import {t} from '@lingui/macro';
import {Dispatch} from '@reduxjs/toolkit';
import React, {useCallback} from 'react';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';
import {privacy, alert} from 'features/mediafire';

const _modalPrivate = 'private';

export function useModalPrivate(ids: string[], dispatch: Dispatch) {
  const submit = useCallback(async () => {
    await privacy(ids, 'public');
    alert(dispatch, ids.length === 1
      ? t`Set privacy of item to public`
      : t`Set privacy of items to public`, 'info');
    Prompt.dismissAnimated(_modalPrivate);
  }, [ids]);

  const show = useCallback(() => {
    Modal.show(
      <Prompt
        id={_modalPrivate}
        title={ids.length === 1
          ? t`Item is private`
          : t`Items are private`}
        text={ids.length === 1
          ? t`While this item is set to private, it cannot be shared, and any previous sharing links will not work. Click the button below to enable sharing.`
          : t`One or more items selected are private. If you would like to share all items you have selected, click the button below to enable.`}
        buttons={[{
          mode: 'primary',
          text: t`ENABLE SHARING`,
          onPress: () => submit(),
        }, {
          mode: 'cancel',
          text: t`CANCEL`,
          onPress: () => Prompt.dismissAnimated(_modalPrivate),
        }]}
      />
    , _modalPrivate);
  }, [ids]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalPrivate);
  }, [ids]);

  return [show, close];
}

import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Styles, View, Text, Button, Modal} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import WebView, {Types} from 'react-ult-ext-webview';
import {Light, Vectors, Colors} from 'features/themes';
import {getHost, isNative} from 'features/platform';

export const modalAppSort = 'mf.app.modal.notifications';

export interface AppNotificationsProps {
  floatRight: boolean;
  maximized: boolean;
}

export function AppNotifications(props: AppNotificationsProps) {
  const base = `${getHost()}/widgets/notifications.php`;
  const classes = {
    inner: [
      styles.inner,
      isNative() && styles.padding,
      props.floatRight && styles.floatRight,
      props.maximized && styles.maximized,
    ],
  };

  const close = useCallback(() => Modal.dismiss(modalAppSort), []);
  const noop = useCallback((e) => e.stopPropagation(), []);
  return (
    <View style={styles.root} onPress={close}>
      <View style={classes.inner} onPress={noop}>
        <View style={styles.header}>
          <Text style={styles.title}>{t`NOTIFICATIONS`}</Text>
          <Button style={styles.close} onPress={close} title={t`Close notifications`}>
            <Svg viewBox="0 0 14 14" width={10} height={10}>
              <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.mid}/>
            </Svg>
          </Button>
        </View>
        <View style={styles.iframe}>
          <WebView
            url={base}
            onLoad={console.debug}
            onError={console.debug}
            sandbox={
                Types.WebViewSandboxMode.AllowScripts
              | Types.WebViewSandboxMode.AllowSameOrigin
              | Types.WebViewSandboxMode.AllowPopups}
          />
        </View>
      </View>
    </View>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Light.dialogBehind,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  inner: Styles.createViewStyle({
    position: 'absolute',
    left: 180,
    top: 20,
    backgroundColor: Colors.neutral.white,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 3,
    elevation: 1,
  }),
  padding: Styles.createViewStyle({
    paddingTop: 40,
  }),
  floatRight: Styles.createViewStyle({
    right: 10,
    left: undefined,
  }),
  maximized: Styles.createViewStyle({
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  }),
  iframe: Styles.createViewStyle({
    width: 400,
    height: 335,
  }),
  header: Styles.createViewStyle({
    height: 42,
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: Colors.neutral.lightest,
    borderBottomWidth: 1,
  }),
  close: Styles.createButtonStyle({
    position: 'absolute',
    alignItems: 'center',
    right: 0,
    height: 42,
    width: 42,
  }),
  title: Styles.createTextStyle({
    fontSize: 10,
    paddingLeft: 12,
    flex: 1,
    color: Colors.neutral.dark,
  }),
};

import {t} from '@lingui/macro';
import React, {useCallback, useState, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {Styles, View, Text, Button} from 'react-ult';
import {Light} from 'features/themes';
import {isTouch} from 'features/platform';
import * as effects from 'store/files/effects';

export interface Props {
  id: string;
  name: string;
  active?: boolean;
}

export function FilesPathItem(props: Props) {
  const [stateDrop, setDrop] = useState(0);
  const anchor = useRef(null);
  const hasTouch = isTouch();
  const isActive = props.active;
  const isDropping = stateDrop > 0;
  const classes = {
    root: [
      styles.root,
      hasTouch && styles.touch,
    ],
    title: [
      styles.title,
      isActive && styles.active,
      isDropping && styles.drop,
      hasTouch && styles.touchText,
    ]
  };

  const dispatch = useDispatch();
  const navigate = useCallback(effects.navigate(dispatch, props.id), [props.id]);
  const dragOver = useCallback(effects.dragOver(), []);
  const dragEnter = useCallback(effects.dragEnter(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragLeave = useCallback(effects.dragLeave(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragDrop = useCallback(effects.dragDrop(dispatch, setDrop, props.id, props.name), [setDrop, props.id, props.name]);

  return (
    <View
      style={classes.root}
      onContextMenu={undefined}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragOver={dragOver}
      onDrop={dragDrop}>
      <Button
        ref={anchor}
        style={styles.button}
        onPress={navigate}
        title={t`Go to ${props.name}`}>
        <Text style={classes.title}>
          {props.name}
        </Text>
      </Button>
    </View>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  }),
  touch: Styles.createViewStyle({
    height: 54,
  }),
  touchText: Styles.createTextStyle({
    fontSize: 14,
  }),
  button: Styles.createViewStyle({
    marginRight: 8,
  }),
  title: Styles.createTextStyle({
    fontSize: 12,
    lineHeight: 32,
    paddingHorizontal: 8,
    color: Light.breadcrumbsText,
  }),
  drop: Styles.createTextStyle({
    backgroundColor: Light.listItemDropBackground,
  }),
  active: Styles.createTextStyle({
    opacity: 1,
  }),
};

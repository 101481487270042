import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Styles, View, Text, ActivityIndicator, Types} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {IconButton} from 'controls/IconButton';
import {useModalCreateFolder} from 'hooks/useModalCreateFolder';
import {useModalUpsellStorage} from 'hooks/useModalUpsellStorage';
import {FilesNavigator} from 'view/FilesNavigator';
import {FilesList} from 'view/FilesList';
import {Light, Colors, Vectors} from 'features/themes';
import {alert, copy, move, restore} from 'features/mediafire';
import {selectors} from 'store/files';

import * as app from 'store/app';
import * as filesEffects from 'store/files/effects';
import * as MF from 'globals/types';

export function FilesPicker() {
  const isPremium = useSelector(app.selectors.isPremium);
  const viewport = useSelector(app.selectors.getViewport);
  const header = useSelector(app.selectors.getHeader);
  const limits = useSelector(app.selectors.getLimits);
  const dragging = useSelector(selectors.getDrag);
  const filter = useSelector(selectors.getFilter);
  const items = useSelector(selectors.getItems);
  const sort = useSelector(selectors.getSort);
  const pick = useSelector(selectors.getPick);
  const action = pick ? pick.action : 'copy';
  const destination = pick ? pick.destination : 'myfiles';
  const destName = items[destination] ? items[destination].name : t`My Files`;
  const sourceName = !pick || pick.ids.length > 1 ? 'items' : 'item';
  const folder = useSelector(selectors.getItem(destination));
  const view = useSelector(selectors.getView(destination));
  const colorPrimary = header ? header.colors.primary : Colors.primary.brand;
  const sizeExpected = folder ? folder.folders : 0;
  const sizeActual = view ? view.folders.length : 0;
  const isLoading = !folder || sizeActual === 0 && sizeExpected > 0;
  const isEmpty = !isLoading && view && sizeExpected === 0;
  const isMinimal = viewport.width < 500;

  const dispatch = useDispatch();
  const [showCreateFolder] = useModalCreateFolder(destination);
  const [showUpsellStorage] = useModalUpsellStorage('manage');
  const doPick = useCallback(filesEffects.listPick(pick, dispatch, sort, filter), [pick, sort, filter]);
  const close = useCallback(filesEffects.pick(dispatch, null), []);
  const noop = useCallback((e) => e.stopPropagation(), []);
  const submit = useCallback(async () => {
    if (pick) {
      let status: any;
      if (action === 'copy' || action === 'save') {
        status = await copy(dispatch, pick.ids, destination, destName);
      } else if (action === 'move') {
        status = await move(dispatch, pick.ids, destination, destName);
      } else if (action === 'restore') {
        status = await restore(dispatch, pick.ids, destination, destName);
      }
      const error = status?.error;
      if (error) {
        if (error === MF.FilesErrors.StorageLimitExceeded) {
          let totalSize = 0;
          pick.ids.forEach(id => (totalSize += items[id].size));
          showUpsellStorage(totalSize, limits.storage.total);
        } else if (error === MF.FilesErrors.AccessDenied) {
          alert(dispatch, t`You do not have write permissions`, 'fail');
        } else {
          alert(dispatch, t`There was a problem attempting this action (code ${error})`, 'fail');
        }
      }
    }
    filesEffects.pickClear(dispatch);
  }, [pick, items]);

  return pick ? (
    <View
      disableTouchOpacityAnimation
      style={styles.root}
      onPress={close}>
      <View
        style={[styles.inner, isMinimal && styles.innerMobile]}
        accessibilityTraits={Types.AccessibilityTrait.Dialog}
        disableTouchOpacityAnimation={true}
        restrictFocusWithin={true}
        tabIndex={-1}
        onPress={noop}>
        <View style={styles.content}>
          <FilesNavigator picker id={destination}/>
            <View style={styles.list}>
              {!isEmpty &&
                <FilesList
                  picker
                  sort={sort}
                  filter={filter}
                  id={destination}
                  maxwidth={292}
                  dispatch={dispatch}
                  onPick={doPick}
                  isSearch={false}
                  isPremium={isPremium}
                  name={folder ? folder.name : ''}
                  primaryColor={colorPrimary}
                  viewport={viewport}
                  dragging={dragging}
                  focusId={undefined}
                  selection={[]}
                  items={items}
                  view={view}
                />
              }
              {isEmpty &&
                <View style={styles.empty}>
                  <Text style={styles.emptyText}>
                    {t`${action.slice(0,1).toUpperCase()}${action.slice(1)} ${sourceName} here`}
                  </Text>
                </View>
              }
              {isLoading &&
                <View style={styles.loading}>
                  <ActivityIndicator color={colorPrimary} size="small"/>
                </View>
              }
            </View>
        </View>
        <View style={styles.actions}>
          <IconButton
            mode="primary"
            onPress={submit}
            title={t`Start ${action}`}
            text={t`${action.toUpperCase()} HERE`}
          />
          <View style={styles.spacerH}/>
          <IconButton
            mode="cancel"
            onPress={close}
            title={t`Cancel ${action}`}
            text={t`CANCEL`}
          />
          <View style={styles.spacerFill}/>
          <IconButton
            onPress={showCreateFolder}
            title={t`Create new folder`}
            text={!isMinimal ? t`NEW FOLDER` : null}
            buttonStyle={styles.button}
            buttonHoverStyle={styles.buttonHover}
            textStyle={styles.buttonText}
            icon={
              <Svg viewBox="0 0 20 16" width={20} height={16}>
                <SvgPath d={Vectors.iconFolderAdd} fillColor={Colors.neutral.mid} fillOpacity={1}/>
              </Svg>
            }
          />
        </View>
      </View>
    </View>
  ) : null;
}

export const styles = {
  root: Styles.createViewStyle({
    position: 'absolute',
    backgroundColor: Light.dialogBehind,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  inner: Styles.createViewStyle({
    flex: 0,
    width: 480,
    borderRadius: 3,
    backgroundColor: Light.uploaderContentBackground,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 3,
    elevation: 1,
  }),
  innerMobile: Styles.createViewStyle({
    width: 292,
  }),
  actions: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row-reverse',
    padding: 10,
    paddingLeft: 6,
    borderTopWidth: 2,
    borderColor: Light.navigatorBorder,
    backgroundColor: Light.navigatorBackground,
  }),
  button: Styles.createButtonStyle({
    paddingHorizontal: 8,
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: 32,
  }),
  buttonHover: Styles.createButtonStyle({
    backgroundColor: Light.dialogInputBackground,
  }),
  buttonText: Styles.createTextStyle({
    color: Light.dialogText,
  }),
  content: Styles.createViewStyle({
    flex: 1,
    margin: 0,
  }),
  list: Styles.createViewStyle({
    height: 300,
  }),
  empty: Styles.createViewStyle({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  emptyText: Styles.createTextStyle({
    color: Light.dialogText,
    fontSize: 14,
  }),
  loading: Styles.createViewStyle({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  spacerH: Styles.createViewStyle({
    width: 6,
  }),
  spacerFill: Styles.createViewStyle({
    flex: 1,
  }),
};

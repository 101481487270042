import {t} from '@lingui/macro';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Styles, View, StatusBar, ActivityIndicator} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import WebView, {Types} from 'react-ult-ext-webview';
import {AppAlerts} from 'view/AppAlerts';
import {AppMenu} from 'view/AppMenu';
import {Files} from 'view/Files';
import {FilesPicker} from 'view/FilesPicker';
import {FilesUploader} from 'view/FilesUploader';
import {FilesPreviewer} from 'view/FilesPreviewer';
import {FilesDownloader} from 'view/FilesDownloader';
import {getHost, getClient, isNative, isWeb} from 'features/platform';
import {Colors, Breakpoints, Light, Vectors} from 'features/themes';
import {featuresOrder} from 'features/mediafire';
import {random, testBit} from 'features/common';
import {loadLocale} from 'features/i18n';
import device from 'features/device';

import * as app from 'store/app';
import * as files from 'store/files';
import * as appEffects from 'store/app/effects';
import * as filesEffects from 'store/files/effects';
import * as MF from 'globals/types';

export function AppFrame() {
  const [stateAuthed, setAuthed] = useState(false);
  const earlyAccess = useSelector(app.selectors.getFeature);
  const viewport = useSelector(app.selectors.getViewport);
  const isActive = useSelector(app.selectors.isActive, (prev) => !prev);
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const isMenuOpen = useSelector(app.selectors.isMenuOpen);
  const current = useSelector(files.selectors.getCurrent);
  const browser = useSelector(files.selectors.getBrowser);
  const preview = useSelector(files.selectors.getPreview);
  const upload = useSelector(files.selectors.getUpload);
  const downloads = useSelector(files.selectors.getDownloadBulk);
  const folder = useSelector(files.selectors.getItem(current));
  const isMinimized = useSelector(files.selectors.isUploaderMinimized);
  const isMinimizedDownload = useSelector(files.selectors.isDownloaderMinimized);
  const isSpecial = current === 'myfiles' || current === 'recent' ||  current === 'following' || current === 'trash';
  const isShared = !isSpecial && folder && !testBit(folder.flag, MF.FilesItemFlag.isOwned);
  const isMenuFloating = viewport.width <= Breakpoints.menu;
  const isOwned = isLoggedIn && !isShared;
  const isAuthed = isLoggedIn || isShared;
  const hasMenu = isLoggedIn && isOwned && (isMenuOpen || !isMenuFloating);
  const hasDownload = downloads.length > 0;
  const hasUpload = !!upload;
  const hasOffsetUploader = isMinimized && hasUpload;
  const hasOffsetDownloader = isMinimizedDownload && hasDownload;
  const classes = {
    root: [
      styles.root,
      (isWeb() || !isMenuFloating) && styles.rootFloatingMenu,
    ],
    menu: [
      styles.menu,
      isMenuFloating && styles.menuFloating,
    ],
  };

  const dispatch = useDispatch();
  const noop = useCallback((e) => e.preventDefault(), []);
  const layout = useCallback(appEffects.layout(dispatch), []);
  const login = useCallback(appEffects.loginNativeAuth(dispatch), []);
  const openMenu = useCallback(appEffects.menuOpen(dispatch), []);
  const loginNav = useCallback(appEffects.loginNativeRedirect(setAuthed), [setAuthed]);
  const closeMenu = useCallback(appEffects.menuClose(dispatch), []);
  const dragOver = useCallback(filesEffects.dragOver(), []);
  const drop = useCallback(filesEffects.dragDrop(dispatch, null, 'myfiles', 'My Files'), []);

  useEffect(() => {
    // TODO: RTL support
    loadLocale(device.getLocale(true));
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      StatusBar.setBarStyle('dark-content', false);
    }
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      {isAuthed &&
        <View
          style={classes.root}
          onLayout={layout}
          onContextMenu={noop}
          onDragOver={dragOver}
          onDrop={drop}>
          {!isActive &&
            <View style={styles.loading}>
              <ActivityIndicator color={Colors.primary.brand} size="medium"/>
            </View>
          }
          {isActive && !earlyAccess &&
            <View style={styles.content}>
              <Files browser={browser} content={current}/>
            </View>
          }
          {isActive && earlyAccess &&
            <View style={styles.content}>
              <WebView
                url={`${getHost()}/templates/new_products_marketing/app_page.php?order=${featuresOrder}&dark=${device.isDarkMode() ? '1' : '0'}#${earlyAccess.toLowerCase()}`}
                sandbox={Types.WebViewSandboxMode.AllowScripts | Types.WebViewSandboxMode.AllowSameOrigin | Types.WebViewSandboxMode.AllowTopNavigation}
                onLoad={console.debug}
                onError={console.debug}
              />
            </View>
          }
          {isActive && earlyAccess && isMenuFloating &&
            <View style={styles.menuButton} onPress={openMenu} title={t`Open main menu`}>
              <Svg viewBox="0 0 18 12" width={18} height={12}>
                <SvgPath d={Vectors.iconMenu} fillColor={Colors.neutral.white}/>
              </Svg>
            </View>
          }
          {isMenuOpen && isMenuFloating &&
            <View style={styles.overlay} onPress={closeMenu}/>
          }
          {hasMenu &&
            <View style={classes.menu}>
              <AppMenu/>
            </View>
          }
          {preview &&
            <FilesPreviewer/>
          }
          {hasDownload &&
            <FilesDownloader minimized={isMinimizedDownload} offset={hasOffsetUploader}/>
          }
          {hasUpload &&
            <FilesUploader id={upload} minimized={isMinimized}/>
          }
          <FilesPicker/>
          <AppAlerts isOffset={hasOffsetUploader} isOffsetMore={hasOffsetDownloader}/>
        </View>
      }
      {!isAuthed &&
        <View style={styles.loadingRoot}>
          <ActivityIndicator color={Colors.primary.brand} size="medium"/>
        </View>
      }
      {!isLoggedIn && isNative() &&
        <View style={!stateAuthed ? styles.root : styles.hidden}>
          <WebView
            onMessage={login}
            onNavigationStateChange={loginNav}
            url={`${getHost()}/login/?platform=${getClient()}&t=${random(0, 10000)}`}
            injectedJavaScript="ReactNativeWebView.postMessage('Cookie: ' + document.cookie)"
            sandbox={
                Types.WebViewSandboxMode.AllowScripts
              | Types.WebViewSandboxMode.AllowPopups
              | Types.WebViewSandboxMode.AllowForms
            }
          />
        </View>
      }
    </React.Fragment>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
  }),
  rootFloatingMenu: Styles.createViewStyle({
    flexDirection: 'row-reverse',
  }),
  loadingRoot: Styles.createViewStyle({
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    flex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Light.filesBackground,
  }),
  hidden: Styles.createViewStyle({
    position: 'absolute',
    left: -1,
    top: -1,
    opacity: 0,
    height: 1,
    width: 1,
  }),
  overlay: Styles.createViewStyle({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    backgroundColor: Light.dialogBehind,
  }),
  content: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'column',
  }),
  loading: Styles.createViewStyle({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  menu: Styles.createViewStyle({
    flex: 0,
  }),
  menuFloating: Styles.createViewStyle({
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
  }),
  menuButton: Styles.createViewStyle({
    right: 8,
    top: 9,
    height: 46,
    width: 46,
    borderRadius: 99,
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  }),
};

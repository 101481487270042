import {Types} from 'react-ult';
import {PayloadAction} from '@reduxjs/toolkit';
import {File} from 'mediafire/dist/types/File';
import {FolderInfo, FolderContent, FolderDepth} from 'mediafire/dist/types/Folder';

// STORES

export type AppStore = {
  user?: AppUser,
  active: boolean,
  menuOpen: boolean,
  revision: number,
  feature: AppFeatures | null,
  notifications: number,
  viewport: {
    width: number,
    height: number,
  },
  alerts: {
    text: string,
    type: AppAlertType,
  }[],
}

export type AppFeatures =
    'Overview'
  | 'Request'
  | 'Send'
  | 'Sell'
  | 'Deliver'
  | 'Insights';

export type FilesStore = {
  layout: 'grid' | 'list';
  root: {id: string, name: string};
  items: {[id: string]: FilesItem};
  views: {[id: string]: FilesView};
  sort: FilesSort;
  filter: FilesFilter;
  gallery: boolean;
  selection: string[];
  focused: string | null;
  current: string | null;
  browser: string | null;
  upload: string | null;
  uploadView: FilesView;
  uploadMinimized: boolean;
  downloadMinimized: boolean;
  uploadItems: {[id: string]: FilesItem};
  uploadFiles?: globalThis.File[];
  uploadWeb?: string[];
  downloadBulk: {
    statusKey: string,
    fileName: string,
    totalSize: number,
  }[];
  downloadItems: {[id: string]: FilesItem};
  downloadView: FilesView;
  search?: string;
  query?: string;
  drag?: string[];
  pick?: FilesPick;
  previewProgress?: number;
  preview?: {
    id: string,
    name: string,
    jobId?: number,
  };
}

// ACTIONS

export type AppStart = PayloadAction<{}>
export type AppRevision = PayloadAction<{}>
export type AppLogout = PayloadAction<{}>
export type AppLogin = PayloadAction<AppUser>
export type AppMenu = PayloadAction<{open: boolean}>
export type AppActivate = PayloadAction<{active: boolean}>
export type AppFeature = PayloadAction<{feature: AppFeatures}>
export type AppResize = PayloadAction<{width: number, height: number}>
export type AppAlert = PayloadAction<{text: string, type: AppAlertType}>
export type AppNotify = PayloadAction<{count: number}>

export type FilesFail = PayloadAction<{message: string, notify: boolean}>
export type FilesMove = PayloadAction<{ids: string[], destination: string}>
export type FilesCopy = PayloadAction<{ids: string[], destination: string}>
export type FilesCreate = PayloadAction<{name: string, id: string}>
export type FilesBulkDownload = PayloadAction<{statusKey: string, fileName: string, totalSize: number}>
export type FilesUpload = PayloadAction<{id: string, files?: globalThis.File[]}>
export type FilesUploadWeb = PayloadAction<{id: string, files?: string[]}>
export type FilesUploadSync = PayloadAction<{id: string, items: FilesItem[]}>
export type FilesDownloadSync = PayloadAction<{items: FilesItem[]}>
export type FilesUploadMinimize = PayloadAction<{minimized: boolean}>
export type FilesDownloadMinimize = PayloadAction<{minimized: boolean}>
export type FilesPassword = PayloadAction<{id: string, password: string}>
export type FilesNavigate = PayloadAction<{id?: string, syncing?: boolean}>
export type FilesSearch = PayloadAction<{id?: string, query?: string, results?: object}>
export type FilesSelect = PayloadAction<{ids: string[], ranged?: boolean, view?: FilesView}>
export type FilesBrowse = PayloadAction<{id: string}>
export type FilesFocus = PayloadAction<{id: string}>
export type FilesDrag = PayloadAction<{ids?: string[]}>
export type FilesPurge = PayloadAction<{ids: string[]}>
export type FilesArchive = PayloadAction<{ids: string[]}>
export type FilesRestore = PayloadAction<{ids: string[]}>
export type FilesRefresh = PayloadAction<{ids: string[]}>
export type FilesSync = PayloadAction<{ids: string[]}>
export type FilesPicking = PayloadAction<FilesPick>
export type FilesSorting = PayloadAction<FilesStore['sort']>
export type FilesFiltering = PayloadAction<FilesStore['filter']>
export type FilesLayout = PayloadAction<{}>
export type FilesPreview = PayloadAction<{id: string, name: string}>
export type FilesPreviewJob = PayloadAction<{jobId: number}>
export type FilesPreviewProgress = PayloadAction<{progress: number}>

// APP TYPES

export type AppUser = {
  ekey: string,
  name?: string,
  email?: string,
  avatar: string,
  created: string,
  options: number,
  header: AppHeader,
  group: AppUserGroup,
  limit: AppUserLimit,
}

export type AppHeader = {
  needsPaymentUpdate: boolean,
  customAlertIDs: number[],
  custom: boolean,
  logo: string,
  theme: {
    header: string,
  },
  colors: {
    header: string,
    primary: string,
  },
  business: {
    company: string,
    subdomain: string,
  },
}

export type AppAlertType = 'info' | 'fail';
export enum AppUserGroup {Unregistered, Trial, Free, Pro, Business}
export type AppUserLimit = {storage: {used: number, total: number}}


// FILES TYPES

export type FilesView = {
  files: string[],
  folders: string[],
}

export type FilesItem = {
  id: string;
  url: string;
  name: string;
  type: string;
  revision: number;
  state: FilesItemState;
  hierarchy: string[][];
  private: boolean;
  created: string;
  shared?: string;
  deleted?: string;
  folders?: number;
  files?: number;
  size?: number;
  flag?: number;
  hash?: string;
  owner?: string;
  mimetype?: string;
  downloads?: number;
  relevancy?: number;
  filedrop?: boolean;
  password?: boolean;
  description?: string;
  parentKey?: string;
  parentName?: string;
  uploadStatus?: string;
  uploadHashed?: number;
  uploadProgress?: number;
}

export enum FilesItemState {
  isNormal,
  isLoading,
  isMoving,
  isCopying,
  isDeleting,
  isRestoring,
  isPurging,
  isUpdating,
  isHashing,
  isUploading,
  isVerifying,
}

export enum FilesItemFlag {
  isReserved,
  isOwned,
  isViewable,
  isReported,
  isEditable,
  isCopyright,
  isRestricted,
  isWordFiltered,
  isInfected,
}

export type FilesFilter = {
  type: 
      'all'
    | 'folders'
    | 'files'
    | 'images'
    | 'video'
    | 'audio'
    | 'document'
    | 'spreadsheet'
    | 'presentation'
    | 'development'
    | 'public'
    | 'private'
}

export type FilesSort = {
  category:
      'name'
    | 'date'
    | 'size'
    | 'downloads',
  order:
      'asc'
    | 'desc',
}

export type FilesPick = {
  action: 'copy' | 'move' | 'save' | 'restore';
  destination: string;
  ids: string[],
}

export type FilesContextMenu = (
  position: Types.PopupPosition,
  offset: number,
  width: number,
  height: number,
) => React.ReactNode;

export enum FilesErrors {
  ApiInternal = 100,
  UserError = 101,
  MissingKey = 102,
  InvalidKey = 103,
  MissingToken = 104,
  InvalidToken = 105,
  InvalidQuickkey = 110,
  MissingQuickkey = 111,
  InvalidFolderkey = 112,
  MissingFolderkey = 113,
  AccessDenied = 114,
  FolderPathConflict = 115,
  InvalidDate = 116,
  MissingFoldername = 117,
  InvalidFilename = 118,
  ZipOwnerNotPro = 149,
  ZipTotalSizeTooBig = 153,
  ZipOwnerInsufficientBandwidth = 155,
  StorageLimitExceeded = 162,
  FolderDepthLimit = 167,
  NotPremiumUser = 173,
  InvalidUrl = 174,
  InvalidUploadKey = 175,
  BandwidthError = 183,
  NotLoggedIn = 188,
  CopyLimitExceeded = 207,
  AsyncJobInProgress = 208,
}

export type FilesLoad = PayloadAction<{
  id: string,
  root: {
    id: string,
    name: string,
  },
  folders: FolderContent,
  files: FolderContent,
  details?: FolderInfo,
  depth?: FolderDepth,
}>

export type FilesLoadRecent = PayloadAction<{
  files: File[],
}>

export type FilesLoadFollowing = PayloadAction<{
  files: File[],
}>

export type FilesLoadMulti = PayloadAction<{
  folders?: FolderContent,
  files?: FolderContent,
  items?: FolderContent,
  virtual?: string;
}>

export type FilesLoadTrash = PayloadAction<{
  id: string,
  revision: number,
  folderCount: number,
  fileCount: number,
  folders: any,
  files: any,
}>

export type FilesChunk = PayloadAction<{
  id: string,
  type: string,
  page: number,
  size: number,
  content?: FolderContent,
}>

export type FilesUpdate = PayloadAction<{
  id: string,
  name?: string,
  password?: boolean,
  description?: string,
  privacy?: 'public' | 'private',
}>

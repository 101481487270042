import {t} from '@lingui/macro';
import React, {useCallback, useState} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Styles, View, Text, Button} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {Dialog} from 'controls/Dialog';
import {InputField} from 'controls/InputField';
import {IconButton} from 'controls/IconButton';
import {alert, uploadAddWebFile} from 'features/mediafire';
import {Light, Fonts, FontSizes, Colors, Vectors} from 'features/themes';

export const modalFilesWebUpload = 'web-upload';

export interface Props {
  dispatch: Dispatch;
  id: string;
  url?: string;
  primaryColor: string;
  isMinimal?: boolean;
}

export function FilesModalWebUpload(props: Props) {
  const {id, url} = props;
  const [stateUrl, setUrl] = useState(props.url || '');
  const [stateName, setName] = useState('');

  const dispatch = props.dispatch;
  const help = useCallback(() => console.log('clicked help'), []);
  const close = useCallback(() => Dialog.dismissAnimated(modalFilesWebUpload), []);
  const submit = useCallback(async () => {
    const response = await uploadAddWebFile(dispatch, id, stateUrl, stateName);
    if (response && response['result'] === 'Success') {
      alert(dispatch, `Uploading “${stateName}” from the web...`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to submit web upload`;
      alert(dispatch, message, 'fail');
    }
    close();
  }, [id, url, stateName]);

  return (
    <Dialog id={modalFilesWebUpload} width={480}>
      <View style={styles.root}>
        <View style={styles.bar}>
          <Text style={styles.title}>
            {t`Upload from the Web`}
          </Text>
          <Button
            style={styles.close}
            onPress={close}
            title={t`Close web uploader`}>
            <Svg viewBox="0 0 14 14" width={14} height={14}>
              <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.mid}/>
            </Svg>
          </Button>
        </View>
        <View style={styles.content}>
          <View style={styles.messageWrapper}>
            <Text style={styles.hint}>
              {'Enter a file URL and name to start your web upload. Web uploads have a maximum file size of '}
              <Text style={styles.hintBold}>
                {'10 GB'}
              </Text>
              {'.'}
            </Text>
            <Text style={styles.message}>
              {t`Enter the file URL:`}
            </Text>
          </View>
          <InputField
            autoFocus
            onChangeText={setUrl}
            onSubmitEditing={submit}
            placeholder={"https://example.com/photo.jpg"}
            defaultValue={stateUrl}
          />
          <View style={styles.spacerV}/>
          <View style={styles.messageWrapper}>
            <Text style={styles.message}>
              {t`Choose a file name:`}
            </Text>
          </View>
          <InputField
            onChangeText={setName}
            onSubmitEditing={submit}
            defaultValue={stateName}
            placeholder="photo.jpg"
          />
          {false &&
            <View
              style={styles.help}
              onMouseEnter={help}
              onMouseLeave={help}
              onPress={help}
              title={t`Web Upload Details`}>
              <Svg viewBox="0 0 24 24" width={24} height={24}>
                <SvgPath d={Vectors.iconHelp} fillColor={Colors.neutral.mid}/>
              </Svg>
            </View>
          }
        </View>
        <View style={styles.buttonWrapper}>
          <IconButton
            mode="primary"
            title={t`Submit web upload`}
            text={t`UPLOAD VIA WEB`}
            onPress={submit}
          />
          <View style={styles.spacer}/>
          <IconButton
            mode="cancel"
            title={t`Cancel web upload`}
            text={t`CANCEL`}
            onPress={close}
          />
        </View>
      </View>
    </Dialog>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    borderWidth: 1,
    borderColor: Light.dialogBorder,
    backgroundColor: Light.dialogBackground,
  }),
  content: Styles.createViewStyle({
    flex: 0,
    paddingHorizontal: 20,
  }),
  help: Styles.createViewStyle({
    position: 'absolute',
    padding: 24,
    bottom: 0,
    left: 0,
  }),
  message: Styles.createTextStyle({
    flex: 1,
    font: Fonts.displayBold,
    fontSize: FontSizes.size14,
    lineHeight: 24,
    color: Light.dialogText,
    marginBottom: 4,
  }),
  messageWrapper: Styles.createViewStyle({
    flex: 1,
  }),
  hint: Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size14,
    color: Light.dialogText,
    marginBottom: 20,
  }),
  hintBold: Styles.createTextStyle({
    font: Fonts.displayBold,
    fontSize: FontSizes.size14,
    color: Light.dialogText,
  }),
  buttonWrapper: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row-reverse',
    padding: 20,
  }),
  input: Styles.createTextInputStyle({
    flex: 1,
    fontSize: 16,
    color: Light.dialogInputText,
    backgroundColor: Light.dialogInputBackground,
  }),
  inputWrapper: Styles.createViewStyle({
    borderBottomWidth: 2,
    borderColor: Colors.primary.brand,
    marginBottom: 12,
  }),
  spacer: Styles.createViewStyle({
    width: 6,
  }),
  spacerV: Styles.createViewStyle({
    height: 10,
  }),
  bar: Styles.createViewStyle({
    flex: 0,
  }),
  title: Styles.createTextStyle({
    fontSize: 20,
    fontWeight: '300',
    color: Light.dialogTitle,
    lineHeight: 65,
    marginLeft: 22,
  }),
  close: Styles.createButtonStyle({
    position: 'absolute',
    alignItems: 'center',
    height: 65,
    width: 58,
    right: 1,
    top: -2,
  }),
};

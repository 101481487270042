import React from 'react';
import {ComponentBase} from 'resub';
import {Fragment, Styles, View, Text, Button, Input, Types} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {Light, Colors, Fonts, FontSizes, Vectors} from 'features/themes';
import {InputField} from 'controls/InputField';
import {IconButton} from 'controls/IconButton';
import {Dialog} from 'controls/Dialog';
import {Keys} from 'globals/keys';

export interface PromptButton {
  text?: string;
  mode?: 'primary' | 'cancel' | 'destructive',
  onPress?: (inputValue?: string) => void;
  disabled?: boolean;
  buttonStyle?: Types.ButtonStyleRuleSet;
  textStyle?: Types.TextStyleRuleSet;
}

export interface PromptProps {
  id: string;
  maxWidth?: number;
  maxHeight?: number;
  title?: string;
  text?: string;
  containerStyle?: Types.ViewStyleRuleSet;
  children?: Types.ReactNode;
  buttons?: PromptButton[];
  options?: PromptOptions;
}

interface PromptOptions {
  type?: 'default' | 'plain-text' | 'secure-text' | 'login-password',
  keyboardType?: 'default' | 'email-address' | 'numeric' | 'number-pad';
  selectInput?: 'none' | 'all' | 'filename',
  defaultValue?: string,
  placeholder?: string,
  nocancel?: boolean,
  primaryColor?: string;
  isMinimal?: boolean;
}

interface PromptState {
  inputValue: string;
}

export class Prompt extends ComponentBase<PromptProps, PromptState> {
  componentDidMount() {
    super.componentDidMount();
    Input.backButtonEvent.subscribe(this._onBack);
    Input.keyUpEvent.subscribe(this._onKeyUp);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    Input.backButtonEvent.unsubscribe(this._onBack);
    Input.keyUpEvent.unsubscribe(this._onKeyUp);
  }

  render() {
    // Title Text
    let optionalTitleText: JSX.Element | undefined;
    if (this.props.title) {
      optionalTitleText = (
        <View importantForAccessibility={Types.ImportantForAccessibility.Yes}>
          <Text
            style={_styles.title}
            importantForAccessibility={Types.ImportantForAccessibility.NoHideDescendants}>
            {this.props.title}
          </Text>
        </View>
      );
    }

    // Content (children)
    let optionalContent: Types.ReactNode | undefined;
    if (this.props.children) {
      optionalContent = this.props.children;
    } else if (this.props.text) {
      optionalContent = (
        <Text style={_styles.message}>
          {this.props.text}
        </Text>
      );
    }

    // Buttons
    let optionalButtonsContainer: JSX.Element | undefined;
    if (this.props.buttons && this.props.buttons.length > 0) {
      const optionalButtons = this.props.buttons.map(this._renderButton);
      optionalButtonsContainer = (
        <View style={_styles.buttons}>
          {optionalButtons}
        </View>
      );
    }

    return (
      <Dialog
        id={this.props.id}
        width={this.props.maxWidth || 450}
        height={this.props.maxHeight}>
        <View style={[_styles.container, this.props.containerStyle]}>
          {optionalTitleText}
          {!this.props.options?.nocancel &&
            <Button title="CANCEL"
              style={_styles.close}
              onPress={this._onCancel}>
              <Svg viewBox="0 0 14 14" width={14} height={14}>
                <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.mid}/>
              </Svg>
            </Button>
          }
          <View style={_styles.content}>
            {optionalContent}
            {this.props.options?.type
              && this.props.options?.type !== 'default' &&
              <InputField
                autoFocus
                onChangeText={this._setValue}
                onSubmitEditing={this._onSubmit}
                selectMode={this.props.options?.selectInput}
                placeholder={this.props.options?.placeholder}
                defaultValue={this.props.options?.defaultValue}
                keyboardType={this.props.options?.keyboardType || 'default'}
                secureTextEntry={this.props.options?.type === 'secure-text'}
              />
            }
          </View>
          {optionalButtonsContainer}
        </View>
      </Dialog>
    );
  }

  protected _buildState(props: PromptProps, initialBuild: boolean): Partial<PromptState> {
    const newState: Partial<PromptState> = {};
    if (initialBuild) {
      newState.inputValue = props.options?.defaultValue ?? undefined;
      return newState;
    }
    return newState;
  }

  private _renderButton = (buttonDef: PromptButton, index: number): JSX.Element => (
    <Fragment key={index}>
      {index > 0 && <View style={_styles.spacer}/>}
      <IconButton
        mode={buttonDef.mode}
        title={buttonDef.text}
        text={buttonDef.text || ''}
        disabled={buttonDef.disabled}
        onPress={e => this._onButtonPress(e, buttonDef)}
      />
    </Fragment>
  );

  private _onButtonPress(e: Types.SyntheticEvent, buttonDef: PromptButton) {
    e.stopPropagation();
    this._completeButtonPress(buttonDef);
  }

  private _completeButtonPress(buttonDef: PromptButton) {
    if (buttonDef.onPress) {
      buttonDef.onPress(this.state.inputValue);
    }
  }

  private _onKeyUp = (event: Types.KeyboardEvent) => {
    if (event.keyCode === Keys.Escape)
      return this._onCancel();
    return false;
  };

  private _setValue = (value: string) => {
    this.setState({inputValue: value});
  };

  private _onSubmit = () => {
    let buttonToCall: PromptButton | undefined;
    this.props.buttons.forEach(button => {
      if (button.mode === 'primary' || button.mode === 'destructive')
        buttonToCall = button;
    });
    if (buttonToCall) {
      this._completeButtonPress(buttonToCall);
      return true;
    }
    return false;
  };

  private _onCancel = () => {
    let buttonToCall: PromptButton | undefined;
    this.props.buttons.forEach(button => {
      if (button.mode === 'cancel')
        buttonToCall = button;
    });
    if (buttonToCall) {
      this._completeButtonPress(buttonToCall);
      return true;
    }
    return false;
  };

  private _onBack = () => {
    return this._onCancel();
  };

  static dismissAnimated(id: string): Promise<void> {
    return Dialog.dismissAnimated(id);
  }
}

const _modalPadding = 20;
const _styles = {
  container: Styles.createViewStyle({
    flex: 1,
    borderWidth: 1,
    borderColor: Light.dialogBorder,
    backgroundColor: Light.dialogBackground,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 3,
    elevation: 1,
  }),
  title: Styles.createTextStyle({
    font: Fonts.displayLight,
    fontSize: FontSizes.size20,
    color: Light.dialogTitle,
    padding: _modalPadding,
  }),
  message: Styles.createTextStyle({
    textAlign: 'left',
    font: Fonts.displayRegular,
    fontSize: FontSizes.size14,
    color: Light.dialogText,
    marginBottom: 10,
  }),
  content: Styles.createViewStyle({
    flex: 1,
    paddingHorizontal: _modalPadding,
  }),
  buttons: Styles.createViewStyle({
    alignItems: 'stretch',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    padding: _modalPadding,
  }),
  close: Styles.createButtonStyle({
    position: 'absolute',
    alignItems: 'center',
    margin: 10,
    marginRight: 4,
    height: 46,
    width: 46,
    right: 0,
  }),
  spacer: Styles.createViewStyle({
    width: 6,
  }),
};

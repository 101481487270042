import React, {useState, useEffect, useCallback} from 'react';
import isEqual from 'react-fast-compare';
import {Styles, View, Text, Image, Types} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {getIcon} from 'features/mediafire';
import {isWeb} from 'features/platform';
import {Light, Colors} from 'features/themes';

export interface FilesItemIconProps {
  id: string;
  type: string;
  name: string;
  width?: number;
  height?: number;
  details?: string;
  primaryColor?: string;
  selected?: boolean;
  dropping?: boolean;
  gallery?: boolean;
  picker?: boolean;
  large?: boolean;
  expandable?: boolean;
  tree?: boolean;
  src?: string;
  url?: string;
}

export const FilesItemIcon = React.memo(function FilesItemIcon(props: FilesItemIconProps) {
  const [statePreview, setPreview] = useState(false);
  const [stateThumbnail, setThumbnail] = useState(props.src);
  const {icon, fill, type} = getIcon(props.type, props.name, props.primaryColor);
  const extension = props.name && props.name.length ? props.name.split('.').pop() : 'FILE';
  const badge = extension ? extension.substr(0, 4).toUpperCase() : 'FILE';
  const isFolder = type === 'folder';
  const isIE11 = !!window.MSInputMethodContext && !!document['documentMode'];
  const hasPrefetch = props.src && isWeb() && !isIE11;
  const classes = {
    root: [
      styles.root,
      props.gallery && styles.gallery,
      props.gallery && props.selected && type !== 'image' && styles.gallerySelected,
      props.dropping && styles.dropping,
    ],
    icon: props.tree
      ? styles.iconTree
      : isFolder
        ? styles.iconFolder
        : styles.icon,
    badge: [
      styles.badge,
      props.large && styles.badgeLarge,
      props.gallery && styles.badgeGallery,
    ],
    extension: [
      styles.extension,
      props.large && styles.extensionLarge,
      props.gallery && styles.extensionGallery,
    ],
    thumbnail: [
      styles.thumbnail,
      statePreview && styles.thumbnailLoaded,
      props.large && styles.thumbnailLarge,
      props.gallery && Styles.createViewStyle({
        width: props.width,
        height: props.height,
        marginHorizontal: 0,
      }, false),
    ],
    title: [
      styles.name,
      !isFolder && styles.nameMargin,
      Styles.createViewStyle({width: props.width - 20}, false)
    ],
    details: [
      styles.details,
      Styles.createViewStyle({width: props.width - 20}, false),
    ],
  };

  const thumbnailLoaded = useCallback(() => setPreview(true), [setPreview]);

  useEffect(() => {
    if (!hasPrefetch) return () => {};
    const xhr = new XMLHttpRequest();
    let url = props.src;
    xhr.responseType = 'document';
    xhr.open('GET', props.src, true);
    xhr.onload = () => {
      if (xhr.status === 200) {
        setThumbnail(url);
        setPreview(true);
      }
    };
    xhr.send();
    return () => {
      try {
        if (xhr.status === 0) {
          xhr.abort();
        }
      } catch (e) {}
    };
  }, [props.src]);

  return (
    <View
      style={classes.root}
      accessibilityTraits={Types.AccessibilityTrait.Image}>
      {!!props.src &&
        <Image
          title={props.name}
          style={classes.thumbnail}
          onLoad={!hasPrefetch ? thumbnailLoaded : null}
          source={stateThumbnail}
          resizeMode="cover"
        />
      }
      {!statePreview &&
        <View style={styles.inner}>
          <Svg
            style={classes.icon}
            viewBox={'0 0 35 40'}
            width={props.tree ? 20 : props.picker ? 25 : props.gallery ? 40 : 30}
            height={props.tree ? 20 : props.picker ? 30 : props.gallery ? 45 : 35}>
            {icon.map((d,i) =>
              <SvgPath key={i} d={d} fillColor={fill}/>
            )}
          </Svg>
          {!statePreview && type !== 'folder' &&
            <View style={[classes.badge, styles[type]]}>
              <Text style={classes.extension}>
                {badge}
              </Text>
            </View>
          }
        </View>
      }
      {!statePreview && props.gallery &&
        <Text
          style={classes.title}
          numberOfLines={1}
          ellipsizeMode="middle">
          {props.name}
        </Text>
      }
      {!statePreview && props.gallery &&
        <Text style={classes.details}>
          {props.details}
        </Text>
      }
    </View>
  );
}, (prev, next) => isEqual(prev, next));

export const styles = {
  root: Styles.createViewStyle({
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
  }),
  inner: Styles.createViewStyle({
    position: 'relative',
  }),
  gallery: Styles.createViewStyle({
    backgroundColor: Light.listItemNormalBackground,
  }),
  dropping: Styles.createViewStyle({
    backgroundColor: Colors.primary.light,
  }),
  gallerySelected: Styles.createViewStyle({
    backgroundColor: Light.listItemSelectBackground,
    borderColor: Light.menuHeaderMultiBorder,
    borderWidth: 1,
  }),
  hover: Styles.createTextStyle({
    color: Colors.secondary.orange,
  }),
  badge: Styles.createViewStyle({
    backgroundColor: Colors.neutral.black,
    position: 'absolute',
    left: 11,
    top: 5,
  }),
  badgeLarge: Styles.createViewStyle({
    left: 10,
    top: 5,
  }),
  badgeGallery: Styles.createViewStyle({
    left: 11,
    top: 6,
  }),
  name: Styles.createTextStyle({
    textAlign: 'center',
    fontSize: 14,
    color: Light.listItemTitle,
  }),
  nameMargin: Styles.createTextStyle({
    marginTop: 10,
  }),
  details: Styles.createTextStyle({
    textAlign: 'center',
    marginTop: 4,
    fontSize: 12,
    color: Colors.neutral.mid,
  }),
  icon: Styles.createViewStyle({
    marginLeft: 14,
    marginRight: 12,
  }),
  iconFolder: Styles.createViewStyle({
    marginTop: 8,
    marginLeft: 14,
    marginRight: 12,
  }),
  iconTree: Styles.createViewStyle({
    marginTop: 6,
    marginLeft: 3,
  }),
  thumbnail: Styles.createImageStyle({
    position: 'absolute',
    opacity: 0,
    width: 32,
    height: 38,
    marginHorizontal: 12,
  }),
  thumbnailLoaded: Styles.createImageStyle({
    position: 'relative',
    opacity: 1,
  }),
  thumbnailLarge: Styles.createImageStyle({
    height: 48,
  }),
  extension: Styles.createTextStyle({
    fontSize: 8,
    paddingVertical: 1,
    paddingHorizontal: 2,
    fontWeight: '600',
    color: Colors.neutral.white,
  }),
  extensionLarge: Styles.createTextStyle({
    fontSize: 8,
  }),
  extensionGallery: Styles.createTextStyle({
    fontSize: 9,
    paddingVertical: 2,
    paddingHorizontal: 3,
  }),
  file: Styles.createViewStyle({
    backgroundColor: Colors.files.text,
  }),
  folder: Styles.createViewStyle({
    backgroundColor: Colors.files.text,
  }),
  archive: Styles.createViewStyle({
    backgroundColor: Colors.files.archive,
  }),
  audio: Styles.createViewStyle({
    backgroundColor: Colors.files.audio,
  }),
  video: Styles.createViewStyle({
    backgroundColor: Colors.files.video,
  }),
  image: Styles.createViewStyle({
    backgroundColor: Colors.files.image,
  }),
  spreadsheet: Styles.createViewStyle({
    backgroundColor: Colors.files.spreadsheet,
  }),
  pdf: Styles.createViewStyle({
    backgroundColor: Colors.files.pdf,
  }),
  presentation: Styles.createViewStyle({
    backgroundColor: Colors.files.presentation,
  }),
  document: Styles.createViewStyle({
    backgroundColor: Colors.files.document,
  }),
};

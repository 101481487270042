import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Modal} from 'react-ult';
import {Types} from 'react-ult-ext-webview';
import {Dispatch} from '@reduxjs/toolkit';
import {WebFrame} from 'controls/WebFrame';
import {isNative, getHost} from 'features/platform';
import {alert, copyLink, trackShare, isFolderkey, isQuickkey, getKeysFromUrl} from 'features/mediafire';
import device from 'features/device';

const _modalShare = 'share';

export function useModalShare(
  dispatch: Dispatch,
  ids: string[],
  name?: string,
  url?: string,
  oneTimeLink?: boolean,
) {
  let folders = ids.filter(id => isFolderkey(id));
  let files = ids.filter(id => isQuickkey(id));

  // No valid ids found, use key from url (file upload)
  if (url && folders.length === 0 && files.length === 0) {
    const keys = getKeysFromUrl(url);
    folders = keys.filter(id => isFolderkey(id));
    files = keys.filter(id => isQuickkey(id));
  }

  const close = useCallback(() => Modal.dismiss(_modalShare), []);

  const message = useCallback((e: Types.WebViewMessageEvent) => {
    if (e && e.data === 'mf-close-dialogs') {
      close();
    } else if(e && typeof e.data === 'string' && e.data.indexOf('mf-share-') !== -1) {
      copyLink(e.data.slice(9));
      alert(dispatch, t`Copied share link to clipboard`, 'info');
    } else if(e && typeof e.data === 'string' && e.data.indexOf('mf-open-') !== -1) {
      window.open(e.data.slice(8), '_blank');
    }
  }, [ids]);

  const show = useCallback(() => {
    if (isNative()) {
      device.share(url, name);
    } else {
      const base = `${getHost()}/widgets/share.php?web=1&dlr=1${oneTimeLink ? '&1tdl=1' : ''}`;
      const path = `${base}&folders=${folders.join(',')}&files=${files.join(',')}`;
      Modal.show(
        <WebFrame
          url={path}
          onMessage={message}
          close={close}
        />
      , _modalShare);
    }
    trackShare();
  }, [ids]);

  return [show, close];
}

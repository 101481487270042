import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';
import {alert, rename, isFolderkey} from 'features/mediafire';

const _modalRename = 'rename';

export function useModalRename(id: string, name: string, dispatch: Dispatch) {
  const isFolder = isFolderkey(id);
  const group = isFolder ? 'folder' : 'file';

  const submit = useCallback(async (newName: string) => {
    const response = await rename(id, newName);
    if (response && response['result'] === 'Success') {
      alert(dispatch, t`Renamed “${name}” to “${newName}”`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to rename ${name}`;
      alert(dispatch, message, 'fail');
    }
    Modal.dismiss(_modalRename);
  }, [id, name]);

  const show = useCallback(() => {
    Modal.show(
      <Prompt
        id={_modalRename}
        title={t`Rename`}
        text={isFolder ? `${name} ${group}` : name}
        options={{
          type: 'plain-text',
          placeholder: t`Give this ${group} a new name`,
          defaultValue: name,
          selectInput: isFolder ? 'all' : 'filename',
        }}
        buttons={[{
          text: t`OK`,
          mode: 'primary',
          onPress: (value?: string) => {
            Prompt.dismissAnimated(_modalRename);
            if (value) submit(value);
          },
        }, {
          text: t`CANCEL`,
          mode: 'cancel',
          onPress: () => {
            Prompt.dismissAnimated(_modalRename);
          },
        }]}
      />
    , _modalRename);
  }, [id, name]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalRename);
  }, [id, name]);

  return [show, close];
}

import {luminance} from 'features/common';
import {Colors} from './styles';
import {Theme} from './models';

class DarkTheme implements Theme {
  // General
  logoColor = '#0070F0';
  fileIconColor = Colors.neutral.light;
  mainMenuBorder = Colors.neutral.darker;
  headerBackground = Colors.neutral.white;
  contentBackground = Colors.neutral.white;
  borderSeparator = '#ccc';
  borderSeparatorLight = '#eee';
  separator = '#eee';

  // Lists
  listItemTitle = Colors.neutral.dark;
  listItemNormalBackground = Colors.neutral.white;
  listItemNormalBorder = Colors.neutral.lighter;
  listItemHoverBackground = Colors.neutral.whitish;
  listItemHoverTreeBackground = Colors.neutral.lighter;
  listItemHoverSelectedBackground = '#FDF3DE';
  listItemSelectBackground = Colors.secondary.tan;
  listItemSelectBorder = '#ebd2a3';
  listItemSelectText = Colors.neutral.dark;
  listItemFocusBackground = Colors.neutral.whitish;
  listItemFocusSelectedBackground = '#fff1d4';
  listItemDraggingBackground = Colors.neutral.white;
  listItemDropBackground = Colors.primary.light;
  listItemTreeBackground = Colors.neutral.white;
  listItemTreeHoverBackground = Colors.neutral.white;

  // Buttons
  buttonPrimaryBorder = Colors.primary.brand;
  buttonPrimaryBackground = Colors.primary.brand;
  buttonPrimaryBackgroundHover = luminance(Colors.primary.brand, -0.2);
  textButtonPrimary = Colors.neutral.white;

  buttonCancelBorder = Colors.neutral.lighter;
  buttonCancelBackground = Colors.neutral.white;
  buttonCancelBackgroundHover = Colors.neutral.lightest;
  textButtonCancel = Colors.neutral.mid;

  buttonDestructiveBorder = Colors.secondary.brightRed;
  buttonDestructiveBackground = Colors.secondary.brightRed;
  buttonDestructiveBackgroundHover = luminance(Colors.secondary.brightRed, -0.2);
  textButtonDestructive = Colors.neutral.white;

  // Dialogs
  dialogBackground = Colors.neutral.white;
  dialogBorder = Colors.neutral.lighter;
  dialogText = Colors.neutral.dark;
  dialogTitle = Colors.neutral.mid;
  dialogInputText = Colors.neutral.dark;
  dialogInputPlaceholder = '#bdc2cd';
  dialogInputBackground = Colors.neutral.white;
  dialogBehind = 'rgba(0, 0, 0, 0.6)';

  // Menus
  menuText = Colors.neutral.dark;
  menuTextDisabled = '#bbb';
  menuItemHover = Colors.neutral.lightest;
  menuBackground = Colors.neutral.white;
  menuBorder = Colors.neutral.lighter;
  
  menuHeaderText = Colors.neutral.dark;
  menuHeaderBorder = Colors.neutral.lighter;
  menuHeaderBackground = Colors.neutral.lightest;

  menuHeaderMultiBorder = '#E2C18E';
  menuHeaderMultiBackground = Colors.secondary.tan;
  menuHeaderMultiText = '#665e4e';

  // Breadcrumbs
  breadcrumbsText = Colors.neutral.dark;
  breadcrumbsBorder = Colors.neutral.lighter;
  breadcrumbsBackground = Colors.neutral.lightest;
  breadcrumbsShareBackground = Colors.neutral.white;
  breadcrumbsShareBorder = Colors.neutral.lighter;

  // Navigator
  navigatorText = Colors.neutral.dark;
  navigatorBorder = Colors.neutral.lighter;
  navigatorBackground = Colors.neutral.lightest;

  // Files
  filesBackground = Colors.neutral.white;
  filesTreeText = Colors.neutral.dark;
  filesTreeBorder = Colors.neutral.lighter;
  filesTreeBackground = Colors.neutral.lightest;
  filesTreeHoverBackground = Colors.neutral.lighter;

  // Uploader
  uploaderBarTitle = Colors.neutral.darker;
  uploaderBarText = Colors.neutral.dark;
  uploaderBarBorder = Colors.neutral.lighter;
  uploaderBarBackground = Colors.neutral.lightest;
  uploaderContentBackground = Colors.neutral.white;
  uploaderContentIcon = Colors.neutral.lighter;

  // Misc
  barSelectButtonBorder = '#ebd2a3';
  diagnosticUploadTool = Colors.primary.brand;
}

export default new DarkTheme();

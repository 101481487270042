export async function getSecureCookies() {
  // Secure cookies not supported on web
  return {} as Promise<{[key: string]: string}>;
}

export async function clearCookies() {
  // Safely call incase of security error
  try {
    document.cookie = '';
  } catch(e) {}
}

import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Styles, View, Text, Button, Modal} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useModalShare} from 'hooks/useModalShare';
import {FilesModalBulkDownload, modalFilesBulkDownload} from 'view/modals/FilesModalBulkDownload';
import {Vectors, Colors} from 'features/themes';
import {selectors} from 'store/files';
import * as app from 'store/app';

import * as appEffects from 'store/app/effects';
import * as filesEffects from 'store/files/effects';

export interface Props {
  id: string;
}

export function FilesHeader(props: Props) {
  const {id} = props;
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const viewport = useSelector(app.selectors.getViewport);
  const header = useSelector(app.selectors.getHeader);
  const parent = useSelector(selectors.getParent('current'));
  const folder = useSelector(selectors.getItem(id));
  const color = header ? header.colors.primary : Colors.primary.brand;
  const depth = folder ? folder.hierarchy && folder.hierarchy.length : 0;
  const name = folder ? folder.name : '';
  const owner = folder ? folder.owner : '';
  const url = folder ? folder.url : undefined;
  const isLarge = viewport.width > 800;

  const dispatch = useDispatch();
  const save = useCallback(filesEffects.pick(dispatch, {action: 'save', destination: 'myfiles', ids: [id]}), [id, dispatch]);
  const back = useCallback(filesEffects.navigate(dispatch), []);
  const login = useCallback(appEffects.gotoLogin(), []);

  const [showShare] = useModalShare(dispatch, [id], name, url);

  const bulkDownload = useCallback(() => Modal.show(
    <FilesModalBulkDownload
      dispatch={dispatch}
      ids={[id]}
      primaryColor={color}
    />
  , modalFilesBulkDownload), [id]);

  return (
    <View style={styles.root}>
      {depth > 0 &&
        <Button
          title={t`Go to ${parent[1]} ${parent[0]}`}
          style={styles.back}
          onPress={back}>
          <Svg style={styles.icon} viewBox={'0 0 20 20'} width={20} height={20}>
            <SvgPath d={Vectors.iconBack} fillColor={Colors.primary.brand}/>
          </Svg>
        </Button>
      }
      <Text style={styles.title}>
        {name}
      </Text>
      {owner && owner !== ' ' && isLarge &&
        <Text style={styles.owner}>
          {t`shared by ${owner || '...'}`}
        </Text>
      }
      <View style={styles.spacer}></View>
      <View style={styles.actions}>
        <Button
          title={t`Download ${name}`}
          style={styles.button}
          onPress={bulkDownload}>
          <Svg style={styles.dlfix} viewBox={'0 0 20 20'} width={19} height={19}>
            <SvgPath d={Vectors.iconDownload} fillColor={Colors.neutral.mid}/>
          </Svg>
          {isLarge &&
            <Text style={styles.action}>
              {t`DOWNLOAD`}
            </Text>
          }
        </Button>
        <Button
          title={t`Share ${name}`}
          style={styles.button}
          onPress={showShare}>
          <Svg viewBox={'0 0 20 20'} width={18} height={16}>
            <SvgPath d={Vectors.iconShare} fillColor={Colors.neutral.mid}/>
          </Svg>
          {isLarge &&
            <Text style={styles.action}>
              {t`SHARE`}
            </Text>
          }
        </Button>
        <Button
          title={t`Save ${name} to your account`}
          style={styles.button}
          onPress={isLoggedIn ? save : login}>
          <Svg viewBox={'0 0 60 48'} width={28} height={22}>
            <SvgPath d={Vectors.iconSave} fillColor={Colors.neutral.mid}/>
          </Svg>
          {isLarge &&
            <Text style={styles.action}>
              {t`SAVE TO MY FILES`}
            </Text>
          }
        </Button>
      </View>
    </View>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flexDirection: 'row',
    flex: 0,
    height: 46,
    margin: 14,
  }),
  large: Styles.createViewStyle({
    height: 72,
    marginHorizontal: 0,
  }),
  title: Styles.createTextStyle({
    lineHeight: 46,
    fontSize: 24,
    color: Colors.neutral.dark,
    paddingHorizontal: 8,
    flexShrink: 1,
    flexGrow: 0,
  }),
  titleLarge: Styles.createTextStyle({
    lineHeight: 72,
  }),
  icon: Styles.createViewStyle({
    marginLeft: 8,
  }),
  back: Styles.createButtonStyle({
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
  }),
  actions: Styles.createViewStyle({
    flexDirection: 'row',
    marginTop: 2,
    marginLeft: 12,
  }),
  spacer: Styles.createViewStyle({
    flex: 1,
  }),
  button: Styles.createButtonStyle({
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 12,
    paddingHorizontal: 8,
  }),
  dlfix: Styles.createViewStyle({
    marginTop: 4,
  }),
  action: Styles.createTextStyle({
    color: Colors.neutral.light,
    fontSize: 11,
    lineHeight: 46,
    marginLeft: 8,
  }),
  actionLarge: Styles.createTextStyle({
    lineHeight: 72,
  }),
  owner: Styles.createTextStyle({
    flexGrow: 1,
    flexShrink: 1,
    marginTop: 8,
    fontSize: 12,
    color: Colors.neutral.mid,
    alignSelf: 'center',
  }),
};

import {ImagesBase} from './models';

class Images implements ImagesBase {
  avatar = require('assets/images/avatar.png').default;
  bizBranding = require('assets/images/business-branding.png').default;
  bizSecurity = require('assets/images/business-security.png').default;
  bizSharing = require('assets/images/business-sharing.png').default;
  emptyBackground = require('assets/images/empty-bg.png').default;
  emptyDrag = require('assets/images/empty-drag.png').default;
  welcomeBrowse = require('assets/images/welcome-browse.png').default;
  welcomeUpload = require('assets/images/welcome-upload.png').default;
  welcomeView = require('assets/images/welcome-view.png').default;
}

export default new Images();

import {t} from '@lingui/macro';
import React, {useCallback, useState, useEffect} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Styles, View, Text, Button, TextInput, Modal, Picker, Clipboard, Types} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {InputField} from 'controls/InputField';
import {IconButton} from 'controls/IconButton';
import {Dialog} from 'controls/Dialog';
import {alert, configureFileDrop} from 'features/mediafire';
import {Light, Colors, Vectors} from 'features/themes';

export const modalFilesFileDrop = 'filedrop';

export interface Props {
  dispatch: Dispatch;
  id: string;
  name: string;
  primaryColor: string;
  isFileDrop: boolean;
  isMinimal?: boolean;
}

const pickerItemsFullWidth: Types.PickerPropsItem[] = [
  {label: t`Choose an option`, value: ''},
  {label: t`Yes, use the full width of embed location`, value: 'full'},
  {label: t`No, use a fixed width (600 pixels wide)`, value: '600'},
];

const pickerItemsEmailNotify: Types.PickerPropsItem[] = [
  {label: t`Choose an option`, value: ''},
  {label: t`No, I do not want email notifications`, value: 'no'},
  {label: t`Yes, send me email notifications`, value: 'yes'},
];

export function FilesModalFileDrop(props: Props) {
  const {id, name, primaryColor, isMinimal, isFileDrop} = props;
  const [stateEnabled, setEnabled] = useState(isFileDrop);
  const [statePromptConfigure, setPromptConfigure] = useState(false);
  const [stateFullWidth, setFullWidth] = useState('');
  const [stateEmailNotify, setEmailNotify] = useState('');
  const [stateContentEmbedded, setContentEmbedded] = useState('Loading...');
  const [stateContentHosted, setContentHosted] = useState('Loading...');
  const [stateTitle, setTitle] = useState('');
  const classes = {
    inputWrapper: [
      styles.inputWrapper,
      Styles.createViewStyle({borderColor: primaryColor}, false),
    ],
  };

  const dispatch = props.dispatch;
  const close = useCallback(() => Dialog.dismissAnimated(modalFilesFileDrop), []);
  const cancel = useCallback(() => setPromptConfigure(false), [setPromptConfigure]);
  const configure = useCallback(() => setPromptConfigure(true), [setPromptConfigure]);
  const pickWidth = useCallback((e) => setFullWidth(e), [setPromptConfigure]);
  const pickEmail = useCallback((e) => setEmailNotify(e), [setEmailNotify]);

  const disable = useCallback(async () => {
    const response = await configureFileDrop('disable', id);
    if (response && response['result'] === 'Success') {
      alert(dispatch, t`Disabled the “${name}” FileDrop`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to disable the FileDrop`;
      alert(dispatch, message, 'fail');
    }
    Modal.dismiss(modalFilesFileDrop);
  }, [id]);

  const update = useCallback(async () => {
    const response = await configureFileDrop('enable', id, stateTitle, stateFullWidth, stateEmailNotify);
    if (response && response['result'] === 'Success') {
      alert(dispatch, t`Updated the “${name}” FileDrop`, 'info');
      setContentEmbedded(response.htmlEmbedCode);
      setContentHosted(response.hostedFileDrop);
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to update the FileDrop`;
      alert(dispatch, message, 'fail');
    }
    if (stateEnabled) {
      setPromptConfigure(false);
    } else {
      setEnabled(true);
    }
  }, [id, stateTitle, stateFullWidth, stateEmailNotify]);

  const copyHostedLink = useCallback(() => {
    if (stateContentHosted !== 'Loading...') {
      Clipboard.setText(stateContentHosted);
      alert(dispatch, t`Copied hosted filedrop link`, 'info');
    }
  }, [stateContentHosted]);

  const copyEmbedCode = useCallback(() => {
    if (stateContentEmbedded !== 'Loading...') {
      Clipboard.setText(stateContentEmbedded);
      alert(dispatch, t`Copied embedded filedrop code`, 'info');
    }
  }, [stateContentEmbedded]);

  useEffect(() => {
    configureFileDrop('view', id).then((response) => {
      setContentEmbedded(response.htmlEmbedCode);
      setContentHosted(response.hostedFileDrop);
    });
  }, []);

  return (
    <Dialog id={modalFilesFileDrop} width={480}>
      <View style={styles.root}>
        <View style={styles.header}>
          <Text numberOfLines={1} style={styles.title}>
            {t`Configure FileDrop`}
          </Text>
          <Button style={styles.close} onPress={close} title={t`Close FileDrop dialog`}>
            <Svg viewBox="0 0 14 14" width={14} height={14}>
              <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.mid}/>
            </Svg>
          </Button>
        </View>
        <View style={styles.content}>
          {!stateEnabled &&
            <View style={styles.share}>
              <Text style={styles.message}>
                {t`Would you like to enable this folder as a FileDrop? This action can be undone at anytime.`}
              </Text>
            </View>
          }
          {stateEnabled && !statePromptConfigure &&
            <View style={styles.share}>
              <Text style={styles.label}>
                {t`Share this link with friends to allow them to upload to this folder:`}
              </Text>
              <View style={classes.inputWrapper}>
                <TextInput
                  style={[styles.inputBlocks, styles.inputHosted]}
                  multiline={true}
                  editable={false}
                  value={stateContentHosted}
                />
                <Button title={t`Copy Hosted FileDrop Link`} style={styles.copyIcon} onPress={copyHostedLink}>
                  <Svg viewBox="0 0 20 10" width={18} height={18}>
                    <SvgPath d={Vectors.iconPublic} fillColor={Colors.neutral.mid}/>
                  </Svg>
                </Button>
              </View>
              <View style={styles.spacerV}/>
              <Text style={styles.label}>
                {t`To embed a filedrop, include the code below into your webpage:`}
              </Text>
              <View style={classes.inputWrapper}>
                <TextInput
                  style={[styles.inputBlocks, styles.inputEmbedded]}
                  multiline={true}
                  editable={false}
                  value={stateContentEmbedded}
                />
                <Button title={t`Copy Embedded FileDrop Code`} style={styles.copyIcon} onPress={copyEmbedCode}>
                  <Svg viewBox="0 0 20 10" width={18} height={18}>
                    <SvgPath d={Vectors.iconPublic} fillColor={Colors.neutral.mid}/>
                  </Svg>
                </Button>
              </View>
            </View>
          }
          {stateEnabled && statePromptConfigure &&
            <View style={styles.configure}>
              <Text style={styles.label}>
                {t`Message displayed in uploader:`}
              </Text>
              <InputField
                autoFocus
                onChangeText={setTitle}
                onSubmitEditing={update}
                placeholder={t`Upload to my MediaFire FileDrop`}
                placeholderTextColor={Light.dialogInputPlaceholder}
              />
              <View style={styles.spacerV}/>
              <Text style={styles.label}>
                {t`Should the embedded uploader extend to the width of the page?`}
              </Text>
              <Picker
                style={styles.picker}
                items={pickerItemsFullWidth}
                selectedValue={stateFullWidth}
                onValueChange={pickWidth}
              />
              <View style={styles.spacerV}/>
              <Text style={styles.label}>
                {t`Would you like to receive email notifications for uploads?`}
              </Text>
              <Picker
                style={styles.picker}
                items={pickerItemsEmailNotify}
                selectedValue={stateEmailNotify}
                onValueChange={pickEmail}
              />
            </View>
          }
        </View>
        <View style={styles.buttons}>
          {!stateEnabled && <React.Fragment>
            <IconButton
              mode="primary"
              title={t`Enable FileDrop`}
              text={t`ENABLE`}
              onPress={update}
            />
            <View style={styles.spacerH}/>
            <IconButton
              mode="cancel"
              title={t`Close FileDrop dialog`}
              text={t`CANCEL`}
              onPress={close}
            />
          </React.Fragment>}
          {stateEnabled && statePromptConfigure && <React.Fragment>
            <IconButton
              mode="primary"
              title={t`Save FileDrop settings`}
              text={t`SAVE`}
              onPress={update}
            />
            <View style={styles.spacerH}/>
            <IconButton
              mode="destructive"
              title={t`Disable FileDrop dialog`}
              text={t`DISABLE`}
              onPress={disable}
            />
            <View style={styles.spacerH}/>
            <IconButton
              mode="cancel"
              title={t`Cancel FileDrop configuration`}
              text={t`CANCEL`}
              onPress={cancel}
            />
          </React.Fragment>}
          {stateEnabled && !statePromptConfigure && <React.Fragment>
            <IconButton
              mode="primary"
              title={t`Configure FileDrop`}
              text={`CONFIGURE ${isMinimal ? '' : 'FILEDROP'}`}
              onPress={configure}
            />
            <View style={styles.spacerH}/>
            <IconButton
              mode="cancel"
              title={t`Close FileDrop dialog`}
              text={t`CANCEL`}
              onPress={close}
            />
          </React.Fragment>}
        </View>
      </View>
    </Dialog>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    borderWidth: 1,
    borderColor: Light.dialogBorder,
    backgroundColor: Light.dialogBackground,
  }),
  spacerV: Styles.createViewStyle({
    height: 20,
  }),
  spacerH: Styles.createViewStyle({
    width: 6,
  }),
  content: Styles.createViewStyle({
    flex: 0,
    paddingHorizontal: 20,
  }),
  share: Styles.createViewStyle({
    flex: 1,
  }),
  configure: Styles.createViewStyle({
    flex: 1,
  }),
  inputBlocks: Styles.createTextInputStyle({
    flex: 1,
    fontSize: 12,
    padding: 10,
    borderRadius: 0,
    color: Light.dialogInputText,
    backgroundColor: Light.dialogInputBackground,
  }),
  inputHosted: Styles.createTextInputStyle({
    height: 80,
  }),
  inputEmbedded: Styles.createTextInputStyle({
    height: 120,
  }),
  inputWrapper: Styles.createViewStyle({
    borderBottomWidth: 2,
    borderColor: Colors.primary.brand,
    backgroundColor: Colors.neutral.lightest,
  }),
  buttons: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row-reverse',
    padding: 20,
  }),
  copyIcon: Styles.createButtonStyle({
    position: 'absolute',
    paddingHorizontal: 10,
    paddingVertical: 8,
    right: 0,
    bottom: 0,
    flex: 0,
  }),
  picker: Styles.createPickerStyle({
    maxHeight: 200,
    padding: 10,
    margin: 1,
    color: Light.dialogInputText,
    borderColor: Light.dialogBorder,
    backgroundColor: Light.dialogInputBackground,
  }),
  label: Styles.createTextStyle({
    flex: 1,
    fontSize: 12,
    fontWeight: '600',
    marginBottom: 10,
    color: Light.dialogText,
  }),
  message: Styles.createTextStyle({
    flex: 1,
    fontSize: 14,
    marginBottom: 10,
    color: Light.dialogText,
  }),
  header: Styles.createViewStyle({
    flex: 0,
  }),
  title: Styles.createTextStyle({
    fontSize: 20,
    fontWeight: '300',
    color: Light.dialogTitle,
    lineHeight: 65,
    marginLeft: 19,
  }),
  close: Styles.createButtonStyle({
    position: 'absolute',
    alignItems: 'center',
    height: 65,
    width: 58,
    right: 1,
    top: -2,
  }),
};

import React, {useCallback, Component} from 'react';
import {Popup, Types} from 'react-ult';
import {Menu, MenuItem} from 'controls/Menu';

const _modalMenu = 'menu';

export function useMenu(
  items: MenuItem[],
  onSelect?: (command: string) => void,
  anchor?: Component,
  anchorUpdate?: Function,
  positionPriorities?: Types.PopupPosition[],
  dismissIfShown?: boolean,
  cacheable?: boolean,
  multiMode?: boolean,
) {
  const show = useCallback((e?: any) => {
    if (!anchorUpdate) {
      e.stopPropagation();
      e.preventDefault();
    }
    Popup.show({
      cacheable,
      dismissIfShown,
      positionPriorities,
      getAnchor: () => anchor,
      renderPopup: (
        _anchorPosition: Types.PopupPosition,
        _anchorOffset: number,
        _popupWidth: number,
        _popupHeight: number,
      ) => {
        if (anchorUpdate)
          anchorUpdate(e.clientX, e.clientY);
        return (
          <Menu
            focusFirst
            multiMode={multiMode}
            menuItems={items.filter(e => !!e)}
            onSelectItem={onSelect}
          />
        );
      },
  }, _modalMenu);
  }, [items, anchor]);

  const close = useCallback(() => {
    Popup.dismiss(_modalMenu);
  }, []);

  return [show, close];
}

/*

  useEffect(() => {
    if (ActionSheet) {
      const options: string[] = [];
      options.push('Cancel');
      ActionSheet.showActionSheetWithOptions({
        title,
        options,
        cancelButtonIndex: options.length - 1,
        destructiveButtonIndex: options.indexOf('Delete Permanently') !== -1
          ? options.indexOf('Delete Permanently')
          : options.indexOf('Move to Trash'),
      }, (index) => {
        const choice = options[index];
        switch (choice) {
          case 'Share':
            return share();
          case 'Cancel':
          default:
            return Popup.dismiss(modalFilesMenu);
        }
      });
    }
  }, [ids]);

*/

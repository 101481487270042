import {t} from '@lingui/macro';
import React, {useCallback, useEffect, useState} from 'react';
import {Styles, View, Text, Image, Storage} from 'react-ult';
import {Dispatch} from '@reduxjs/toolkit';
import {Dialog} from 'controls/Dialog';
import {IconButton} from 'controls/IconButton';
import {Light, Colors} from 'features/themes';
import images from 'features/images';
import * as effects from 'store/files/effects';

export const modalFilesWelcome = 'welcome';

export interface Props {
  dispatch: Dispatch;
  isBusiness?: boolean;
  isMinimal?: boolean;
}

export function FilesModalWelcome(props: Props) {
  const [statePage, setPage] = useState('welcome');
  const isPageWelcome = statePage === 'welcome';
  const isPageBusiness = statePage === 'business';
  const title = isPageWelcome
    ? t`Welcome to your new My Files!`
    : t`Explore your Business Account`;

  const next = useCallback(() => setPage('business'), []);
  const close = useCallback(() => Dialog.dismissAnimated(modalFilesWelcome), []);
  const upload = useCallback(effects.uploadStart(props.dispatch, 'myfiles'), [props.dispatch]);

  // Save that we displayed the welcome dialog
  useEffect(() => {
    try {
      Storage.setItem('welcome:shown', '1');
    } catch (e) {}
  }, []);

  return (
    <Dialog id={modalFilesWelcome}>
      <View style={styles.root}>
        <Text style={styles.header}>{title}</Text>
        {isPageWelcome &&
          <View style={styles.columns}>
            <View style={styles.column}>
              <Text style={styles.subheader}>
                {t`Upload Files`}
              </Text>
              <Image style={styles.image} source={images.welcomeUpload} resizeMode="cover"/>
              <Text style={styles.text}>
                {t`Click the upload button at the top or drag & drop files anywhere.`}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.subheader}>
                {t`Browse Folders`}
              </Text>
              <Image style={styles.image} source={images.welcomeBrowse} resizeMode="cover"/>
              <Text style={styles.text}>
                {t`Quickly browse and organize your files by using the folder browser.`}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.subheader}>
                {t`View Options`}
              </Text>
              <Image style={styles.image} source={images.welcomeView} resizeMode="cover"/>
              <Text style={styles.text}>
                {t`Toggle between the list view and the gallery view at any time.`}
              </Text>
            </View>
          </View>
        }
        {isPageBusiness &&
          <View style={styles.columns}>
            <View style={styles.column}>
              <Text style={styles.subheader}>
                {t`Advanced Sharing`}
              </Text>
              <Image style={styles.image} source={images.bizSharing} resizeMode="cover"/>
              <Text style={styles.text}>
                {t`Select files & folders to share a link with your custom subdomain.`}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.subheader}>
                {t`Customize Your Branding`}
              </Text>
              <Image style={styles.image} source={images.bizBranding} resizeMode="cover"/>
              <Text style={styles.text}>
                {t`Customize your colors, logo, and more in your account settings.`}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.subheader}>
                {t`Detailed Security Log`}
              </Text>
              <Image style={styles.image} source={images.bizSecurity} resizeMode="cover"/>
              <Text style={styles.text}>
                {t`Know who made what changes to your account and when.`}
              </Text>
            </View>
          </View>
        }
        <View style={styles.buttons}>
          {(props.isBusiness && isPageWelcome) &&
            <React.Fragment>
              <IconButton
                mode="primary"
                text={t`NEXT`}
                title={t`Show next page`}
                onPress={next}
              />
              <View style={styles.spacer}/>
            </React.Fragment>
          }
          {(!props.isBusiness || isPageBusiness) &&
            <React.Fragment>
              <IconButton
                mode="primary"
                text={t`UPLOAD FILES`}
                title={t`Open uploader`}
                onPress={upload}
              />
              <View style={styles.spacer}/>
              <IconButton
                mode="cancel"
                text={t`DISMISS`}
                title={t`Close welcome dialog`}
                onPress={close}
              />
            </React.Fragment>
          }
        </View>
      </View>
    </Dialog>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    borderWidth: 1,
    borderColor: Light.dialogBorder,
    backgroundColor: Light.dialogBackground,
  }),
  columns: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
  }),
  column: Styles.createViewStyle({
    flex: 1,
    width: 200,
    margin: 20,
  }),
  buttons: Styles.createViewStyle({
    flex: 1,
    padding: 20,
    flexDirection: 'row-reverse',
  }),
  spacer: Styles.createViewStyle({
    width: 6,
  }),
  image: Styles.createImageStyle({
    width: 200,
    height: 120,
    marginTop: 12,
    marginBottom: 24,
    borderWidth: 1,
    borderColor: Light.dialogBorder,
  }),
  header: Styles.createTextStyle({
    marginBottom: 16,
    marginTop: 32,
    fontSize: 24,
    fontWeight: '600',
    textAlign: 'center',
    color: Colors.primary.brand,
  }),
  subheader: Styles.createTextStyle({
    fontSize: 16,
    fontWeight: '600',
    color: Light.dialogTitle,
  }),
  text: Styles.createTextStyle({
    fontSize: 13,
    color: Light.dialogText,
  }),
};

import {luminance} from 'features/common';
import {Colors} from './styles';
import {Theme} from './models';

class LightTheme implements Theme {
  // General
  logoColor = Colors.primary.brand;
  fileIconColor = Colors.neutral.dark;
  mainMenuBorder = luminance(Colors.neutral.dark, 0.2);
  headerBackground = Colors.neutral.white;
  contentBackground = Colors.neutral.white;
  borderSeparator = '#ccc';
  borderSeparatorLight = '#eee';
  separator = '#eee';

  // Lists
  listItemTitle = Colors.neutral.lighter;
  listItemNormalBackground = luminance(Colors.neutral.dark, -0.4);
  listItemNormalBorder = Colors.neutral.dark;
  listItemHoverBackground = luminance(Colors.neutral.dark, -0.6);
  listItemHoverTreeBackground = luminance(Colors.neutral.dark, -0.6);
  listItemHoverSelectedBackground = luminance(Colors.primary.dark, -0.2);
  listItemSelectBackground = luminance(Colors.primary.dark, 0);
  listItemSelectBorder = luminance(Colors.primary.dark, 0.2);
  listItemSelectText = Colors.neutral.light;
  listItemFocusBackground = Colors.neutral.darker;
  listItemFocusSelectedBackground = luminance(Colors.primary.dark, 0.4);
  listItemDraggingBackground = Colors.neutral.darker;
  listItemDropBackground = luminance(Colors.neutral.darker, -0.6);
  listItemTreeBackground = Colors.neutral.white;
  listItemTreeHoverBackground = Colors.neutral.white;

  // Buttons
  buttonPrimaryBorder = Colors.primary.brand;
  buttonPrimaryBackground = Colors.primary.brand;
  buttonPrimaryBackgroundHover = luminance(Colors.primary.brand, -0.2);
  textButtonPrimary = Colors.neutral.white;

  buttonCancelBorder = Colors.neutral.dark;
  buttonCancelBackground = Colors.neutral.darker;
  buttonCancelBackgroundHover = luminance(Colors.neutral.darker, -0.2);
  textButtonCancel = Colors.neutral.mid;

  buttonDestructiveBorder = Colors.secondary.brightRed;
  buttonDestructiveBackground = Colors.secondary.brightRed;
  buttonDestructiveBackgroundHover = luminance(Colors.secondary.brightRed, -0.2);
  textButtonDestructive = Colors.neutral.white;

  // Dialogs
  dialogBackground = Colors.neutral.darker;
  dialogBorder = Colors.neutral.dark;
  dialogText = Colors.neutral.mid;
  dialogTitle = Colors.neutral.lighter;
  dialogInputText = Colors.neutral.white;
  dialogInputPlaceholder = Colors.neutral.mid;
  dialogInputBackground = luminance(Colors.neutral.darker, -0.4);
  dialogBehind = 'rgba(0, 0, 0, 0.6)';

  // Menus
  menuText = Colors.neutral.mid;
  menuTextDisabled = '#bbb';
  menuItemHover = Colors.neutral.darker;
  menuBackground = Colors.neutral.dark;
  menuBorder = luminance(Colors.neutral.mid, -0.4);
  
  menuHeaderText = Colors.neutral.mid;
  menuHeaderBorder = Colors.neutral.dark;
  menuHeaderBackground = Colors.neutral.darker;

  menuHeaderMultiBackground = luminance(Colors.primary.dark, 0);
  menuHeaderMultiBorder = '#5d75af';
  menuHeaderMultiText = '#b6ccff';

  // File Breadcrumbs
  breadcrumbsText = Colors.neutral.light;
  breadcrumbsBorder = Colors.neutral.dark;
  breadcrumbsBackground = luminance(Colors.neutral.dark, -0.4);
  breadcrumbsShareBackground = Colors.neutral.darker;
  breadcrumbsShareBorder = Colors.neutral.mid;

  // Navigator
  navigatorText = Colors.neutral.light;
  navigatorBorder = Colors.neutral.dark;
  navigatorBackground = luminance(Colors.neutral.dark, -0.4);

  // Files
  filesBackground = luminance(Colors.neutral.dark, -0.4);
  filesTreeText = Colors.neutral.light;
  filesTreeBorder = Colors.neutral.dark;
  filesTreeBackground = luminance(Colors.neutral.dark, -0.4);
  filesTreeHoverBackground = Colors.neutral.lighter;

  // Uploader
  uploaderBarTitle = Colors.neutral.lighter;
  uploaderBarText = Colors.neutral.light;
  uploaderBarBorder = Colors.neutral.dark;
  uploaderBarBackground = Colors.neutral.darker;
  uploaderContentBackground = luminance(Colors.neutral.dark, -0.4);
  uploaderContentIcon = Colors.neutral.dark;

  // Misc
  barSelectButtonBorder = '#5d75af';
  diagnosticUploadTool = '#4a9eff';
}

export default new LightTheme();

import {AppStore} from 'globals/types';
import {createSlice} from '@reduxjs/toolkit';
import * as selectors from './selectors';
import * as reducers from './reducers';

export {selectors};
export default createSlice({
  reducers,
  name: 'app',
  initialState: {
    revision: 0,
    notifications: 0,
    active: false,
    menuOpen: false,
    feature: null,
    viewport: {width: -1, height: -1},
    alerts: [],
  } as AppStore,
});

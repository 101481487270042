import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';
import {alert, archive} from 'features/mediafire';

const _modalTrash = 'trash';

export function useModalTrash(ids: string[], dispatch: Dispatch) {
  const submit = useCallback(async () => {
    const response = await archive(dispatch, ids);
    if (response[0] || response[1]) {
      alert(dispatch, `Successfully moved items to trash`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : 'Failed to move items to trash';
      alert(dispatch, message, 'fail');
    }
    Modal.dismiss(_modalTrash);
  }, [ids]);

  const show = useCallback(() => {
    Modal.show(
      <Prompt
        id={_modalTrash}
        title={t`Move to Trash`}
        text={t`Are you sure you want to move these items to Trash? You can always restore items from your trash if you change your mind.`}
        buttons={[{
          text: t`MOVE TO TRASH`,
          mode: 'destructive',
          onPress: () => {
            Prompt.dismissAnimated(_modalTrash);
            submit();
          },
        }, {
          text: t`CANCEL`,
          mode: 'cancel',
          onPress: () => {
            Prompt.dismissAnimated(_modalTrash);
          },
        }]}
      />
    , _modalTrash);
  }, [ids]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalTrash);
  }, [ids]);

  return [show, close];
}

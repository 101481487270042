import {FilesStore} from 'globals/types';
import {createSlice} from '@reduxjs/toolkit';
import * as selectors from './selectors';
import * as reducers from './reducers';

export {selectors};
export default createSlice({
  reducers,
  name: 'files',
  initialState: {
    root: {id: '', name: ''},
    sort: {category: 'name', order: 'asc'},
    filter: {type: 'all'},
    items: {},
    layout: 'list',
    views: {},
    gallery: false,
    current: '',
    picker: '',
    focused: null,
    browser: 'myfiles',
    upload: null,
    uploadItems: {},
    uploadView: {files: [], folders: []},
    downloadItems: {},
    downloadBulk: [],
    downloadView: {files: [], folders: []},
    uploadMinimized: false,
    downloadMinimized: false,
    selection: [],
  } as FilesStore,
});

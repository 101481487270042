import {parseContentItem, getShareLink} from 'features/mediafire';
import * as MF from 'globals/types';

// GENERIC

export function move(state: MF.FilesStore, action: MF.FilesMove) {
  const {ids} = action.payload;
  state.selection = [];
  state.search = undefined;
  state.query = undefined;
  ids.forEach(id => {
    const item = state.items[id];
    if (item) item.state = MF.FilesItemState.isMoving;
  });
}

export function copy(state: MF.FilesStore, action: MF.FilesCopy) {
  const {ids} = action.payload;
  state.selection = [];
  state.search = undefined;
  state.query = undefined;
  ids.forEach(id => {
    const item = state.items[id];
    if (item) item.state = MF.FilesItemState.isCopying;
  });
}

export function update(state: MF.FilesStore, action: MF.FilesUpdate) {
  const {id} = action.payload;
  const item = state.items[id];
  item.state = MF.FilesItemState.isUpdating;
}

export function drag(state: MF.FilesStore, action: MF.FilesDrag) {
  const {ids} = action.payload;
  state.drag = ids || undefined;
}

export function select(state: MF.FilesStore, action: MF.FilesSelect) {
  const {ids, ranged, view} = action.payload;
  // Range selection
  if (ranged && ids.length === 1) {
    const list = [...view.folders, ...view.files];
    const to = list.indexOf(ids[0]);
    const from = list.indexOf(state.focused);
    state.selection = from <= to
      ? list.slice(from, to + 1)
      : list.slice(to, from + 1);
  // Clear selection
  } else if (ids.length === 0) {
    state.selection = [];
  // Toggle selection
  } else {
    ids.forEach(id => {
      const index = state.selection.indexOf(id);
      if (index === -1) {
        state.selection.push(id);
      } else {
        state.selection.splice(index, 1);
      }
    });
  }
}

export function focus(state: MF.FilesStore, action: MF.FilesFocus) {
  const {id} = action.payload;
  state.focused = id || null;
}

export function search(state: MF.FilesStore, action: MF.FilesSearch) {
  const {id, query} = action.payload;
  state.query = query || undefined;
  state.search = id || undefined;
  state.views.search = undefined;
}

export function filter(state: MF.FilesStore, action: MF.FilesFiltering) {
  state.filter = action.payload;
}

export function sort(state: MF.FilesStore, action: MF.FilesSorting) {
  state.sort = action.payload;
}

export function layout(state: MF.FilesStore) {
  state.layout = state.layout === 'grid' ? 'list' : 'grid';
}

// NAVIGATING

export function pick(state: MF.FilesStore, action: MF.FilesPicking) {
  state.pick = action.payload;
}

export function preview(state: MF.FilesStore, action: MF.FilesPreview) {
  const {id, name} = action.payload;
  state.preview = {id, name};
  state.previewProgress = 0;
}

export function previewJob(state: MF.FilesStore, action: MF.FilesPreviewJob) {
  const {jobId} = action.payload;
  state.preview.jobId = jobId;
}

export function previewProgress(state: MF.FilesStore, action: MF.FilesPreviewProgress) {
  const {progress} = action.payload;
  state.previewProgress = progress;
}

export function previewClose(state: MF.FilesStore) {
  state.preview = undefined;
  state.previewProgress = 0;
}

export function browse(state: MF.FilesStore, action: MF.FilesBrowse) {
  return {
    ...state,
    browser: action.payload.id,
  };
}

export function navigate(state: MF.FilesStore, action: MF.FilesNavigate) {
  return {
    ...state,
    selection: [],
    search: undefined,
    query: undefined,
    current: action.payload.id,
  };
}

export function chunk(state: MF.FilesStore, action: MF.FilesChunk) {
  const {id, type, content} = action.payload;
  const keys = [];
  content.folderContent[type].forEach((f: any) => {
    const key = f.folderkey || f.quickkey;
    const cache = state.items[key];
    const data = parseContentItem(f, state.items[id] && state.items[id].hierarchy, null, cache);
    state.items[key] = data;
    keys.push(key);
  });
  
  state.views[id][type] = Array.from(new Set([
    ...state.views[id][type],
    ...keys,
  ]));
}

export function sync(state: MF.FilesStore, action: MF.FilesSync) {
  const {ids} = action.payload;
  ids.forEach(id => {
    const item = state.items[id];
    if (item)
      item.state = MF.FilesItemState.isNormal;
  });
}

export function load(state: MF.FilesStore, action: MF.FilesLoad) {
  const {id, root, details, depth} = action.payload;
  const chain = depth && depth.chainFolders;
  const parent = details && parseContentItem(
    details.folderInfo,
    null,
    chain,
    state.items[id],
  );

  const folders = [];
  const files = [];

  if (root) state.root = root;
  if (parent) state.items[id] = parent;

  action.payload.folders && (action.payload.folders as any).forEach((f: any) => {
    const cache = state.items[f.folderkey];
    const data = parseContentItem(f, state.items[id] && state.items[id].hierarchy, null, cache);
    state.items[f.folderkey] = data;
    folders.push(f.folderkey);
  });

  action.payload.files && (action.payload.files as any).forEach((f: any) => {
    const cache = state.items[f.quickkey];
    const data = parseContentItem(f, state.items[id] && state.items[id].hierarchy, null, cache);
    state.items[f.quickkey] = data;
    files.push(f.quickkey);
  });

  state.views[id] = {folders, files};
}

export function loadMulti(state: MF.FilesStore, action: MF.FilesLoadMulti) {
  const files = [];
  const folders = [];

  action.payload.items && (action.payload.items as any).forEach((f: any) => {
    const id = f.folderkey || f.quickkey;
    const cache = state.items[id];
    const data = parseContentItem(f, null, null, cache);
    state.items[id] = data;
    if (f.folderkey) {
      folders.push(id);
    } else {
      files.push(id);
    }
  });

  action.payload.folders && (action.payload.folders as any).forEach((f: any) => {
    const cache = state.items[f.folderkey];
    const data = parseContentItem(f, null, null, cache);
    state.items[f.folderkey] = data;
    folders.push(f.folderkey);
  });

  action.payload.files && (action.payload.files as any).forEach((f: any) => {
    const cache = state.items[f.quickkey];
    const data = parseContentItem(f, null, null, cache);
    state.items[f.quickkey] = data;
    files.push(f.quickkey);
  });

  if (action.payload.virtual) {
    state.views[action.payload.virtual] = {folders, files};
  }
}

export function loadTrash(state: MF.FilesStore, action: MF.FilesLoadTrash) {
  const {id, revision, folderCount, fileCount} = action.payload;
  const folders = [];
  const files = [];

  state.root = {id: 'myfiles', name: 'My Files'};
  state.items[id] = {
    id,
    revision,
    state: 0,
    name: 'Trash',
    type: 'folder',
    url: getShareLink(id, 'folder'),
    folders: folderCount || 0,
    files: fileCount || 0,
    hierarchy: [],
    private: true,
    created: '',
  };

  action.payload.folders && (action.payload.folders as any).forEach((f: any) => {
    const cache = state.items[f.folderkey];
    const data = parseContentItem(f, null, null, cache);
    state.items[f.folderkey] = data;
    folders.push(f.folderkey);
  });

  action.payload.files && (action.payload.files as any).forEach((f: any) => {
    const cache = state.items[f.quickkey];
    const data = parseContentItem(f, null, null, cache);
    state.items[f.quickkey] = data;
    files.push(f.quickkey);
  });

  state.views[id] = {folders, files};
}

// DOWNLOADING

export function bulkDownload(state: MF.FilesStore, action: MF.FilesBulkDownload) {
  state.downloadMinimized = true;
  state.downloadBulk.push(action.payload);
}

export function bulkDownloadClose(state: MF.FilesStore) {
  state.downloadBulk = [];
  state.downloadItems = {};
  state.downloadView = {files: [], folders: []};
}

// UPLOADING

export function upload(state: MF.FilesStore, action: MF.FilesUpload) {
  const {id, files} = action.payload;
  const isVirtual = id === 'recent' ||  id === 'following' || id === 'trash' || id === 'search';
  const targetId = isVirtual ? 'myfiles' : id;
  if (id) {
    state.upload = targetId;
    state.uploadWeb = undefined;
    state.uploadFiles = files;
    state.uploadMinimized = !!files;
    state.uploadView = {files: [], folders: []};
    state.uploadItems = {};
  } else {
    state.upload = null;
    state.uploadWeb = undefined;
    state.uploadFiles = undefined;
    state.uploadView = {files: [], folders: []};
    state.uploadItems = {};
  }
}

export function uploadWeb(state: MF.FilesStore, action: MF.FilesUploadWeb) {
  const {id, files} = action.payload;
  const isVirtual = id === 'recent' ||  id === 'following' || id === 'trash' || id === 'search';
  const targetId = isVirtual ? 'myfiles' : id;
  state.upload = targetId;
  state.uploadWeb = files;
  state.uploadFiles = undefined;
  state.uploadView = {files: [], folders: []};
  state.uploadItems = {};
}

export function uploadMinimize(state: MF.FilesStore, action: MF.FilesUploadMinimize) {
  const {minimized} = action.payload;
  state.uploadMinimized = minimized;
}

export function downloadMinimize(state: MF.FilesStore, action: MF.FilesDownloadMinimize) {
  const {minimized} = action.payload;
  state.downloadMinimized = minimized;
}

export function uploadSync(state: MF.FilesStore, action: MF.FilesUploadSync) {
  const {items} = action.payload;
  if (state.upload) {
    items.forEach(item => {
      const path = item.name;
      if (path) {
        state.uploadItems[path] = item;
        if (!state.uploadView.files.includes(path)) {
          state.uploadView.files.push(path);
          state.uploadView.files.sort((a, b) =>
            state.uploadItems[a].size - state.uploadItems[b].size
          )
        }
      }
    });
  }
}

export function downloadSync(state: MF.FilesStore, action: MF.FilesDownloadSync) {
  if (state.downloadBulk.length > 0) {
    const {items} = action.payload;
    items.forEach(item => {
      const path = item.id;
      if (path) {
        state.downloadItems[path] = item;
        if (!state.downloadView.files.includes(path)) {
          state.downloadView.files.push(path);
        }
      }
    });
  }
}

import {t} from '@lingui/macro';
import React, {Fragment, useState, useCallback, useEffect} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Styles, View, Text, Button, Modal} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {Dialog} from 'controls/Dialog';
import {IconButton} from 'controls/IconButton';
import {useModalUpsellFeature} from 'hooks/useModalUpsellFeature';
import {alert, bulkDownload, bulkDownloadPreview, bulkDownloadAdd} from 'features/mediafire';
import {Light, Colors, Vectors} from 'features/themes';
import {bytesize} from 'features/common';
import {FilesErrors} from 'globals/types';

export const modalFilesBulkDownload = 'bulk-download';

export interface Props {
  dispatch: Dispatch;
  ids: string[];
  primaryColor: string;
}

export function FilesModalBulkDownload(props: Props) {
  const [isUserCharged, promptUseBandwidth] = useState(false);
  const [isLargeDownload, promptLargeDownload] = useState(false);
  const [isDownloadReady, setDownloadReady] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [fileName, setFileName] = useState('');
  const [statusKey, setStatusKey] = useState('');
  const {ids} = props;

  const dispatch = props.dispatch;
  const close = useCallback(() => Dialog.dismissAnimated(modalFilesBulkDownload), []);

  const [showUpsell] = useModalUpsellFeature('pro', 'bulkdownload');

  const submit = useCallback(async () => {
    const response = await bulkDownload(ids, statusKey, isUserCharged, isLargeDownload);
    if (response && response['result'] === 'Success') {
      bulkDownloadAdd(dispatch, {statusKey, fileName, totalSize});
      Modal.dismiss(modalFilesBulkDownload);
    } else {
      if (response && response.error === FilesErrors.ZipTotalSizeTooBig) {
        promptLargeDownload(true);
      } else if (response && (response.error === FilesErrors.ZipOwnerInsufficientBandwidth
        || response.error === FilesErrors.ZipOwnerNotPro)) {
        promptUseBandwidth(true);
      } else {
        const message = response && response['error']
          ? response['message']
          : 'Failed to start bulk download';
        alert(dispatch, message, 'fail');
        Modal.dismiss(modalFilesBulkDownload);
      }
    }
  }, [ids, statusKey, isLargeDownload, isUserCharged]);

  useEffect(() => {
    bulkDownloadPreview(ids).then((res) => {
      if (res && res.result === 'Success') {
        setTotalSize(res.totalSizeEstimate);
        setTotalItems(res.fileCount);
        setFileName(res.fileName);
        setStatusKey(res.statusKey);
        setDownloadReady(true);
      } else {
        if (res.error && res.message) {
          Modal.dismiss(modalFilesBulkDownload);
          if (res.error === 148) {
            showUpsell();
          } else {
            alert(dispatch, res?.message ?? 'Failed to start bulk download', 'fail');
          }
        }
      }
    });
  }, []);

  return (
    <Dialog id={modalFilesBulkDownload} width={480}>
      <View style={styles.root}>
        <View style={styles.bar}>
          <Text style={styles.title}>
            {t`Bulk Download`}
          </Text>
          <Button style={styles.close} onPress={close} title={t`Cancel bulk download`}>
            <Svg viewBox="0 0 14 14" width={14} height={14}>
              <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.mid}/>
            </Svg>
          </Button>
        </View>
        <View style={styles.content}>
          {!isLargeDownload && !isUserCharged &&
            <View style={styles.messageWrapper}>
              {!isDownloadReady &&
                <Text style={styles.message}>
                  Calculating total size…
                </Text>
              }
              {isDownloadReady && <Fragment>
                <Text style={styles.message}>
                  {`Are you sure you want to zip `}
                  <Text style={styles.messageBold}>
                    {`${totalItems} ${totalItems === 1 ? 'item' : 'items'}`}
                  </Text>
                  <Text>?</Text>
                </Text>
                <Text style={styles.message}>
                  {'Total size: '}
                  {totalSize > 0 &&
                    <Text style={styles.messageBold}>
                      {bytesize(totalSize)}
                    </Text>
                  }
                </Text>
              </Fragment>}
              <Text style={[styles.message, styles.marginTop]}>
                {t`Depending on the size of the folder it may take several minutes to prepare the bulk download.`}
              </Text>
            </View>
          }
          {isLargeDownload && !isUserCharged &&
            <View style={styles.messageWrapper}>
              <Text style={styles.message}>
                {t`The total size of these files exceeds 2GB. Would you like to continue with the bulk download?`}
              </Text>
            </View>
          }
          {isUserCharged && !isLargeDownload &&
            <View style={styles.messageWrapper}>
              <Text style={styles.message}>
                {t`The owner does not have sufficient bandwidth, would you like to use your own bandwidth to continue with the bulk download?`}
              </Text>
            </View>
          }
        </View>
        <View style={styles.buttons}>
          {!isLargeDownload && !isUserCharged &&
            <IconButton
              mode="primary"
              disabled={!isDownloadReady}
              title={t`Start bulk download`}
              text={t`DOWNLOAD`}
              onPress={submit}
            />
          }
          {(isLargeDownload || isUserCharged) &&
            <IconButton
              mode="primary"
              title={t`Confirm download`}
              text={t`CONFIRM`}
              onPress={submit}
            />
          }
          <View style={styles.spacer}/>
          <IconButton
            mode="cancel"
            title={t`Cancel bulk download`}
            text={t`CLOSE`}
            onPress={close}
          />
        </View>
      </View>
    </Dialog>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    borderWidth: 1,
    borderColor: Light.dialogBorder,
    backgroundColor: Light.dialogBackground,
  }),
  content: Styles.createViewStyle({
    flex: 0,
    padding: 20,
    paddingTop: 8,
  }),
  message: Styles.createTextStyle({
    flex: 1,
    fontSize: 14,
    lineHeight: 24,
    color: Light.dialogText,
  }),
  messageBold: Styles.createTextStyle({
    fontWeight: '600',
  }),
  marginTop: Styles.createTextStyle({
    marginTop: 16,
  }),
  messageWrapper: Styles.createViewStyle({
    flex: 1,
  }),
  buttons: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row-reverse',
    padding: 20,
  }),
  spacer: Styles.createViewStyle({
    width: 6,
  }),
  bar: Styles.createViewStyle({
    flex: 0,
  }),
  title: Styles.createTextStyle({
    marginLeft: 24,
    lineHeight: 65,
    fontSize: 20,
    fontWeight: '300',
    color: Light.dialogTitle,
  }),
  close: Styles.createButtonStyle({
    position: 'absolute',
    alignItems: 'center',
    height: 65,
    width: 58,
    right: 1,
    top: -2,
  }),
};

import React from 'react';
import {Styles, Button, Types, Component, CommonProps} from 'react-ult';

export interface HoverButtonProps extends CommonProps {
  title?: string;
  disabled?: boolean;
  onPress?: (e: Types.SyntheticEvent) => void;
  onRenderChild: (isHovering: boolean) => JSX.Element | null;
}

export interface HoverButtonState {
  isHovering: boolean;
}

export default class HoverButton extends Component<HoverButtonProps, HoverButtonState> {
  render(): JSX.Element | null {
    return (
      <Button
        style={_styles.button}
        title={this.props.title}
        disabled={this.props.disabled}
        onHoverStart={this._onHoverStart}
        onHoverEnd={this._onHoverEnd}
        onPress={this._onPress}>
        {this.props.onRenderChild(this.state ? this.state.isHovering : false)}
      </Button>
    );
  }

  private _onPress = (e: Types.SyntheticEvent) => {
    if (this.props.onPress) {
      this.props.onPress(e);
    }
  };

  private _onHoverStart = () => {
    if (!this.props.disabled) {
      this.setState({isHovering: true});
    }
  };

  private _onHoverEnd = () => {
    if (!this.props.disabled) {
      this.setState({isHovering: false});
    }
  };
}

const _styles = {
  button: Styles.createButtonStyle({
    padding: 1,
  }),
};

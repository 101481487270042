import React from 'react';
import {ComponentBase} from 'resub';
import {Styles, Animated, View, Input, Types, CommonProps, Modal} from 'react-ult';
import {Light} from 'features/themes';
import {Keys} from 'globals/keys';

interface DialogProps extends CommonProps {
  id: string;
  width?: number;
  height?: number;
  children?: Types.ReactNode;
}

interface DialogState {
  widthStyle?: Types.ViewStyleRuleSet;
  heightStyle?: Types.ViewStyleRuleSet;
}

export class Dialog extends ComponentBase<DialogProps, DialogState> {
  private static _visibleDialogs: {[id: string]: Dialog} = {};

  private _contentOpacity = new Animated.Value(1);
  private _contentScale = new Animated.Value(_initialScalingRatio);
  private _contentScaleStyle = Styles.createAnimatedViewStyle({
    opacity: this._contentOpacity,
    transform: [{
      scale: this._contentScale,
    }],
  });

  private _opacityAnimation = new Animated.Value(1);
  private _opacityAnimationStyle = Styles.createAnimatedViewStyle({
    opacity: this._opacityAnimation,
  });

  protected _buildState(props: DialogProps, _initialBuild: boolean): Partial<DialogState> {
    const newState: Partial<DialogState> = {};
    newState.widthStyle = props.width ? Styles.createViewStyle({width: props.width}, false) : undefined;
    newState.heightStyle = props.height ? Styles.createViewStyle({height: props.height}, false) : undefined;
    return newState;
  }

  componentDidMount() {
    super.componentDidMount();
    Input.keyUpEvent.subscribe(this._onKeyUp);
    Dialog._visibleDialogs[this.props.id] = this;
    Animated.timing(this._contentScale, {
      toValue: 1,
      duration: _scalingAnimationDuration,
      easing: Animated.Easing.OutBack(),
      useNativeDriver: true,
    }).start();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    delete Dialog._visibleDialogs[this.props.id];
    Input.keyUpEvent.unsubscribe(this._onKeyUp);
  }

  render() {
    const modalBoxStyles = [_styles.modalBox, this.state.widthStyle];
    const modalContentStyles = [_styles.modalContainer, this._contentScaleStyle, this.state.heightStyle];
    const modalContent = (
      <Animated.View style={modalContentStyles}>
        <View
          restrictFocusWithin
          disableTouchOpacityAnimation
          style={modalBoxStyles}
          accessibilityTraits={Types.AccessibilityTrait.Dialog}
          onPress={this._clickInside}
          tabIndex={-1}>
          {this.props.children}
        </View>
      </Animated.View>
    );

    return (
      <Animated.View
        disableTouchOpacityAnimation
        style={[_styles.modalBackground, this._opacityAnimationStyle]}
        onPress={this._clickOutside}
        onLongPress={this._onLongPressOutside}>
        {modalContent}
      </Animated.View>
    );
  }

  private _onKeyUp = (e: Types.KeyboardEvent) => {
    if (e.keyCode === Keys.Escape) {
      this._clickOutside(e);
      return true;
    }
    return false;
  };

  // Do nothing, keeps click/press from propogating up to the dismissal action
  private _clickInside = (e: Types.SyntheticEvent) => {
    e.stopPropagation();
  };

  // Do nothing, required to keep onPress from firing on long press
  private _onLongPressOutside = (e: Types.SyntheticEvent) => {
    e.stopPropagation();
  };

  private _clickOutside = (e: Types.SyntheticEvent) => {
    e.stopPropagation();
    Dialog.dismissAnimated(this.props.id);
  };

  private _animateClose(onAnimationComplete: () => void) {
    Animated.parallel([
      Animated.timing(this._opacityAnimation, {
        toValue: 0,
        duration: _opacityAnimationDuration,
        easing: Animated.Easing.Out(),
        useNativeDriver: true,
      }),
      Animated.timing(this._contentOpacity, {
        toValue: 0,
        duration: _opacityAnimationDuration,
        easing: Animated.Easing.Out(),
        useNativeDriver: true,
      }),
      Animated.timing(this._contentScale, {
        toValue: _initialScalingRatio,
        duration: _scalingAnimationDuration,
        easing: Animated.Easing.Out(),
        useNativeDriver: true,
      }),
    ]).start(() => {
      onAnimationComplete();
    });
  }

  static dismissAnimated(id: string): Promise<void> {
    const dialog = Dialog._visibleDialogs[id];
    if (!dialog) return Promise.reject('Dialog ID not found');
    return new Promise<void>(resolve => {
      dialog._animateClose(() => {
        Modal.dismiss(id);
        resolve(void 0);
      });
    });
  }
}

const _opacityAnimationDuration = 150;
const _scalingAnimationDuration = 250;
const _initialScalingRatio = 0.95;
const _styles = {
  modalBackground: Styles.createViewStyle({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Light.dialogBehind,
  }),
  modalContainer: Styles.createViewStyle({
    flex: -1,
    flexDirection: 'row',
  }),
  modalBox: Styles.createViewStyle({
    flex: -1,
    margin: 32,
  }),
};

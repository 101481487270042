import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';
import {alert, purge} from 'features/mediafire';

const _modalPurge = 'purge';

export function useModalPurge(ids: string[], dispatch: Dispatch) {
  const submit = useCallback(async () => {
    const response = await purge(dispatch, ids);
    if (response[0] || response[1]) {
      alert(dispatch, t`Successfully deleted items from trash`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to purge items`;
      alert(dispatch, message, 'fail');
    }
    Modal.dismiss(_modalPurge);
  }, [ids]);

  const show = useCallback(() => {
    Modal.show(
      <Prompt
        id={_modalPurge}
        title={t`Delete items`}
        text={t`This will permanently delete the items you have selected. They will no longer be able to be restored, and this cannot be undone. Would you like to continue?`}
        buttons={[{
          text: t`YES, DELETE`,
          mode: 'destructive',
          onPress: () => {
            Prompt.dismissAnimated(_modalPurge);
            submit();
          },
        }, {
          text: t`CANCEL`,
          mode: 'cancel',
          onPress: () => {
            Prompt.dismissAnimated(_modalPurge);
          },
        }]}
      />
    , _modalPurge);
  }, [ids]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalPurge);
  }, [ids]);

  return [show, close];
}

import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';
import {alert, createFolder, loadFolder} from 'features/mediafire';
import {selectors} from 'store/files';

const _modalCreateFolder = 'create-folder';

export function useModalCreateFolder(id: string) {
  const dispatch = useDispatch();

  const sort = useSelector(selectors.getSort);
  const filter = useSelector(selectors.getFilter);

  const submit = useCallback(async (name: string) => {
    const response = await createFolder(id, name);
    if (response && response['name']) {
      loadFolder(dispatch, id, false, true, undefined, sort, filter);
      alert(dispatch, t`Created the folder “${response['name']}”`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to create folder`;
      alert(dispatch, message, 'fail');
    }
    Modal.dismiss(_modalCreateFolder);
  }, [id, sort, filter]);

  const show = useCallback(() => {
    Modal.show(
      <Prompt
        id={_modalCreateFolder}
        title={t`Create Folder`}
        text={t`Give this folder a name`}
        options={{
          type: 'plain-text',
          placeholder: t`Folder name`,
        }}
        buttons={[{
          text: t`OK`,
          mode: 'primary',
          onPress: (value?: string) => {
            Prompt.dismissAnimated(_modalCreateFolder);
            if (value) submit(value);
          },
        }, {
          text: t`CANCEL`,
          mode: 'cancel',
          onPress: () => {
            Prompt.dismissAnimated(_modalCreateFolder);
          },
        }]}
      />
    , _modalCreateFolder);
  }, [id]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalCreateFolder);
  }, [id]);

  return [show, close];
}

import {Light} from 'features/themes';
import {getContrast} from 'features/common';
import * as MF from 'globals/types';

export function activate(state: MF.AppStore, action: MF.AppActivate) {
  const {active} = action.payload;
  state.active = active;
}

export function feature(state: MF.AppStore, action: MF.AppFeature) {
  const {feature} = action.payload;
  state.feature = feature;
}

export function menu(state: MF.AppStore, action: MF.AppMenu) {
  const {open} = action.payload;
  state.menuOpen = open;
}

export function resize(state: MF.AppStore, action: MF.AppResize) {
  const {width, height} = action.payload;
  state.viewport = {width, height};
}

export function login(state: MF.AppStore, action: MF.AppLogin) {
  let {header} = action.payload;
  // Limit too light of a primary color
  if (header.custom && getContrast(header.colors.primary) === "black")
    header.colors.primary = Light.logoColor;
  // Update user
  state.user = action.payload;
  // Empty trial account logged in, show menu by default
  if (state.user.group === MF.AppUserGroup.Trial
    && state.user.limit.storage.used === 0)
    state.menuOpen = true;
}

export function logout(state: MF.AppStore) {
  state.user = null;
}

export function alert(state: MF.AppStore, action: MF.AppAlert) {
  const {text, type} = action.payload;
  state.alerts.push({text, type});
}

export function clearAlerts(state: MF.AppStore) {
  state.alerts = [];
}

export function updateNotifications(state: MF.AppStore, action: MF.AppNotify) {
  state.notifications = action.payload.count;
}

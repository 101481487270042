import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {login, load, loadTrash} from 'features/mediafire';
import {isLocal} from 'features/platform';
import {getSecureCookies} from 'features/filesystem';
import {activate, navigate, reporter} from './middleware';

import app from './app';
import files from './files';

// Create initial state
const state = {app, files};

// Init plugins
const {initActivate} = activate(state);
const {initReporter, pluginReporter} = reporter(state);
const {initNavigate, pluginNavigate, current, history} = navigate(state);

// Create store
const reducer = combineReducers({app: state.app.reducer, files: state.files.reducer});
const middleware = [pluginReporter, pluginNavigate];
const store = configureStore({reducer, middleware, devTools: isLocal()});

// Start app
(async() => {
  // Authenticate
  const {dispatch} = store;
  const cookies = await getSecureCookies();
  const user = await login(dispatch, cookies);

  // Start plugins
  initReporter(store, user);
  initActivate(store);
  initNavigate(store, (id: string) => {
    const state = store.getState();
    load(dispatch, id, false, state.files.sort, state.files.filter);
  });

  // Load current folder
  const state = store.getState();
  load(dispatch, current, true, state.files.sort, state.files.filter);

  // Load additional content
  if (user) {
    loadTrash(dispatch, true);
    if (current !== 'myfiles') {
      load(dispatch, 'myfiles', false, state.files.sort, state.files.filter);
    }
  }
})();

export type Store = ReturnType<typeof reducer>;
export {state, history};
export default store;

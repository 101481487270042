import React, {useCallback} from 'react';
import {Modal} from 'react-ult';
import {Types} from 'react-ult-ext-webview';
import {WebFrame} from 'controls/WebFrame';
import {bytesize} from 'features/common';
import {getHost, isIOS} from 'features/platform';

const _modalId = 'upsell-storage';

type Action = 'upload' | 'manage' | 'remind';

export function useModalUpsellStorage(action: Action) {
  const close = useCallback(() => Modal.dismiss(_modalId), []);
  const message = useCallback((e: Types.WebViewMessageEvent) => {
    if (e && e.data === 'mf-close-dialogs') {
      close();
    }
  }, []);

  const show = useCallback((needed: number = 0, limit: number = 0) => {
    if (isIOS()) return;
    const path = `${getHost()}/widgets/storage_limit_upsell.php`;
    const query = `action=${action}&needed=${bytesize(needed)}&limit=${bytesize(limit)}`;
    Modal.show(
      <WebFrame url={`${path}?${query}`} close={close} onMessage={message}/>
    , _modalId);
  }, [action]);

  return [show, close];
}

import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';
import {alert, describe} from 'features/mediafire';

const _modalDescribe = 'describe';

export function useModalDescribe(id: string, name: string, description: string, isFolder: boolean, dispatch: Dispatch) {
  const group = isFolder ? 'folder' : 'file';

  const submit = useCallback(async (newDescription: string) => {
    const response = await describe(id, newDescription);
    if (response && response['result'] === 'Success') {
      alert(dispatch, t`Updated the description for “${name}”`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to update description`;
      alert(dispatch, message, 'fail');
    }
    Modal.dismiss(_modalDescribe);
  }, [id, name, description]);

  const show = useCallback(() => {
    Modal.show(
      <Prompt
        id={_modalDescribe}
        title={t`Description`}
        text={isFolder ? `${name} ${group}` : name}
        options={{
          type: 'plain-text',
          placeholder: t`Give this item a description`,
          defaultValue: description,
          selectInput: 'all',
        }}
        buttons={[{
          text: t`OK`,
          mode: 'primary',
          onPress: (value?: string) => {
            Prompt.dismissAnimated(_modalDescribe);
            if (value) submit(value);
          },
        }, {
          text: t`CANCEL`,
          mode: 'cancel',
          onPress: () => {
            Prompt.dismissAnimated(_modalDescribe);
          },
        }]}
      />
    , _modalDescribe);
  }, [id, name, description, group]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalDescribe);
  }, [id, name, description]);

  return [show, close];
}

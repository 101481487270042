import {createSelector} from '@reduxjs/toolkit';
import {Store} from 'store';

const x = (store: Store) => store;

export const isSearch = createSelector(x, store => !!store.files.search);
export const isUploaderMinimized = createSelector(x, store => store.files.uploadMinimized);
export const isDownloaderMinimized = createSelector(x, store => store.files.downloadMinimized);

export const getLayout = createSelector(x, store => store.files.layout);
export const getRoot = createSelector(x, store => store.files.root);
export const getBrowser = createSelector(x, store => store.files.browser);
export const getCurrent = createSelector(x, store => store.files.current);
export const getFocused = createSelector(x, store => store.files.focused);
export const getSelection = createSelector(x, store => store.files.selection);
export const getPreview = createSelector(x, store => store.files.preview);
export const getPreviewProgress = createSelector(x, store => store.files.previewProgress);
export const getSearch = createSelector(x, store => store.files.search);
export const getFilter = createSelector(x, store => store.files.filter);
export const getDrag = createSelector(x, store => store.files.drag);
export const getPick = createSelector(x, store => store.files.pick);
export const getSort = createSelector(x, store => store.files.sort);
export const getQuery = createSelector(x, store => store.files.query);
export const getItems = createSelector(x, store => store.files.items);
export const getItem = (path: string) => createSelector(x, store => store.files.items[path]);
export const getView = (path: string) => createSelector(x, store => store.files.views[path]);
export const getParent = (list: 'current' | 'browser') => createSelector(x, store => {
  const parentId = store.files[list];
  const parent = parentId && store.files.items[parentId];
  const hierarchy = parent && parent.hierarchy;
  return hierarchy && hierarchy.length
    ? hierarchy.slice(-1)[0]
    : ['myfiles', 'My Files'];
});


export const hasPrivateItems = (ids: string[]) => createSelector(x, store => {
  for (let i = 0; i < ids.length; i++) {
    const item = store.files.items[ids[i]];
    if (item && item.private) {
      return true;
    }
  }
  return false;
});

export const getUpload = createSelector(x, store => store.files.upload);
export const getUploadView = createSelector(x, store => store.files.uploadView);
export const getUploadItems = createSelector(x, store => store.files.uploadItems);
export const getUploadFiles = createSelector(x, store => store.files.uploadFiles);
export const getUploadWeb = createSelector(x, store => store.files.uploadWeb);
export const getDownloadBulk = createSelector(x, store => store.files.downloadBulk);
export const getDownloadView = createSelector(x, store => store.files.downloadView);
export const getDownloadItems = createSelector(x, store => store.files.downloadItems);

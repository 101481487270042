import {DownloadProgress, DownloadResult} from '../types';

export const downloadDirectory = '';

export function downloadFile(
  _fromUrl: string,
  _toFile: string,
  _progress: (e: DownloadProgress) => void,
): DownloadResult {
  return null;
}

export async function cancelDownload(_id: number) {
  return null;
}

import React, {useCallback} from 'react';
import {Styles, Animated, TextInput} from 'react-ult';
import {Light} from 'features/themes';

export interface Props {
  onChangeText: (value: string) => void,
  onSubmitEditing: () => void,
  placeholder?: string,
  placeholderTextColor?: string,
  defaultValue?: string,
  autoFocus?: boolean,
  secureTextEntry?: boolean,
  keyboardType?: 'default' | 'email-address' | 'numeric' | 'number-pad';
  selectMode?: 'none' | 'all' | 'filename',
}

export function InputField(props: Props) {
  const onChange = useCallback((value: string) => {
    props.onChangeText(value);
  }, [props.onChangeText]);

  const onSubmit = useCallback(() => {
    props.onSubmitEditing();
  }, [props.onSubmitEditing]);

  const onMount = useCallback((e: TextInput) => {
    const mode = props?.selectMode;
    const value = props?.defaultValue;
    if (!value || !mode || mode === 'none') return;
    try {
      const last = mode === 'filename' && value.indexOf('.') !== -1
        ? value.split('.').slice(0, -1).join('.').length
        : value.length;
      e && e.selectRange(0, last);
    } catch (e) {}
  }, []);

  const interpolatedValue = Animated.interpolate(
    Animated.createValue(0.0),
    [0.0, 1.0],
    [Light.dialogBorder, Light.logoColor]
  );

  const animatedColorStyle = Styles.createAnimatedViewStyle({
    backgroundColor: interpolatedValue,
  });

  const onBlur = useCallback(() => {
    Animated.timing(interpolatedValue, {
      toValue: 0,
      duration: 200,
      easing: Animated.Easing.OutBack(),
      useNativeDriver: true,
    }).start();
  }, [interpolatedValue]);

  const onFocus = useCallback(() => {
    Animated.timing(interpolatedValue, {
      toValue: 1,
      duration: 200,
      easing: Animated.Easing.InOut(),
      useNativeDriver: true,
    }).start();
  }, [interpolatedValue]);

  return (
    <Animated.View style={[styles.field, animatedColorStyle]}>
      <TextInput
        maxLength={200}
        autoCorrect={false}
        style={styles.input}
        ref={onMount}
        onBlur={onBlur}
        onFocus={onFocus}
        onChangeText={onChange}
        onSubmitEditing={onSubmit}
        autoFocus={props?.autoFocus}
        defaultValue={props?.defaultValue}
        keyboardType={props?.keyboardType || 'default'}
        secureTextEntry={props?.secureTextEntry}
        placeholder={props?.placeholder}
        placeholderTextColor={props?.placeholderTextColor
          ?? Light.dialogInputPlaceholder}
      />
    </Animated.View>
  );
}

export const styles = {
  field: Styles.createViewStyle({
    paddingBottom: 2,
  }),
  input: Styles.createTextInputStyle({
    flex: 1,
    fontSize: 16,
    lineHeight: 24,
    paddingVertical: 8,
    paddingHorizontal: 10,
    color: Light.dialogInputText,
    backgroundColor: Light.dialogInputBackground,
  }),
};

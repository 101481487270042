import React, {useState, useCallback, useMemo} from 'react';
import isEqual from 'react-fast-compare';
import {Dispatch} from '@reduxjs/toolkit';
import {Styles, View} from 'react-ult';
import {
  VirtualListView,
  VirtualListViewCellRenderDetails,
  VirtualListViewItemInfo,
} from 'react-ult-ext-virtuallistview';
import {
  FilesGridRow,
  FilesGridRowProps,
  FilesGridItemNormal,
} from 'view/FilesGridItem';
import {isTouch} from 'features/platform';
import * as effects from 'store/files/effects';
import {Light, Breakpoints} from 'features/themes';
import * as MF from 'globals/types';

export interface FilesGridProps {
  dispatch: Dispatch;
  id: string;
  name: string;
  sort: MF.FilesStore['sort'];
  filter: MF.FilesStore['filter'];
  items: {[id: string]: MF.FilesItem};
  view: {files: string[], folders: string[]};
  viewport: {width: number, height: number};
  dragging: string[];
  selection: string[];
  isPremium: boolean;
  isSearch: boolean;
  focusId?: string;
  primaryColor?: string;
  maxheight?: number;
  maxwidth?: number;
  hasFolderTree?: boolean;
  hasPrivateSelection?: boolean;
  foreign?: boolean;
}

export const FilesGrid = React.memo(function FilesGrid(props: FilesGridProps) {
  const {id, name, sort, filter, viewport, view, items, selection, dragging, focusId, primaryColor, isPremium} = props;
  const [stateDrop, setDrop] = useState(0);
  const hasTouch = isTouch();
  const isOwned = !props.foreign;
  const isTrash = props.id === 'trash';
  const isSearch = props.id === 'search';
  const isLargeRow = hasTouch || !isOwned;
  const heightHeader = isOwned ? isLargeRow ? 142 : 120 : 160;
  const maxHeight = props.maxheight || viewport.height - heightHeader;
  const maxWidth = props.maxwidth
    ? Math.min(viewport.width, props.maxwidth)
    : viewport.width - (viewport.width > Breakpoints.menu ? props.hasFolderTree ? 420 : 220 : 0);
  const columns = Math.floor(maxWidth / FilesGridItemNormal);
  const styleHeight = Styles.createScrollViewStyle({maxHeight, maxWidth, overflow: 'visible'}, false);
  const dispatch = props.dispatch;
  const drop = useCallback(effects.dragDrop(dispatch, setDrop, id, name), [setDrop, id, name]);
  const dragEnter = useCallback(effects.dragEnter(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragLeave = useCallback(effects.dragLeave(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragOver = useCallback(effects.dragOver(), []);
  const focus = useCallback(effects.listFocus(dispatch), []);

  const render = useCallback((e: VirtualListViewCellRenderDetails<FilesGridRowProps>) =>
    <FilesGridRow {...e.item}/>, []);

  const prepareRows = (list: string[]) => {
    const grid = [];
    list.forEach((key, i) => {
      const row = Math.floor(i / columns);
      const column = i % columns;
      const item = {
        isPremium,
        id: key && items[key] ? items[key].id : null,
        url: key && items[key] ? items[key].url : '',
        name: key && items[key] ? items[key].name : '',
        description: key && items[key] ? items[key].description : '',
        hash: key && items[key] ? items[key].hash : '',
        type: key && items[key] ? items[key].type : '',
        flag: key && items[key] ? items[key].flag : 0,
        size: key && items[key] ? items[key].size : 0,
        time: key && items[key] && (items[key].deleted || items[key].created),
        shared: key && items[key] && items[key].shared,
        parentKey: isSearch ? key && items[key] && items[key].parentKey : '',
        parentName: isSearch ? key && items[key] && items[key].parentName : '',
        files: key && items[key] ? items[key].files : 0,
        folders: key && items[key] ? items[key].folders : 0,
        downloads: key && items[key] ? items[key].downloads : 0,
        password: key && items[key] ? items[key].password : false,
        private: key && items[key] ? items[key].private : false,
        filedrop: key && items[key] ? items[key].filedrop : false,
        uploadStatus: key && items[key] ? items[key].uploadStatus : '',
        uploadHashed: key && items[key] ? items[key].uploadHashed : 0,
        uploadProgress: key && items[key] ? items[key].uploadProgress : 0,
      };
      if (!grid[row]) {
        grid[row] = {
          // Virtual list item props
          key: row,
          isNavigable: true,
          height: FilesGridItemNormal,
          // File list item props
          context: [],
          opts: {
            dispatch,
            dragging,
            selection,
            focusId,
            sort,
            filter,
            primaryColor,
            isTrash,
            hasPrivateSelection: props.hasPrivateSelection,
          },
        }
        for (let i = 0; i < columns; i++) {
          grid[row].context[i] = null;
        }
      }
      grid[row].context[column] = item;
    });
    return grid;
  };

  const itemList = useMemo(() =>
    view ? prepareRows([
      ...Array.from(new Set(view.folders)),
      ...Array.from(new Set(view.files)),
    ]) : []
  , [view, items, selection, dragging, focusId]);

  // Reset scroll position when changing folders
  const mount = useCallback((e?: VirtualListView<VirtualListViewItemInfo>) => {
    e && e.scrollToTop(false, 0);
  }, [id]);

  return view ? (
    <View
      style={styles.root}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={drop}>
      <VirtualListView
        skipRenderIfItemUnchanged
        animateChanges
        ref={mount}
        style={styleHeight}
        itemList={itemList}
        renderItem={render}
        keyboardFocusScrollOffset={(maxHeight / 2) - 41}
        scrollsToTop={true}
        scrollIndicatorInsets={{top: 20, left: 0, right: 0, bottom: 0}}
        onItemFocused={focus}
        onItemSelected={undefined}
      />
    </View>
  ) : null;
}, (prev, next) => isEqual(prev, next));

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    backgroundColor: Light.filesBackground,
  }),
};

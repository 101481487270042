import {t} from '@lingui/macro';
import React, {useCallback, Component} from 'react';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useMenu} from 'hooks/useMenu';
import {Vectors, Colors} from 'features/themes';
import {FilesStore} from 'globals/types';

type Sort = FilesStore['sort'];
type Filter = FilesStore['filter'];

export function useMenuSort(
  id: string,
  sort: Sort,
  filter: Filter,
  onSort: (sort: Sort, filter: Filter) => void,
  anchor: Component,
) {
  const selectSort = useCallback((category: Sort['category']) => {
    const order = sort.order === 'asc' ? 'desc' : 'asc';
    onSort({category, order}, filter);
  }, [id, sort, onSort]);

  const icon = useCallback((isActive: boolean) => 
    <Svg viewBox="0 0 24 24" width={22} height={22}>
      <SvgPath
        d={sort.order === 'asc' ? Vectors.iconArrowUp : Vectors.iconArrowDown}
        fillColor={isActive
          ? Colors.neutral.mid // TODO: dark = colors.neutral.light
          : 'transparent'}
      />
    </Svg>
  , [sort]);

  const [show, close] = useMenu([
    {
      command: 'header',
      text: t`Sort`,
    },
    {
      command: 'name',
      icon: icon(sort.category === 'name'),
      text: t`Sort by Name`,
    },
    {
      command: 'date',
      icon: icon(sort.category === 'date'),
      text: t`Sort by Date`,
    },
    {
      command: 'size',
      icon: icon(sort.category === 'size'),
      text: t`Sort by Size`,
    },
    {
      command: 'downloads',
      icon: icon(sort.category === 'downloads'),
      text: t`Sort by Downloads`,
    },
  ], (command: Sort['category']) => {
    selectSort(command);
    close();
  }, anchor, undefined, ['bottom', 'top'], true, false, false);

  return [show, close];
}

import React, {useCallback} from 'react';
import {Modal} from 'react-ult';
import {Types} from 'react-ult-ext-webview';
import {WebFrame} from 'controls/WebFrame';
import {getHost} from 'features/platform';

const _modalId = 'custom-alert';

export function useModalCustomAlert() {
  const close = useCallback(() => Modal.dismiss(_modalId), []);
  const message = useCallback((e: Types.WebViewMessageEvent) => {
    if (e && e.data === 'mf-close-dialogs') {
      close();
    }
  }, []);

  const show = useCallback((alertId: number) => {
    Modal.show(
      <WebFrame
        url={`${getHost()}/templates/custom_alert.php?id=${alertId}`}
        onMessage={message}
        close={close}
      />
    , _modalId)
  }, []);

  return [show, close];
}

import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Styles, View, Button, Text} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {Light, Vectors, Colors} from 'features/themes';
import {selectors} from 'store/files';

import * as filesEffects from 'store/files/effects';

export const filesPeekHeaderHeight = 42;
export const filesPeekHeaderHeightPicker = 52;

export interface Props {
  id: string;
  picker?: boolean;
}

export function FilesNavigator(props: Props) {
  const folder = useSelector(selectors.getItem(props.id));
  const filter = useSelector(selectors.getFilter);
  const sort = useSelector(selectors.getSort);
  const pick = useSelector(selectors.getPick);
  const hierarchy = folder && folder.hierarchy.length > 0 && folder.hierarchy[folder.hierarchy.length - 2];
  const parent = hierarchy && hierarchy[0] !== props.id ? hierarchy[0] : 'myfiles';
  const depth = folder ? folder.hierarchy && folder.hierarchy.length : 0;
  const isRoot = !folder || folder.name === 'myfiles';
  const title = folder && !isRoot ? folder.name : props.picker ? t`My Files` : t`My Folders`;
  const classes = {
    root: [
      styles.root,
      props.picker && styles.picker,
    ],
    title: [
      styles.title,
      props.picker && styles.titlePicker,
      isRoot && styles.titleRoot,
      isRoot && props.picker && styles.titleRootPicker,
    ],
  };

  const dispatch = useDispatch();
  const self = useCallback(filesEffects.navigate(dispatch, props.id), [props.id]);
  const close = useCallback(filesEffects.pick(dispatch, null), []);
  const goBack = useCallback(filesEffects.browse(dispatch, parent, sort, filter), [parent, sort, filter]);
  const goBackPick = useCallback(filesEffects.pick(dispatch, {...pick, destination: parent}), [pick]);

  return (
    <View style={classes.root}>
      {depth > 0 &&
        <Button
          style={styles.back}
          title={t`Go back to parent`}
          onPress={props.picker ? goBackPick : goBack}>
          <Svg viewBox="0 0 24 24" width={props.picker ? 24 : 18} height={props.picker ? 24 : 18}>
            <SvgPath d={Vectors.iconBack} fillColor={Colors.neutral.mid} fillOpacity={0.75}/>
          </Svg>
        </Button>
      }
      {!props.picker &&
        <Button
          style={styles.button}
          title={t`Go to ${title}`}
          onPress={props.picker ? undefined : self}>
          {depth === 0 &&
            <Svg style={styles.folderIcon} viewBox={'0 0 24 24'} width={20} height={20}>
              <SvgPath d={Vectors.iconFolder} fillColor={Colors.neutral.mid} fillOpacity={0.5}/>
            </Svg>
          }
          <Text style={classes.title}>
            {title}
          </Text>
        </Button>
      }
      {props.picker &&
        <View style={styles.button}>
          <Text style={classes.title}>
            {title}
          </Text>
          <Button style={styles.close} onPress={close} title={t`Close folder picker`}>
            <Svg viewBox="0 0 14 14" width={14} height={14}>
              <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.mid}/>
            </Svg>
          </Button>
        </View>
      }
    </View>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Light.navigatorBorder,
    backgroundColor: Light.navigatorBackground,
    height: filesPeekHeaderHeight,
  }),
  picker: Styles.createViewStyle({
    height: filesPeekHeaderHeightPicker,
  }),
  back: Styles.createButtonStyle({
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    margin: 2,
  }),
  button: Styles.createButtonStyle({
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  }),
  folderIcon: Styles.createViewStyle({
    marginTop: 6,
    marginLeft: 10,
  }),
  title: Styles.createTextStyle({
    flex: 1,
    fontSize: 12,
    color: Light.navigatorText,
  }),
  titlePicker: Styles.createTextStyle({
    fontSize: 14,
  }),
  titleRoot: Styles.createTextStyle({
    marginLeft: 6,
  }),
  titleRootPicker: Styles.createTextStyle({
    marginLeft: 16,
  }),
  close: Styles.createButtonStyle({
    position: 'absolute',
    alignItems: 'center',
    margin: 10,
    marginRight: 4,
    height: 46,
    width: 46,
    right: 0,
  }),
};

import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';
import {alert, password} from 'features/mediafire';

const _modalPasswordProtect = 'password-protect';

export function useModalPasswordProtect(id: string, name: string, isFolder: boolean, dispatch: Dispatch) {
  const group = isFolder ? 'folder' : 'file';

  const submit = useCallback(async (text: string) => {
    const response = await password(id, text);
    if (response && response['result'] === 'Success') {
      alert(dispatch, t`Updated password to “${name}”`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to update password to “${name}”`;
      alert(dispatch, message, 'fail');
    }
    Modal.dismiss(_modalPasswordProtect);
  }, [id]);

  const clear = useCallback(async () => {
    const response = await password(id, '');
    if (response && response['result'] === 'Success') {
      alert(dispatch, t`Removed password to “${name}”`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : t`Failed to remove password to “${name}”`;
      alert(dispatch, message, 'fail');
    }
    Modal.dismiss(_modalPasswordProtect);
  }, [id]);

  const show = useCallback((e?: any) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    Modal.show(
      <Prompt
        id={_modalPasswordProtect}
        title={t`Password Protect`}
        text={isFolder ? `${name} ${group}` : name}
        options={{
          type: 'secure-text',
          placeholder: t`Password`,
        }}
        buttons={[{
          text: t`SAVE`,
          mode: 'primary',
          onPress: (value?: string) => {
            Prompt.dismissAnimated(_modalPasswordProtect);
            if (value) submit(value);
          },
        }, {
          text: t`REMOVE`,
          mode: 'destructive',
          onPress: () => {
            Prompt.dismissAnimated(_modalPasswordProtect);
            clear();
          },
        }, {
          text: t`CANCEL`,
          mode: 'cancel',
          onPress: () => {
            Prompt.dismissAnimated(_modalPasswordProtect);
          },
        }]}
      />
    , _modalPasswordProtect);
  }, [id]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalPasswordProtect);
  }, [id]);

  return [show, close];
}

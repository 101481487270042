import {t} from '@lingui/macro';
import React, {useRef, useState, useCallback} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Modal, Styles} from 'react-ult';
import {useMenuFiles} from 'hooks/useMenuFiles';
import {useModalFlags} from 'hooks/useModalFlags';
import {useModalShare} from 'hooks/useModalShare';
import {useModalUpsellFeature} from 'hooks/useModalUpsellFeature';
import {useModalPasswordProtect} from 'hooks/useModalPasswordProtect';
import {FilesModalFileDrop, modalFilesFileDrop} from 'view/modals/FilesModalFileDrop';
import {getTimeStamp, getDetails, getPhoto} from 'features/mediafire';
import {testBit, bytesize} from 'features/common';
import device from 'features/device';
import {Colors} from 'features/themes';
import * as effects from 'store/files/effects';
import * as appEffects from 'store/app/effects';
import * as MF from 'globals/types';

export interface FileItem {
  id: string,
  url: string,
  name: string,
  type: string,
  hash: string,
  time: string,
  shared: string,
  flag: number,
  size?: number,
  files?: number,
  folders?: number,
  downloads?: number,
  description?: string,
  uploadStatus?: string,
  uploadHashed?: number,
  uploadProgress?: number,
  parentKey?: string,
  parentName?: string,
  filedrop?: boolean,
  private?: boolean,
  password?: boolean,
  isPremium?: boolean,
};

export interface FileListOptions {
  focusId: string,
  dragging?: string[],
  selection?: string[],
  sort: MF.FilesStore['sort'],
  filter: MF.FilesStore['filter'],
  hasPrivateSelection: boolean,
  primaryColor?: string,
  isUploader: boolean,
  isPicker?: boolean,
  isTree?: boolean,
  isTrash: boolean,
  isMinimal?: boolean,
  isUploadFull?: boolean,
  dispatch: Dispatch,
}

export function useFileItem(item: FileItem, opts: FileListOptions) {
  // Local state
  const dispatch = opts.dispatch;
  const anchor = useRef(null);
  const [stateDrop, setDrop] = useState(0);
  const [stateTitle, setTitle] = useState(false);
  const [stateHover, setHover] = useState(false);
  const [stateMenuPosX, setMenuPosX] = useState(0);
  const [stateMenuPosY, setMenuPosY] = useState(0);

  // Derived state
  const isOwned = testBit(item.flag, MF.FilesItemFlag.isOwned);
  const isInfected = testBit(item.flag, MF.FilesItemFlag.isInfected);
  const isReported = testBit(item.flag, MF.FilesItemFlag.isReported);
  const isCopyright = testBit(item.flag, MF.FilesItemFlag.isCopyright);
  const isRestricted = testBit(item.flag, MF.FilesItemFlag.isRestricted);
  const isWordfilter = testBit(item.flag, MF.FilesItemFlag.isWordFiltered);
  const isFlagged = isReported || isCopyright || isRestricted || isWordfilter || isInfected;
  const isImage = item.type === 'image';
  const isFolder = item.type === 'folder';
  const isShared = item.shared;
  const isFocused = item.id === opts.focusId;
  const isSelected = opts.selection && opts.selection.includes(item.id);
  const isDragging = opts.dragging && opts.dragging.includes(item.id);
  const isDropping = stateDrop > 0 && !isDragging;
  const isNavigable = isFolder && !item.shared;
  const isExpandable = opts.isTree && item.folders > 0;
  const hasSelection = opts.selection && opts.selection.length > 0;
  const hasUploadError = item.uploadStatus
    && isNaN(parseInt(item.uploadStatus))
    && item.uploadStatus !== 'Queued'
    && item.uploadStatus !== 'Preparing…'
    && item.uploadStatus !== 'Verifying…'
    && item.uploadStatus !== 'Completed'
    && item.uploadStatus !== 'Conflict';

  let textFlagged = 'This file has been restricted';
  if (isCopyright) textFlagged += ' due to copyright claim';
  else if (isReported) textFlagged += ' due to DMCA takedown';
  else if (isInfected) textFlagged += ' due to a virus detected';

  const textTimestamp = getTimeStamp(item.time);
  const textPhotoURI = getPhoto(item.id, item.hash, item.flag, item.private);
  const textDetails = opts.isUploader ? bytesize(item.size) : getDetails(item.type, item);
  const textOpen = `${isFolder ? t`Go to` : isImage ? t`View` : t`Download`} ${item.name}`;
  
  const percentHashed = (item.uploadHashed / item.size) * 100;
  const percentUploaded = (item.uploadProgress / item.size) * 100;
  
  const colorSecondary = isSelected ? Colors.neutral.mid : Colors.neutral.light;
  const colorPrimary = isSelected
    ? device.isDarkMode()
      ? Colors.neutral.white
      : Colors.neutral.dark
    : opts.primaryColor;

  const styleColorPrimary = Styles.createTextStyle({color: colorPrimary}, false);

  const contextOptions = {
    primaryColor: opts.primaryColor,
    isMinimal: opts.isMinimal,
    isPremium: item.isPremium,
    hasPrivate: hasSelection && isSelected
      ? opts.hasPrivateSelection
      : item.private,
  };

  const contextItems = hasSelection && isSelected
    ? opts.selection
    : [item.id];

  const contextItem = {
    id: item.id,
    url: item.url,
    flag: item.flag,
    type: item.type,
    name: item.name,
    description: item.description,
    filedrop: item.filedrop,
    private: item.private,
    deleted: opts.isTrash ? '-' : '',
    revision: 0,
    state: 0,
    created: '',
    hierarchy: [],
  };

  // Event handlers
  const copyLink = useCallback(effects.copyLink2(dispatch, [item.id], item.url), [item.id, item.url]);
  const onSelect = useCallback(effects.select(dispatch, item.id), [item.id]);
  const onDownload = useCallback(effects.downloadFile(item.url), [item.url]);
  const onNavigate = useCallback(effects.navigate(dispatch, item.id), [item.id]);
  const onPreview = useCallback(effects.previewFile(dispatch, item.id, item.name, item.url), [item.id]);
  const onBrowse = useCallback(effects.browse(dispatch, item.id, opts.sort, opts.filter), [item.id, opts.sort, opts.filter]);
  const onBack = useCallback(effects.navigate(dispatch, item.parentKey), [item.parentKey]);
  const onDragOver = useCallback(effects.dragOver(), []);
  const onDragEnter = useCallback(effects.dragEnter(setDrop, stateDrop), [setDrop, stateDrop]);
  const onDragLeave = useCallback(effects.dragLeave(setDrop, stateDrop), [setDrop, stateDrop]);
  const onDragStart = useCallback(effects.dragStart(dispatch, contextItems, item.type, item.name), [contextItems, item.type, item.name]);
  const onDragEnd = useCallback(effects.dragEnd(dispatch), []);
  const onDrop = useCallback(effects.dragDrop(dispatch, setDrop, item.id, item.name, item.parentKey), [setDrop, item.id, item.name]);
  const onMouseEnter = useCallback(appEffects.stateOn(setHover), [setHover]);
  const onMouseLeave = useCallback(appEffects.stateOff(setHover), [setHover]);
  const onHoverStart = useCallback(appEffects.stateOn(setTitle), [setTitle]);
  const onHoverEnd = useCallback(appEffects.stateOff(setTitle), [setTitle]);

  const open = isNavigable ? onNavigate : onPreview;

  // Modals & Menus
  const [showFlags] = useModalFlags(textFlagged);
  const [showShare] = useModalShare(dispatch, [item.id], item.name, item.url);
  const [showPassword] = useModalPasswordProtect(item.id, item.name, isFolder, dispatch);
  const [showMenuFiles] = useMenuFiles(contextItems, contextItem, contextOptions, dispatch, anchor.current, (x: number, y: number) => {setMenuPosX(x); setMenuPosY(y)});
  const [showUpsellFileDrop] = useModalUpsellFeature('pro', 'filedrop');
  const [showUpsellPassword] = useModalUpsellFeature('pro', 'password');

  // Legacy modals

  const showFileDrop = useCallback((e) => {
    e.stopPropagation();
    Modal.show(
      <FilesModalFileDrop
        dispatch={dispatch}
        id={item.id}
        name={item.name}
        isFileDrop={item.filedrop}
        isMinimal={opts.isMinimal}
        primaryColor={opts.primaryColor}
      />
    , modalFilesFileDrop);
  } , [item.id, item.filedrop, opts.isMinimal]);

  return {
    anchor,

    stateTitle,
    stateHover,
    stateMenuPosX,
    stateMenuPosY,

    contextOptions,
    contextItems,
    contextItem,

    isOwned,
    isFlagged,
    isImage,
    isShared,
    isFolder,
    isFocused,
    isSelected,
    isDragging,
    isDropping,
    isNavigable,
    isExpandable,
    hasSelection,
    hasUploadError,

    textTimestamp,
    textPhotoURI,
    textDetails,
    textFlagged,
    textOpen,
    percentHashed,
    percentUploaded,
    colorSecondary,
    colorPrimary,
    styleColorPrimary,

    open,
    copyLink,
    onSelect,
    onDownload,
    onNavigate,
    onPreview,
    onBrowse,
    onBack,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDragStart,
    onDragEnd,
    onDrop,
    onMouseEnter,
    onMouseLeave,
    onHoverStart,
    onHoverEnd,

    showFlags,
    showPassword,
    showMenuFiles,
    showShare,
    showFileDrop,
    showUpsellFileDrop,
    showUpsellPassword,
  };
}

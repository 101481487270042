import React from 'react';
import {t} from '@lingui/macro';
import isEqual from 'react-fast-compare';
import {Styles, View, GestureView, Text, Button} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {VirtualListViewItemInfo} from 'react-ult-ext-virtuallistview';
import {useFileItem, FileItem, FileListOptions} from 'hooks/useFileItem';
import {FilesItemIcon} from 'view/FilesThumbnail';
import {Light, Vectors, Colors} from 'features/themes';
import {isTouch} from 'features/platform';

export const FilesGridItemNormal = 176;

export interface FilesGridItemProps {
  info: FileItem,
  opts: FileListOptions,
}

export interface FilesGridRowProps extends VirtualListViewItemInfo {
  context: FileItem[] | null;
  opts: FileListOptions,
}

function FilesGridItem(props: FilesGridItemProps) {
  const {id, url, name, type} = props.info;
  const item = useFileItem(props.info, props.opts);

  // UI state
  const hasTouch = isTouch();
  const hasDragDrop = item.isOwned;
  const classes = {
    root: [
      styles.root,
      styles.normal,
      item.isFocused && (item.isSelected ? styles.focusedAndSelected : styles.focused),
      item.isDropping && styles.dropping,
      item.isDragging && styles.dragging,
    ],
    bar: [
      styles.bar,
      (hasTouch || (item.stateHover || item.isSelected)) && styles.visible,
      item.isSelected && item.isImage && styles.barSelected,
    ],
    name: [
      styles.name,
      item.isSelected && item.isImage && styles.nameSelected,
    ],
    anchor: [
      styles.anchor,
      Styles.createViewStyle({left: item.stateMenuPosX, top: item.stateMenuPosY}, false),
    ],
  };

  return (
    <View
      style={classes.root}
      onDrop={hasDragDrop && item.isFolder ? item.onDrop : undefined}
      onDragOver={hasDragDrop && item.isFolder ? item.onDragOver : undefined}
      onDragEnter={hasDragDrop && item.isFolder ? item.onDragEnter : undefined}
      onDragLeave={hasDragDrop && item.isFolder ? item.onDragLeave : undefined}
      onDragStart={hasDragDrop ? item.onDragStart : undefined}
      onDragEnd={hasDragDrop ? item.onDragEnd : undefined}
      onMouseEnter={item.onMouseEnter}
      onMouseLeave={item.onMouseLeave}
      title={name}>
      <GestureView
        style={styles.inner}
        onContextMenu={item.showMenuFiles}
        onTap={item.hasSelection ? item.onSelect : item.open}>
        <View ref={item.anchor} style={classes.anchor}/>
        <FilesItemIcon
          gallery
          selected={item.isSelected}
          dropping={item.isDropping}
          id={id}
          url={url}
          name={name}
          type={type}
          src={item.textPhotoURI}
          details={item.textDetails}
          width={FilesGridItemNormal}
          height={FilesGridItemNormal}
          primaryColor={item.isSelected
            ? Light.menuHeaderMultiText
            : item.colorPrimary}
        />
        {!item.isShared &&
          <View style={classes.bar}>
            <Button onPress={item.onSelect} title={t`Select ${name}`}>
              <View style={styles.check}>
                <Svg viewBox={'0 0 24 24'} width={20} height={20}>
                  <SvgPath
                    d={Vectors.iconCheck}
                    fillColor={item.isSelected
                      ? Light.menuHeaderMultiText
                      : Colors.neutral.mid}/>
                </Svg>
              </View>
              <Text
                style={classes.name}
                ellipsizeMode="middle"
                numberOfLines={1}>
                {name}
              </Text>
            </Button>
          </View>
      }
      </GestureView>
    </View>
  );
}

export const FilesGridRow = React.memo(function FilesGridRow(props: FilesGridRowProps) {
  return (
    <View style={styles.row}>
      {props.context.map((item, i) => item && item.id
        ? <FilesGridItem
            key={item.id}
            info={item}
            opts={props.opts}
          />
        : <View key={`blank-${i}`} style={styles.root}/>
      )}
    </View>
  );
}, (prev, next) => isEqual(prev, next));

export const styles = {
  row: Styles.createViewStyle({
    flexDirection: 'row',
    justifyContent: 'center',
    height: FilesGridItemNormal,
    backgroundColor: Light.listItemNormalBackground,
    position: 'relative',
  }),
  root: Styles.createViewStyle({
    height: FilesGridItemNormal,
    width: FilesGridItemNormal,
    padding: 8,
    paddingLeft: 0,
    paddingBottom: 0,
  }),
  normal: Styles.createViewStyle({
    backgroundColor: Light.listItemNormalBackground,
  }),
  dragging: Styles.createViewStyle({
    opacity: 0.4,
  }),
  dropping: Styles.createViewStyle({
    opacity: 1,
    backgroundColor: Colors.primary.light,
  }),
  focused: Styles.createViewStyle({
    backgroundColor: Light.listItemFocusBackground,
  }),
  focusedAndSelected: Styles.createViewStyle({
    backgroundColor: Light.listItemFocusSelectedBackground,
  }),
  inner: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
  }),
  name: Styles.createTextStyle({
    color: 'transparent',
    lineHeight: 32,
    marginLeft: 24,
    fontSize: 14,
  }),
  nameSelected: Styles.createTextStyle({
    color: Light.listItemSelectText,
  }),
  check: Styles.createViewStyle({
    position: 'absolute',
    left: 8,
    top: 8,
  }),
  bar: Styles.createViewStyle({
    position: 'absolute',
    right: 0,
    left: 0,
    height: 34,
    opacity: 0,
    flex: 1,
    borderWidth: 1,
    borderColor: 'transparent',
  }),
  barSelected: Styles.createViewStyle({
    backgroundColor: Light.listItemSelectBackground,
    borderColor: Light.listItemSelectBorder,
  }),
  visible: Styles.createViewStyle({
    opacity: 1,
  }),
  anchor: Styles.createViewStyle({
    top: 0,
    left: 0,
    width: 1,
    height: 1,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0)',
  }),
};

import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';

const _modalConflict = 'conflict';

export function useModalConflict(id: string) {
  const show = useCallback((submit: (option: string) => void) => {
    const pick = (choice: string) => {
      submit(choice);
      Modal.dismiss(_modalConflict);
    };

    Modal.show(
      <Prompt
        id={_modalConflict}
        title={t`File Conflict`}
        text={t`Files with conflicting names exist in this folder. Choose what to do with duplicate files.`}
        buttons={[{
          text: t`KEEP BOTH`,
          mode: 'primary',
          onPress: () => pick('keep'),
        }, {
          text: t`REPLACE`,
          mode: 'destructive',
          onPress: () => pick('replace'),
        }, {
          text: t`SKIP`,
          mode: 'cancel',
          onPress: () => pick('skip_with_errors'),
        }]}
      />
    , _modalConflict);
  }, [id]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalConflict);
  }, [id]);

  return [show, close];
}

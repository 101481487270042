import device from 'features/device';
import {Colors, Fonts, FontSizes, Breakpoints} from './styles';
import {Vectors} from './vectors';
import {Theme} from './models';
import Light from './light';
import Dark from './dark';

let Default: Theme = device.isDarkMode() ? Dark : Light;

export {Default as Light};
export {
  Colors,
  Vectors,
  Fonts,
  FontSizes,
  Breakpoints,
};

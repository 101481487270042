import {t} from '@lingui/macro';
import React from 'react';
import {Styles, View, Button, Types} from 'react-ult';
import WebView, {Types as WebViewTypes} from 'react-ult-ext-webview';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {Light, Colors, Vectors} from 'features/themes';

export interface Props {
  url: string,
  close: () => void,
  onMessage: (e: WebViewTypes.WebViewMessageEvent) => void,
  isProTrialSignup?: boolean,
}

export function WebFrame(props: Props) {
  return (
    <View style={styles.root} onPress={!props.isProTrialSignup ? props.close : undefined}>
      <View style={props.isProTrialSignup ? styles.contentProTrial : styles.content}
        accessibilityTraits={Types.AccessibilityTrait.Dialog}
        disableTouchOpacityAnimation={true}
        restrictFocusWithin={true}
        tabIndex={-1}>
        <WebView
          scalesPageToFit
          url={props.url}
          onMessage={props.onMessage}
          onError={console.debug}
          onLoad={console.debug}
          sandbox={
              WebViewTypes.WebViewSandboxMode.AllowTopNavigation
            | WebViewTypes.WebViewSandboxMode.AllowSameOrigin
            | WebViewTypes.WebViewSandboxMode.AllowScripts
            | WebViewTypes.WebViewSandboxMode.AllowPopups
            | WebViewTypes.WebViewSandboxMode.AllowForms
          }
        />
        <Button style={styles.close} onPress={props.close} title={t`Close dialog`}>
          <Svg viewBox="0 0 14 14" width={14} height={14}>
            <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.black}/>
          </Svg>
        </Button>
      </View>
    </View>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Light.dialogBehind,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  content: Styles.createViewStyle({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  contentProTrial: Styles.createViewStyle({
    width: 580,
    height: 370,
  }),
  close: Styles.createButtonStyle({
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: 99,
    height: 46,
    width: 46,
    right: 8,
    top: 9,
  }),
};

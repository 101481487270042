import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Modal, Storage} from 'react-ult';
import {Types} from 'react-ult-ext-webview';
import {Dispatch} from '@reduxjs/toolkit';
import {WebFrame} from 'controls/WebFrame';
import {alert} from 'features/mediafire';
import {getHost} from 'features/platform';

const _modalId = 'upsell-pro-trial';

export function useModalUpsellProTrial(dispatch: Dispatch) {
  const close = useCallback(() => Modal.dismiss(_modalId), []);
  const message = useCallback((e: Types.WebViewMessageEvent) => {
    if (e && e.data === 'mf-close-dialogs')
      close();
    if (e.origin !== 'https://checkout.mediafire.com'
      && e.origin !== 'https://www.mediafire.com')
      return;
    switch (e.data) {
      case 'mf-trial-close':
        close();
        break;
      case 'mf-trial-denied':
        alert(dispatch, t`Credit card declined. Please verify details are correct.`, 'fail');
        break;
    }
  }, []);

  const show = useCallback(() => {
    Storage.setItem('upsell:trial', '1');
    Modal.show(
      <WebFrame
        isProTrialSignup
        url={`${getHost()}/widgets/premium_trial.php`}
        onMessage={message}
        close={close}
      />
    , _modalId)
  }, []);

  return [show, close];
}

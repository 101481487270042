import {createSelector} from '@reduxjs/toolkit';
import {AppUserGroup} from 'globals/types';
import {Store} from 'store';

const x = (store: Store) => store;

export const isActive = createSelector(x, store => store.app.active);
export const isLoggedIn = createSelector(x, store => !!store.app.user);
export const isMenuOpen = createSelector(x, store => store.app.menuOpen);

export const isTrial = createSelector(x, store => store.app.user && !store.app.user.email);
export const isPremium = createSelector(x, store => store.app.user && store.app.user.group >= AppUserGroup.Pro);
export const isBusiness = createSelector(x, store => store.app.user && store.app.user.group === AppUserGroup.Business);

export const getUser = createSelector(x, store => store.app.user);
export const getName = createSelector(x, store => store.app.user && store.app.user.name);
export const getEkey = createSelector(x, store => store.app.user && store.app.user.ekey);
export const getEmail = createSelector(x, store => store.app.user && store.app.user.email);
export const getLimits = createSelector(x, store => store.app.user && store.app.user.limit);
export const getAvatar = createSelector(x, store => store.app.user && store.app.user.avatar);

export const getRevision = createSelector(x, store => store.app.revision);
export const getViewport = createSelector(x, store => store.app.viewport);
export const getFeature = createSelector(x, store => store.app.feature);
export const getNotifications = createSelector(x, store => store.app.notifications);
export const getHeader = createSelector(x, store => store.app.user && store.app.user.header);
export const getAlerts = createSelector(x, store => store.app.alerts);

import {useState, useEffect} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Input, Popup, Modal, Types} from 'react-ult';
import {useModalCreateFolder} from 'hooks/useModalCreateFolder';
import {useModalRename} from 'hooks/useModalRename';
import {useModalTrash} from 'hooks/useModalTrash';
import {useModalPurge} from 'hooks/useModalPurge';
import {Keys} from 'globals/keys';
import * as effects from 'store/files/effects';

export function useHotkeys(
  folderId: string,
  view: {files: string[], folders: string[]},
  selection: string[],
  focusId: string,
  isSearch: boolean,
  dispatch: Dispatch,
) {
  const [isRangeSelect, setRangeSelect] = useState(false);
  const [showCreateFolder] = useModalCreateFolder(folderId);
  const [_showRename] = useModalRename(focusId, '', dispatch);
  const [showTrash] = useModalTrash(selection, dispatch);
  const [showPurge] = useModalPurge(selection, dispatch);

  useEffect(() => {
    const keydown = (e: Types.KeyboardEvent) => {
      if (Popup.isDisplayed() || Modal.isDisplayed())
        return false;

      // Shift select
      if (e.keyCode === Keys.Shift)
        setRangeSelect(true);

      switch (e.keyCode) {
        // Select all files in folder
        case Keys.A:
          if (isSearch) break;
          if (e.ctrlKey || e.metaKey) {
            if (view) {
              effects.selectMulti(dispatch, [
                ...view.folders,
                ...view.files,
              ].filter(id => !selection.includes(id)))();
            }
          }
          break;

        // Clear selection or exit search
        case Keys.Escape:
          if (isSearch)
            effects.search(dispatch, null)();
          else
            effects.selectClear(dispatch, null)(e);
          break;

        // Open uploader
        case Keys.U:
          if (isSearch) break;
          effects.uploadStart(dispatch, folderId)();
          break;

        // TODO: copy link for selected items
        case Keys.C:
          break;

        // TODO: paste link for selected items (move)
        case Keys.V:
          break;

        // TODO: rename prompt
        case Keys.F12:
          //showRename();
          break;

        // Create folder
        case Keys.N:
          if (isSearch) break;
          showCreateFolder();
          break;

        // Open delete prompt
        case Keys.Delete:
        case Keys.Backspace:
          if (selection.length > 0
            && (e.keyCode !== Keys.Backspace || e.shiftKey)) {
            if (folderId === 'trash') {
              showPurge();
            } else {
              showTrash();
            }
          }
          break;

      }

      return true;
    };
  
    const keyup = (e: Types.KeyboardEvent) => {
      if (Popup.isDisplayed() || Modal.isDisplayed())
        return false;
      // Shift select
      if (e.keyCode === Keys.Shift)
        setRangeSelect(false);
      return true;
    };

    Input.keyDownEvent.subscribe(keydown);
    Input.keyUpEvent.subscribe(keyup);
    return () => {
      Input.keyDownEvent.unsubscribe(keydown);
      Input.keyUpEvent.unsubscribe(keyup);
    };

  }, [view, selection]);

  return {isRangeSelect}
}

import {t} from '@lingui/macro';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Styles, View, Text, Button, Modal, Types} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {SafeAreaView} from 'controls/SafeArea';
import {IconButton} from 'controls/IconButton';
import {FilesList} from 'view/FilesList';
import {bulkDownloadStatus, downloadState, downloadSync} from 'features/mediafire';
import {Vectors, Colors, Light} from 'features/themes';
import {bytesize} from 'features/common';
import {isWeb} from 'features/platform';
import {state} from 'store';
import {selectors} from 'store/files';
import * as filesEffects from 'store/files/effects';
import * as app from 'store/app';

export interface Props {
  minimized: boolean;
  offset: boolean;
}

export function FilesDownloader(props: Props) {
  const isPremium = useSelector(app.selectors.isPremium);
  const viewport = useSelector(app.selectors.getViewport);
  const downloads = useSelector(selectors.getDownloadBulk);
  const items = useSelector(selectors.getDownloadItems);
  const view = useSelector(selectors.getDownloadView);
  const filter = useSelector(selectors.getFilter);
  const sort = useSelector(selectors.getSort);
  const status = downloadState(items);
  const downloadCurrent = status.downloaded;
  const downloadTotal = status.size;
  const hasDownload = downloads.length > 0;
  const hasSkipped = status.skipped === status.count && status.count > 0;
  const isSmall = viewport.width < 600;
  const isMinimal = viewport.width < 800 || viewport.height < 520;
  const isComplete = status.completed;
  const downloadPercent = Math.max(0, Math.min(100, (downloadCurrent / downloadTotal) * 100));
  const downloadPixels = (downloadCurrent / downloadTotal) * (isMinimal ? viewport.width - 126 : 654);
  const downloadPixelsMini = (downloadCurrent / downloadTotal) * (isMinimal ? viewport.width - 36 : 314);
  const downloadHeight = isMinimal ? viewport.height - 143 : 357;
  const classes = {
    root: [
      styles.root,
      props.minimized && styles.rootMinimized,
      props.minimized && props.offset && styles.rootMinimizedOffset,
      props.minimized && isSmall && styles.rootMinimizedSmall,
    ],
    downloader: [
      isMinimal ? styles.downloaderMobile : styles.downloader,
    ],
    bar: [
      styles.bar,
      hasSkipped && styles.barFailed,
    ],
    downloaded: [
      styles.downloaded,
      Styles.createViewStyle({width: isNaN(downloadPixelsMini) ? 0 : downloadPixelsMini}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'background-color 0.2s, width 0.5s ease'}, false),
    ],
    downloadMain: [
      styles.downloadMain,
      Styles.createViewStyle({width: isNaN(downloadPixels) ? 0 : downloadPixels}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'background-color 0.2s, width 0.5s ease'}, false),
    ],
  };

  const textSizes = `${bytesize(downloadCurrent, 1)} / ${bytesize(downloadTotal, 1)}`;
  const textCount = `${status.count} File${status.count !== 1 ? 's' : ''}`;
  const textSkipped = `${status.skipped} file${status.skipped !== 1 ? 's' : ''} not downloaded`;
  const textFinished = `${status.finished} .zip file${status.finished !== 1 ? 's' : ''} downloaded.${isMinimal ? '' : ' It may take a moment for it to complete in your browser.'}`;
  const textDownload = props.minimized
    ? hasSkipped 
      ? t`Bulk download aborted due to skipped files`
      : isComplete
        ? t`Packaging download. Your download will complete soon.`
        : t`Bulk download in progress…`
    : `${Math.floor(downloadPercent)}%`;
  const textTitle = isComplete
    ? hasSkipped
      ? t`Bulk Download Failed`
      : t`Bulk Download Completed`
    : t`Bulk Downloading ${textCount}`;

  const dispatch = useDispatch();
  const noop = useCallback((e) => e.stopPropagation(), []);
  const minimize = useCallback(filesEffects.downloadMinimized(dispatch, true), []);
  const maximize = useCallback(filesEffects.downloadMinimized(dispatch, false), []);

  // Stop and close the downloader
  const close = useCallback(() => {
    Modal.dismissAll();
    dispatch(state.files.actions.bulkDownloadClose());
  }, []);

  useEffect(() => {
    const completed = [];
    const update = () => {
      const promises = [];
      downloads.forEach(download => {
        if (!completed.includes(download.statusKey)) {
          promises.push(bulkDownloadStatus(download.statusKey));
        }
      });
      Promise.all(promises).then(res => {
        const items = res.map((status, i) => {
          const id = downloads[i].statusKey;
          const name = downloads[i].fileName;
          if (status.state === 'complete')
            completed.push(id);
          const state = status.state;
          const total = status?.bytes?.total || 0;
          const bytes = total - (status?.summary?.bytesToSend || 0);
          return {id, name, state, total, bytes};
        });
        downloadSync(dispatch, items);
      })
    };
    update();
    const interval = setInterval(update, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [downloads]);

  return hasDownload ? (
    <View
      disableTouchOpacityAnimation
      style={classes.root}
      onPress={!props.minimized
        ? isComplete ? close : minimize
        : noop}>
        {props.minimized
          ? <View
              disableTouchOpacityAnimation
              style={styles.downloaderMin}
              title={t`Show bulk downloader`}
              onPress={maximize}>
              <View style={classes.bar}>
                {!hasSkipped && <View style={classes.downloaded}></View>}
              </View>
              <View style={styles.downloadBar}>
                <Text style={styles.downloadText}>
                  {textDownload}
                </Text>
                {!isComplete &&
                  <Text style={styles.downloadPercent}>
                    {textSizes}
                  </Text>
                }
              </View>
            </View>
          : <View
              style={classes.downloader}
              accessibilityTraits={Types.AccessibilityTrait.Dialog}
              disableTouchOpacityAnimation={true}
              restrictFocusWithin={true}
              tabIndex={-1}
              onPress={noop}>
              <SafeAreaView style={{flex: 1, backgroundColor: Colors.neutral.black}}>
                <View style={styles.downloadMaximized}>
                  <View style={styles.header}>
                    <Text style={styles.title}>
                      {textTitle}
                    </Text>
                    {!isComplete
                      ? <Button style={styles.close} onPress={minimize} title={t`Minimize bulk downloader`}>
                          <Svg viewBox="0 0 24 24" width={24} height={24}>
                            <SvgPath d={Vectors.iconMin} fillColor={Colors.neutral.mid}/>
                          </Svg>
                        </Button>
                      : <Button style={styles.close} onPress={close} title={t`Close bulk downloader`}>
                          <Svg viewBox="0 0 14 14" width={14} height={14}>
                            <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.mid}/>
                          </Svg>
                        </Button>
                    }
                  </View>
                  <FilesList
                    uploader
                    id={'bulkdownload'}
                    name={t`Downloader`}
                    dispatch={dispatch}
                    viewport={viewport}
                    isSearch={false}
                    isPremium={isPremium}
                    maxheight={downloadHeight}
                    filter={filter}
                    sort={sort}
                    view={view}
                    items={items}
                    selection={[]}
                    dragging={[]}
                  />
                  <View style={styles.actions}>
                    {isComplete &&
                      <Button style={[styles.buttonDownload, Styles.createButtonStyle({cursor: 'default'}, false)]}>
                        <Svg viewBox="0 0 20 20" width={20} height={20}>
                          <SvgPath
                            d={status.skipped === 0 ? Vectors.iconCheck : Vectors.iconWarning}
                            fillColor={status.skipped === 0 ? Colors.secondary.green : Colors.secondary.redOrange}
                          />
                        </Svg>
                        <Text style={styles.textCompleted}>
                          {status.skipped === 0 ? textFinished : textSkipped}
                        </Text>
                      </Button>
                    }
                    {(hasSkipped || isComplete) &&
                      <View style={styles.spacer}/>
                    }
                    {!isComplete && !hasSkipped &&
                      <View style={styles.mainProgress}>
                        <View style={styles.mainProgressBar}>
                          <View style={classes.downloadMain}></View>
                        </View>
                        <View style={styles.mainProgressDetails}>
                          <Text style={styles.mainProgressProgress}>{textSizes}</Text>
                          <Text style={styles.mainProgressPercent}>
                            {textDownload}
                          </Text>
                        </View>
                      </View>
                    }
                    <View style={styles.buttonsActive}>
                      <IconButton
                        mode="cancel"
                        onPress={close}
                        title={t`Close bulk downloader`}
                        text={isComplete ? t`CLOSE` : t`CANCEL`}
                      />
                    </View>
                  </View>
                </View>
              </SafeAreaView>
            </View>
        }
      </View>
  ) : null;
}

export const styles = {
  root: Styles.createViewStyle({
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Light.dialogBehind,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  rootMinimized: Styles.createViewStyle({
    width: 350,
    height: 65,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: Colors.neutral.darker,
    shadowOffset: {width: -1, height: -1},
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 3,
    elevation: 1,
    top: undefined,
    left: undefined,
    bottom: 0,
    right: 16,
  }),
  rootMinimizedOffset: Styles.createViewStyle({
    bottom: 72,
    borderRadius: 3,
  }),
  rootMinimizedSmall: Styles.createViewStyle({
    left: 0,
    right: 0,
    width: undefined,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
  downloader: Styles.createViewStyle({
    width: 780,
    height: 500,
    borderRadius: 3,
    backgroundColor: Light.uploaderContentBackground,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 3,
    elevation: 1,
  }),
  downloaderMobile: Styles.createViewStyle({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Light.uploaderContentBackground,
  }),
  downloadMaximized: Styles.createViewStyle({
    flex: 1,
    backgroundColor: Light.uploaderContentBackground,
  }),
  spacer: Styles.createViewStyle({
    flex: 1,
  }),
  header: Styles.createViewStyle({
    flexDirection: 'row',
    alignItems: 'center',
    height: 54,
    borderBottomWidth: 1,
    borderColor: Light.uploaderBarBorder,
    backgroundColor: Light.uploaderBarBackground,
  }),
  title: Styles.createTextStyle({
    color: Light.uploaderBarText,
    paddingLeft: 20,
    fontSize: 16,
    flex: 1,
  }),
  textCompleted: Styles.createTextStyle({
    color: Light.uploaderBarText,
    marginLeft: 8,
    fontSize: 14,
  }),
  actions: Styles.createViewStyle({
    flexDirection: 'row',
    height: 62,
    paddingRight: 16,
    borderTopWidth: 1,
    borderColor: Light.uploaderBarBorder,
    backgroundColor: Light.uploaderBarBackground,
  }),
  close: Styles.createButtonStyle({
    alignItems: 'center',
    width: 54,
    height: 54,
    top: 1,
  }),
  buttonsActive: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  }),
  buttonDownload: Styles.createButtonStyle({
    marginRight: 6,
    marginLeft: 16,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  downloaderMin: Styles.createViewStyle({
    flex: 1,
    paddingTop: 21,
    paddingHorizontal: 18,
    paddingBottom: 19,
    cursor: 'pointer',
    flexDirection: 'column',
  }),
  bar: Styles.createViewStyle({
    position: 'relative',
    height: 6,
    borderRadius: 3,
    backgroundColor: '#424E65',
  }),
  barFailed: Styles.createViewStyle({
    backgroundColor: Colors.secondary.redOrange,
  }),
  downloaded: Styles.createViewStyle({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: '#33CC66',
  }),
  downloadMain: Styles.createViewStyle({
    flex: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: '#33CC66',
  }),
  downloadBar: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    marginTop: 5,
  }),
  downloadText: Styles.createTextStyle({
    fontSize: 12,
    flex: 1,
    color: Colors.neutral.lighter,
  }),
  downloadPercent: Styles.createTextStyle({
    fontSize: 12,
    flex: 0,
    color: Colors.neutral.lighter,
  }),
  mainProgress: Styles.createViewStyle({
    flex: 1,
    marginLeft: 18,
    marginRight: 16,
  }),
  mainProgressBar: Styles.createViewStyle({
    flex: 0,
    height: 6,
    marginTop: 16,
    borderRadius: 3,
    backgroundColor: '#d6d6d6',
  }),
  mainProgressDetails: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
  }),
  mainProgressProgress: Styles.createTextStyle({
    fontSize: 12,
    flex: 1,
    color: Light.uploaderBarText,
  }),
  mainProgressPercent: Styles.createTextStyle({
    fontSize: 12,
    flex: 0,
    color: Light.uploaderBarText,
  }),
};

import {t} from '@lingui/macro';
import React, {useCallback, Component} from 'react';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useMenu} from 'hooks/useMenu';
import {Vectors, Colors} from 'features/themes';
import {FilesStore} from 'globals/types';

type Sort = FilesStore['sort'];
type Filter = FilesStore['filter'];

export function useMenuFilter(
  id: string,
  sort: Sort,
  filter: Filter,
  onFilter: (sort: Sort, filter: Filter) => void,
  anchor: Component,
) {
  const selectFilter = useCallback((type: FilesStore['filter']['type']) => {
    onFilter(sort, {type});
  }, [id, anchor, onFilter]);

  const icon = useCallback((target: string) => 
    <Svg viewBox="0 0 24 24" width={16} height={16}>
      <SvgPath
        d={filter.type === target ? Vectors.iconRadioOn : Vectors.iconRadioOff}
        fillColor={Colors.neutral.mid}
        // TODO: dark = colors.neutral.light
      />
    </Svg>
  , [filter]);

  const [show, close] = useMenu([
    {
      command: 'header',
      text: t`Filter`,
    },
    {
      command: 'all',
      icon: icon('all'),
      text: t`All Items`,
    },
    {
      command: 'public',
      icon: icon('public'),
      text: t`Public`,
    },
    {
      command: 'private',
      icon: icon('private'),
      text: t`Private`,
    },
    {
      command: 'images',
      icon: icon('images'),
      text: t`Images`,
    },
    {
      command: 'video',
      icon: icon('video'),
      text: t`Video`,
    },
    {
      command: 'audio',
      icon: icon('audio'),
      text: t`Audio`,
    },
    {
      command: 'document',
      text: t`Documents`,
      icon: icon('document'),
    },
    {
      command: 'spreadsheet',
      icon: icon('spreadsheet'),
      text: t`Spreadsheets`,
    },
    {
      command: 'presentation',
      icon: icon('presentation'),
      text: t`Presentations`,
    },
    {
      command: 'development',
      icon: icon('development'),
      text: t`Development`,
    },
  ], (command: FilesStore['filter']['type']) => {
    selectFilter(command);
    close();
  }, anchor, undefined, ['bottom', 'top'], true, false, false);

  return [show, close];
}

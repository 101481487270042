import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Modal} from 'react-ult';
import {Prompt} from 'controls/Prompt';

const _modalFlags = 'flags';

export function useModalFlags(message: string) {
  const show = useCallback(() => {
    Modal.show(
      <Prompt
        id={_modalFlags}
        title={t`File Restricted`}
        text={message}
        buttons={[{
          text: t`OK`,
          mode: 'primary',
          onPress: () => {
            Prompt.dismissAnimated(_modalFlags);
          },
        }]}
      />
    , _modalFlags);
  }, [message]);

  const close = useCallback(() => {
    Prompt.dismissAnimated(_modalFlags);
  }, [message]);

  return [show, close];
}

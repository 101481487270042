import {DeviceBase} from './models';

class Device implements DeviceBase {
  share(url: string, title: string) {
    navigator.share({url, title}).catch(() => {});
  }

  restartApp() {
    location.reload();
  }

  getLocale(short?: boolean): string {
    const locale = (navigator.language || navigator['userLanguage']);
    return short ? locale.split('-').shift() : locale;
  }
  
  isDarkMode() {
    return matchMedia('(prefers-color-scheme: dark)').matches;
  }

  isPreferredGeo() {
    try {
      const dateTime = Intl.DateTimeFormat().resolvedOptions();
      const isEnglish = dateTime.locale.toString().startsWith('en-');
      const isAmerica = dateTime.timeZone.toString().startsWith('America/');
      return isEnglish && isAmerica;
    } catch(e) {
      return false;
    }
  }

  suscribeTheme(update: (isDark: boolean) => void) {
    matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', e => update(e.matches));
  }
}

export default new Device();

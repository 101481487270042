import {t} from '@lingui/macro';
import isEqual from 'react-fast-compare';
import React, {useCallback, useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Styles, View, Button, Link, Text, TextInput, Image, Modal, StatusBar} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {SafeAreaView} from 'controls/SafeArea';
import {IconButton} from 'controls/IconButton';
import {useMenuSort} from 'hooks/useMenuSort';
import {useMenuFiles} from 'hooks/useMenuFiles';
import {useMenuFilter} from 'hooks/useMenuFilter';
import {useModalShare} from 'hooks/useModalShare';
import {useModalPurge} from 'hooks/useModalPurge';
import {useModalPrivate} from 'hooks/useModalPrivate';
import {useModalCreateFolder} from 'hooks/useModalCreateFolder';
import {FilesModalBulkDownload, modalFilesBulkDownload} from 'view/modals/FilesModalBulkDownload';
import {Colors, Breakpoints, Vectors, Light} from 'features/themes';
import {FilesItemFlag} from 'globals/types';
import {SCOPE} from 'globals/config';
import {isFolderkey, isQuickkey, getShareLink} from 'features/mediafire';
import {getHost} from 'features/platform';
import {testBit, luminance} from 'features/common';
import images from 'features/images';
import device from 'features/device';
import {selectors} from 'store/files';
import * as app from 'store/app';
import * as appEffects from 'store/app/effects';
import * as filesEffects from 'store/files/effects';

export const filesBarHeight = 65;

export interface FilesBarProps {
  id: string;
}

export const FilesBar = React.memo(function FilesBar(props: FilesBarProps) {
  // Local state
  const anchorMenuFiles = useRef();
  const anchorMenuFilesAlt = useRef();
  const anchorMenuSort = useRef();
  const anchorMenuFilter = useRef();
  const anchorMenuSortAlt = useRef();
  const anchorMenuFilterAlt = useRef();
  const [stateSearch, setSearch] = useState('');
  // Global state
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const isPremium = useSelector(app.selectors.isPremium);
  const viewport = useSelector(app.selectors.getViewport);
  const header = useSelector(app.selectors.getHeader);
  const avatar = useSelector(app.selectors.getAvatar);
  const trash = useSelector(selectors.getView('trash'));
  const trashIds = trash ? [...trash.files, ...trash.folders] : [];
  const isSearch = useSelector(selectors.isSearch);
  const layout = useSelector(selectors.getLayout);
  const filter = useSelector(selectors.getFilter);
  const sort = useSelector(selectors.getSort);
  const selection = useSelector(selectors.getSelection);
  const folder = useSelector(selectors.getItem(props.id));
  const upload = useSelector(selectors.getUpload);
  const selectItem = useSelector(selectors.getItem(selection[0]));
  const hasPrivate = useSelector(selectors.hasPrivateItems(selection));
  // Computed state
  const host = getHost();
  const isDarkMode = device.isDarkMode();
  const isDefaultAvatar = avatar && avatar.indexOf('/myfiles/default.png') !== -1;
  const imageAvatar = (isDefaultAvatar || !avatar) ? images.avatar : avatar;
  const itemUrl = selectItem ? getShareLink(selectItem.id, selectItem.type, selectItem.name) : '';
  const isRoot = props.id === 'myfiles';
  const isTrash = props.id === 'trash';
  const isRecent = props.id === 'recent';
  const isFollowing = props.id === 'following';
  const isLimited = isRecent || isFollowing;
  const isSpecial = isRoot || isTrash || isRecent || isFollowing;
  const isOwned = folder && testBit(folder.flag, FilesItemFlag.isOwned);
  const isShared = !isSpecial && folder && !isOwned;
  const isModeSelect = selection.length > 0;
  const isModeSearch = !isModeSelect && isSearch;
  const isModeTrash = !isModeSelect && isTrash;
  const isModeShared = !isModeSelect && !isModeTrash && isShared;
  const isModeNormal = !isModeSelect && !isModeSearch && !isModeShared && !isModeTrash;
  const hasCustomLogo = header && !!header.logo;
  const hasCustomHeader = header && header.custom;


  const files = selection.filter(id => isQuickkey(id));
  const folders = selection.filter(id => isFolderkey(id));
  const hasFolders = folders.length > 0;
  const hasFiles = files.length > 0;
  const hasMixed = hasFiles && hasFolders;
  const hasMultiple = selection.length > 1;

  const hasFolder = isFolderkey(selection[0]);
  const hasMenu = viewport.width <= Breakpoints.menu;
  const hasLogo = viewport.width >= 320;
  const hasSort = viewport.width >= 388;
  const hasFilter = viewport.width >= 450;
  const hasFullLogo = !isModeSelect && viewport.width >= 1024;
  const hasFullText = viewport.width >= 860;
  const hasCopyLink = viewport.width >= 400 && !hasPrivate;
  const hasSelected = viewport.width >= 320;
  const isMinimal = viewport.width < 600;
  const isLight = header && header.theme.header === 'light';
  const opacityButton = !hasCustomHeader ? 1.0 : 0.6;
  const opacityLogo = (!hasCustomHeader && !isModeSelect)
    || (isModeSelect && isDarkMode)
    || isModeTrash
      ? 1.0
      : 0.6;
  const iconColorLogoText = isModeShared
    ? Colors.primary.dark
    : isModeTrash
      ? Colors.neutral.white
      : isModeSelect
        ? isDarkMode
          ? Colors.neutral.white
          : Colors.neutral.black
        : hasCustomHeader
          ? isLight
            ? Colors.neutral.white
            : Colors.neutral.black
          : Colors.neutral.white;
  const iconColorLogoFlame = isModeShared
    ? Light.logoColor
    : iconColorLogoText;
  const iconColor = hasCustomHeader
    ? isLight ? Colors.neutral.white : Colors.neutral.black
    : Colors.primary.light;
  const iconColorAlpha = hasCustomHeader
    ? isLight ? `rgba(255,255,255,0.4)` : `rgba(0,0,0,0.4)`
    : `rgba(255,255,255,0.4)`;
  const iconSelectColor = isDarkMode
    ? Colors.neutral.white
    : Colors.neutral.black;
  const iconSelectOpacity = isDarkMode
    ? 1
    : 0.5;
  const primaryColor = header
    ? header.colors.primary
    : Light.logoColor;
  const viewIcon = layout === 'list'
    ? Vectors.iconGrid
    : Vectors.iconList;
  const headerColor = hasCustomHeader
    ? header.colors.header
    : Light.logoColor;
  // Computed styles
  const classes = {
    root: [
      styles.root,
      hasCustomHeader && Styles.createViewStyle({backgroundColor: headerColor}, false),
      isModeShared && styles.rootShared,
      isModeTrash && styles.rootTrash,
      isModeSelect && styles.rootSelect,
      isModeSelect && isShared && styles.rootSharedSelect,
    ],
    selectButton: [
      styles.selectButton,
      !hasFullText && styles.noMarginLeft,
    ],
    buttonText: [
      styles.buttonText,
      isModeSelect && styles.buttonTextSelect,
      isModeSelect && isDarkMode && styles.buttonTextSelectDark,
    ],
    searchInput: [
      styles.searchInput,
      Styles.createTextInputStyle({color: iconColor, backgroundColor: headerColor}, false),
    ],
    signup: [
      styles.signup,
      hasCustomHeader && Styles.createLinkStyle({backgroundColor: headerColor}, false),
    ],
  };

  const hoverStyle = Styles.createButtonStyle({
    backgroundColor: isModeSelect && !isDarkMode
      ? Light.listItemSelectBorder
      : luminance(primaryColor, -0.2),
  }, false);

  const url = hasMultiple ? `${getHost(true)}/folder${SCOPE}${selection.join(',')}/shared` : itemUrl;
  const name = hasMultiple ? t`Multiple Items` : selectItem?.name;

  // Actions
  const dispatch = useDispatch();
  const [showCreateFolder] = useModalCreateFolder(props.id);
  const [showShare] = useModalShare(dispatch, selection, name, url);
  const [showPurge] = useModalPurge(selection.length > 0 ? selection : trashIds, dispatch);
  const [showPrivate] = useModalPrivate(selection, dispatch);
  const openMenu = useCallback(appEffects.menuOpen(dispatch), []);
  const myfiles = useCallback(filesEffects.navigate(dispatch, 'myfiles'), []);
  const toggleLayout = useCallback(filesEffects.layout(dispatch), []);
  const selectClear = useCallback(filesEffects.selectClear(dispatch, null), []);
  const searchOpen = useCallback(filesEffects.search(dispatch, 'myfiles'), []);
  const searchSubmit = useCallback(filesEffects.search(dispatch, 'myfiles', stateSearch), [stateSearch]);
  const searchClose = useCallback(filesEffects.search(dispatch, null), []);
  const uploadStart = useCallback(filesEffects.uploadStart(dispatch, props.id), [props.id]);
  const uploadMaximize = useCallback(filesEffects.uploadMinimize(dispatch, false), []);
  const copy = useCallback(filesEffects.copyLink2(dispatch, selection, selectItem ? selectItem.url : ''), [selection, selectItem]);
  const restore = useCallback(filesEffects.pick(dispatch, {action: 'restore', destination: 'myfiles', ids: selection.length > 0 ? selection : trashIds}), [selection, trashIds]);
  const download = useCallback(filesEffects.downloadFile(itemUrl), [itemUrl]);

  const bulkDownload = useCallback(() => Modal.show(
    <FilesModalBulkDownload
      dispatch={dispatch}
      ids={selection}
      primaryColor={primaryColor}
    />
  , modalFilesBulkDownload), [selection]);

  const [showMenuSort] = useMenuSort(props.id, sort, filter, filesEffects.sort(dispatch, props.id), anchorMenuSort.current || anchorMenuSortAlt.current);
  const [showMenuFilter] = useMenuFilter(props.id, sort, filter, filesEffects.filter(dispatch, props.id), anchorMenuFilter.current || anchorMenuFilterAlt.current);
  const [showMenuFiles] = useMenuFiles(
    selection,
    selectItem,
    {
      isPremium,
      isMinimal,
      hasPrivate,
      primaryColor,
    },
    dispatch,
    anchorMenuFiles.current || anchorMenuFilesAlt.current,
  );

  const downloadSelection = hasMultiple || hasFolder
    ? bulkDownload
    : download;
  
  useEffect(() => {
    const style = isModeTrash
      ? 'light-content'
      : isModeSelect
        ? 'dark-content'
        : !hasCustomHeader
          ? 'light-content'
          : isLight
            ? 'light-content'
            : 'dark-content';
    StatusBar.setBarStyle(style, false);
  }, [isLoggedIn, isModeNormal, isModeTrash, isModeSelect, hasCustomHeader, isLight]);

  return (
    <SafeAreaView style={{
      backgroundColor: isModeTrash
        ? Colors.secondary.redOrange
        : isModeSelect
          ? Light.listItemSelectBackground
          : headerColor}}>
      <View style={classes.root}>
        {hasLogo && !isModeSearch &&
          <View style={styles.logo} onPress={myfiles}>
            {!hasCustomLogo && hasFullLogo &&
              <Svg style={styles.vector} viewBox="0 0 291 41" width={168} height={24}>
                <SvgPath d={Vectors.logoFlame} fillColor={iconColorLogoFlame} fillOpacity={opacityLogo}/>
                {Vectors.logoFull.map((d,i) =>
                  <SvgPath key={i} d={d} fillColor={iconColorLogoText} fillOpacity={opacityLogo}/>
                )}
              </Svg>
            }
            {!hasCustomLogo && !hasFullLogo &&
              <Svg style={styles.vector} viewBox="0 0 75 41" width={44} height={24}>
                <SvgPath d={Vectors.logoFlame} fillColor={iconColorLogoFlame} fillOpacity={opacityLogo}/>
              </Svg>
            }
            {hasCustomLogo &&
              <div style={{
                width: '190px',
                height: '65px',
                marginLeft: '12px',
                backgroundPosition: '0 center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${header.logo})`,
              }}/>
            }
          </View>
        }
        {isModeNormal &&
          <View style={styles.actions}>
            <View style={styles.spacer}/>
            {!isLimited &&
              <IconButton
                onPress={upload ? uploadMaximize : uploadStart}
                title={t`Upload files`}
                text={hasFullText ? t`UPLOAD` : ''}
                textStyle={classes.buttonText}
                buttonStyle={styles.button}
                buttonHoverStyle={hoverStyle}
                icon={
                  <Svg viewBox="0 0 14 17" width={14} height={17}>
                    <SvgPath d={Vectors.iconUpload} fillColor={iconColor} fillOpacity={opacityButton}/>
                  </Svg>
                }
              />
            }
            {hasSort && !isLimited &&
              <IconButton
                ref={anchorMenuSort}
                onPress={showMenuSort}
                title={t`Sort folder`}
                text={hasFullText ? t`SORT` : ''}
                textStyle={classes.buttonText}
                buttonStyle={styles.button}
                buttonHoverStyle={hoverStyle}
                icon={
                  <Svg viewBox="0 0 20 20" width={20} height={20}>
                    <SvgPath d={Vectors.iconSortAlt} fillColor={iconColor} fillOpacity={opacityButton}/>
                  </Svg>
                }
              />
            }
            {hasFilter && !isLimited &&
              <IconButton
                ref={anchorMenuFilter}
                onPress={showMenuFilter}
                title={t`Filter folder`}
                text={hasFullText ? t`FILTER` : ''}
                textStyle={classes.buttonText}
                buttonStyle={styles.button}
                buttonHoverStyle={hoverStyle}
                icon={
                  <Svg viewBox="0 0 24 24" width={24} height={24}>
                    <SvgPath d={Vectors.iconFilter} fillColor={iconColor} fillOpacity={opacityButton}/>
                  </Svg>
                }
              />
            }
            {!isLimited &&
              <IconButton
                onPress={searchOpen}
                title={t`Search files`}
                buttonStyle={styles.button}
                buttonHoverStyle={hoverStyle}
                icon={
                  <Svg viewBox="0 0 18 18" width={18} height={18}>
                    <SvgPath d={Vectors.iconSearch} fillColor={iconColor} fillOpacity={opacityButton}/>
                  </Svg>
                }
              />
            }
            {!isLimited &&
              <IconButton
                onPress={showCreateFolder}
                title={t`Create folder`}
                buttonStyle={styles.button}
                buttonHoverStyle={hoverStyle}
                icon={
                  <Svg viewBox="0 0 20 16" width={20} height={16}>
                    <SvgPath d={Vectors.iconFolderAdd} fillColor={iconColor} fillOpacity={opacityButton}/>
                  </Svg>
                }
              />
            }
            {!hasMenu &&
              <IconButton
                onPress={toggleLayout}
                title={t`Change view`}
                buttonStyle={styles.button}
                buttonHoverStyle={hoverStyle}
                icon={
                  <Svg viewBox="0 0 24 24" width={24} height={24}>
                    <SvgPath d={viewIcon} fillColor={iconColor} fillOpacity={opacityButton}/>
                  </Svg>
                }
              />
            }
            {hasMenu &&
              <IconButton
                onPress={openMenu}
                title={t`Open main menu`}
                buttonStyle={styles.button}
                buttonHoverStyle={hoverStyle}
                icon={
                  <Svg viewBox="0 0 18 12" width={18} height={12}>
                    <SvgPath d={Vectors.iconMenu} fillColor={iconColor} fillOpacity={opacityButton}/>
                  </Svg>
                }
              />
            }
          </View>
        }
        {isModeSearch &&
          <View style={styles.actions}>
            <View style={styles.searchIcon}>
              <Svg viewBox="0 0 20 20" width={20} height={20}>
                <SvgPath d={Vectors.iconSearch} fillColor={iconColor} fillOpacity={opacityButton}/>
              </Svg>
            </View>
            <View style={styles.search}>
              <TextInput
                autoFocus
                spellCheck
                style={classes.searchInput}
                autoCorrect={false}
                maxLength={40}
                onChangeText={setSearch}
                onSubmitEditing={searchSubmit}
                placeholder={t`Search files & folders`}
                placeholderTextColor={iconColorAlpha}
              />
            </View>
            <IconButton
              onPress={searchClose}
              title={t`Clear search`}
              buttonStyle={styles.button}
              buttonHoverStyle={hoverStyle}
              icon={
                <Svg viewBox="0 0 14 14" width={14} height={14}>
                  <SvgPath d={Vectors.iconClose} fillColor={iconColor} fillOpacity={opacityButton}/>
                </Svg>
              }
            />
          </View>
        }
        {isModeSelect &&
          <View style={styles.actions}>
          {hasSelected &&
            <Button
              ref={anchorMenuFiles}
              style={styles.selected}
              onPress={showMenuFiles}
              title={t`Open selection menu`}>
              <Text style={styles.selectedText}>
                {hasFullText
                  ? t`${selection.length} ${hasMultiple ? 'items' : 'item'} selected`
                  : selection.length}
              </Text>
              <View style={styles.spacer}/>
              <Svg viewBox="0 0 24 24" width={18} height={16}>
                <SvgPath
                  d={Vectors.iconDropDown}
                  fillColor={iconSelectColor}
                  fillOpacity={0.4}
                />
              </Svg>
            </Button>
          }
          <View style={styles.spacer}/>
          {!isTrash && hasCopyLink &&
            <IconButton
              onPress={copy}
              title={t`Copy selection link`}
              text={hasFullText ? t`COPY LINK` : ''}
              textStyle={classes.buttonText}
              buttonStyle={styles.button}
              buttonHoverStyle={hoverStyle}
              icon={
                <Svg viewBox="0 0 20 10" width={18} height={18}>
                  <SvgPath d={Vectors.iconPublic} fillColor={iconSelectColor} fillOpacity={iconSelectOpacity}/>
                </Svg>
              }
            />
          }
          {!hasMixed && !isTrash &&
            <IconButton
              onPress={hasPrivate ? showPrivate : showShare}
              title={t`Share selection`}
              text={hasFullText ? t`SHARE` : ''}
              textStyle={classes.buttonText}
              buttonStyle={styles.button}
              buttonHoverStyle={hoverStyle}
              icon={
                <Svg viewBox="0 0 20 20" width={18} height={16}>
                  <SvgPath d={Vectors.iconShare} fillColor={iconSelectColor} fillOpacity={iconSelectOpacity}/>
                </Svg>
              }
            />
          }
          {!isTrash &&
            <IconButton
              onPress={downloadSelection}
              title={t`Download selection`}
              text={hasFullText ? t`DOWNLOAD` : ''}
              textStyle={classes.buttonText}
              buttonStyle={styles.button}
              buttonHoverStyle={hoverStyle}
              icon={
                <Svg viewBox="0 0 14 17" width={16} height={16}>
                  <SvgPath d={Vectors.iconDownload} fillColor={iconSelectColor} fillOpacity={iconSelectOpacity}/>
                </Svg>
              }
            />
          }
          {isTrash &&
            <IconButton
              onPress={showPurge}
              title={t`Delete selection`}
              text={hasFullText ? t`DELETE` : ''}
              textStyle={classes.buttonText}
              buttonStyle={styles.button}
              buttonHoverStyle={hoverStyle}
              icon={
                <Svg viewBox="0 0 24 24" width={24} height={24}>
                  <SvgPath d={Vectors.iconEmptyTrash} fillColor={iconSelectColor} fillOpacity={iconSelectOpacity}/>
                </Svg>
              }
            />
          }
          {isTrash &&
            <IconButton
              onPress={restore}
              title={t`Restore selection`}
              text={hasFullText ? t`RESTORE` : ''}
              textStyle={classes.buttonText}
              buttonStyle={styles.button}
              buttonHoverStyle={hoverStyle}
              icon={
                <Svg viewBox="0 0 24 24" width={24} height={24}>
                  <SvgPath d={Vectors.iconRestoreTrash} fillColor={iconSelectColor} fillOpacity={iconSelectOpacity}/>
                </Svg>
              }
            />
          }
          <IconButton
            onPress={selectClear}
            title={t`Clear selection`}
            buttonStyle={styles.button}
            buttonHoverStyle={hoverStyle}
            icon={
              <Svg viewBox="0 0 14 14" width={14} height={14}>
                <SvgPath d={Vectors.iconClose} fillColor={iconSelectColor} fillOpacity={iconSelectOpacity}/>
              </Svg>
            }
          />
        </View>
        }
        {isModeTrash &&
          <View style={styles.actions}>
            <View style={styles.spacer}/>
            <IconButton
              onPress={showPurge}
              title={t`Purge all files and folders`}
              text={hasFullText ? t`EMPTY TRASH` : ''}
              textStyle={classes.buttonText}
              buttonStyle={styles.button}
              buttonHoverStyle={styles.buttonTrashHover}
              icon={
                <Svg viewBox="0 0 24 24" width={24} height={24}>
                  <SvgPath d={Vectors.iconEmptyTrash} fillColor={Colors.neutral.white}/>
                </Svg>
              }
            />
            <IconButton
              onPress={restore}
              title={t`Restore all files and folders`}
              text={hasFullText ? t`RESTORE ALL` : ''}
              textStyle={classes.buttonText}
              buttonStyle={styles.button}
              buttonHoverStyle={styles.buttonTrashHover}
              icon={
                <Svg viewBox="0 0 24 24" width={24} height={24}>
                  <SvgPath d={Vectors.iconRestoreTrash} fillColor={Colors.neutral.white}/>
                </Svg>
              }
            />
            {hasMenu &&
              <IconButton
                onPress={openMenu}
                title={t`Open main menu`}
                buttonStyle={styles.button}
                buttonHoverStyle={styles.buttonTrashHover}
                icon={
                  <Svg viewBox="0 0 18 12" width={18} height={12}>
                    <SvgPath d={Vectors.iconMenu} fillColor={Colors.neutral.white}/>
                  </Svg>
                }
              />
            }
          </View>
        }
        {isModeShared &&
          <View style={styles.actions}>
            <View style={styles.spacer}/>
            {isLoggedIn &&
              <Button
                onPress={myfiles}
                style={styles.avatar}
                title={t`Go to your My Files`}>
                <Image resizeMode="cover" style={styles.imageAvatar} source={imageAvatar}/>
              </Button>
            }
            {!isLoggedIn &&
              <View style={styles.authMenu}>
                <Link
                  style={classes.signup}
                  url={`${host}/upgrade`}
                  title={t`Create a MediaFire account`}>
                  {t`SIGN UP`}
                </Link>
                <Link
                  style={styles.login}
                  url={`${host}/login`}
                  title={t`Log in to your MediaFire account`}>
                  {t`LOG IN`}
                </Link>
              </View>
            }
          </View>
        }
      </View>
      <View ref={anchorMenuFilesAlt} ignorePointerEvents style={[styles.anchor, styles.anchorFiles]}/>
      <View ref={anchorMenuSortAlt} ignorePointerEvents style={[styles.anchor, styles.anchorSort]}/>
      <View ref={anchorMenuFilterAlt} ignorePointerEvents style={styles.anchor}/>
    </SafeAreaView>
  );
}, (prev, next) => isEqual(prev, next));

export const styles = {
  root: Styles.createViewStyle({
    height: 65,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Light.logoColor,
  }),
  actions: Styles.createViewStyle({
    flex: 1,
    height: 65,
    paddingRight: 6,
    paddingVertical: 12,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
  }),
  desktopUpload: Styles.createViewStyle({
    flex: 0,
  }),
  spacer: Styles.createViewStyle({
    flex: 1,
  }),
  logo: Styles.createViewStyle({
    flex: 0,
    maxWidth: 800,
  }),
  vector: Styles.createViewStyle({
    marginLeft: 16,
  }),
  button: Styles.createButtonStyle({
    minWidth: 60,
    paddingHorizontal: 12,
    alignItems: 'center',
    flexDirection: 'row',
  }),
  buttonText: Styles.createTextStyle({
    color: Colors.primary.light,
    fontSize: 13,
  }),
  buttonTextSelect: Styles.createTextStyle({
    color: Colors.neutral.mid,
  }),
  buttonTextSelectDark: Styles.createTextStyle({
    color: Colors.neutral.white,
  }),
  avatar: Styles.createButtonStyle({
    width: 65,
    height: 65,
  }),
  anchor: Styles.createViewStyle({
    height: 1,
    width: 1,
    opacity: 0,
    position: 'absolute',
    right: 238,
    top: 54,
  }),
  anchorSort: Styles.createViewStyle({
    right: 333,
  }),
  anchorFiles: Styles.createViewStyle({
    right: undefined,
    left: 188,
  }),
  // Search
  search: Styles.createViewStyle({
    flex: 1,
  }),
  searchIcon: Styles.createButtonStyle({
    justifyContent: 'center',
    alignItems: 'center',
    width: 54,
  }),
  searchInput: Styles.createTextInputStyle({
    color: Colors.neutral.white,
    borderColor: Colors.neutral.white,
    borderBottomWidth: 2,
    fontSize: 18,
    flex: 1,
  }),
  // Select
  rootSelect: Styles.createViewStyle({
    backgroundColor: Light.listItemSelectBackground,
  }),
  selectButton: Styles.createButtonStyle({
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    minWidth: 60,
    height: 65,
  }),
  noMarginLeft: Styles.createButtonStyle({
    marginLeft: 0,
  }),
  text: Styles.createTextStyle({
    color: Colors.neutral.mid,
    fontSize: 12,
    margin: 8,
  }),
  icon: Styles.createViewStyle({
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  iconCopy: Styles.createViewStyle({
    marginTop: 8,
  }),
  iconDownload: Styles.createViewStyle({
    marginTop: 4,
  }),
  selected: Styles.createViewStyle({
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Light.listItemSelectBackground,
    borderColor: Light.barSelectButtonBorder,
    borderWidth: 1,
    height: 30,
    marginVertical: 18,
    marginRight: 4,
    marginLeft: 10,
    borderRadius: 18,
    paddingHorizontal: 7,
  }),
  selectedText: Styles.createTextStyle({
    paddingHorizontal: 6,
    color: Light.menuHeaderMultiText,
    fontSize: 11,
  }),
  // Trash
  rootTrash: Styles.createViewStyle({
    backgroundColor: Colors.secondary.redOrange,
  }),
  buttonTrashHover: Styles.createButtonStyle({
    backgroundColor: luminance(Colors.secondary.redOrange, -0.2),
  }),
  // Shared
  rootShared: Styles.createViewStyle({
    backgroundColor: Colors.neutral.white,
    borderColor: Colors.neutral.lighter,
    borderBottomWidth: 1,
  }),
  rootSharedSelect: Styles.createViewStyle({
    borderColor: Light.listItemSelectBackground,
    borderBottomWidth: 1,
  }),
  imageAvatar: Styles.createImageStyle({
    height: 48,
    width: 48,
    borderRadius: 3,
  }),
  iconMenu: Styles.createImageStyle({
    height: 12,
  }),
  authMenu: Styles.createViewStyle({
    height: 65,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  }),
  signup: Styles.createLinkStyle({
    height: 36,
    marginLeft: 4,
    marginRight: 8,
    paddingHorizontal: 16,
    fontSize: 12,
    lineHeight: 36,
    borderRadius: 3,
    backgroundColor: Light.logoColor,
    color: Colors.neutral.white,
  }),
  login: Styles.createLinkStyle({
    height: 36,
    marginLeft: 4,
    marginRight: 16,
    paddingHorizontal: 16,
    fontSize: 12,
    lineHeight: 32,
    borderWidth: 2,
    borderRadius: 3,
    borderColor: Colors.neutral.lighter,
    backgroundColor: Colors.neutral.white,
    color: Colors.neutral.mid,
  }),
};

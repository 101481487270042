import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {Dispatch} from '@reduxjs/toolkit';
import {Modal} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useMenu} from 'hooks/useMenu';
import {useModalShare} from 'hooks/useModalShare';
import {useModalTrash} from 'hooks/useModalTrash';
import {useModalPurge} from 'hooks/useModalPurge';
import {useModalRename} from 'hooks/useModalRename';
import {useModalDescribe} from 'hooks/useModalDescribe';
import {useModalPrivate} from 'hooks/useModalPrivate';
import {useModalUpsellFeature} from 'hooks/useModalUpsellFeature';
import {useModalPasswordProtect} from 'hooks/useModalPasswordProtect';
import {FilesModalFileDrop, modalFilesFileDrop} from 'view/modals/FilesModalFileDrop';
import {FilesModalBulkDownload, modalFilesBulkDownload} from 'view/modals/FilesModalBulkDownload';
import {isQuickkey, isFolderkey} from 'features/mediafire';
import {isNative, getHost} from 'features/platform';
import {Vectors, Colors} from 'features/themes';
import {testBit} from 'features/common';
import {SCOPE} from 'globals/config';
import * as effects from 'store/files/effects';
import * as MF from 'globals/types';

export function useMenuFiles(
  ids: string[],
  item: MF.FilesItem,
  options: {
    isPremium: boolean,
    isMinimal: boolean,
    hasPrivate: boolean,
    primaryColor: string,
  },
  dispatch: Dispatch,
  anchor: any,
  anchorUpdate?: Function,
) {
  const files = ids.filter(id => isQuickkey(id));
  const folders = ids.filter(id => isFolderkey(id));

  const hasMultiple = ids.length > 1;
  const hasFolders = folders.length > 0;
  const hasFiles = files.length > 0;
  const hasMixed = hasFiles && hasFolders;
  const hasOnlyFiles = hasFiles && !hasFolders;
  const hasOnlyFolders = hasFolders && !hasFiles;

  const isOwned = testBit(item?.flag, MF.FilesItemFlag.isOwned);
  const isInAccount = isOwned && !item.deleted;
  const isFolder = item?.type === 'folder';
  const isImage = item?.type === 'image';

  const hasShare = !item?.deleted && (!hasMixed || isNative());
  const hasOneTimeLink = !hasMultiple && hasOnlyFiles;
  const hasCopyLink = !options.hasPrivate && !item?.deleted;
  const hasDownload = !item?.deleted && !hasMultiple && !hasOnlyFolders;
  const hasDownloadBulk = !item?.deleted && (hasMultiple || hasOnlyFolders);
  const hasSaveToMyFiles = !isOwned;
  const hasCopyTo = isInAccount;
  const hasMoveTo = isInAccount;
  const hasRename = isInAccount && !hasMultiple;
  const hasDescription = isInAccount && !hasMultiple;
  const hasPasswordProtect = isInAccount && hasOnlyFiles && !hasMultiple && !isImage;
  const hasFileDropConfigure = isInAccount && hasOnlyFolders && !hasMultiple;
  const hasMoveToTrash = isInAccount;
  const hasRestore = item?.deleted;
  const hasPurge = item?.deleted;
  const hasView = isImage && !item?.deleted && !hasMultiple;

  const url = hasMultiple ? `${getHost()}/folder${SCOPE}${ids.join(',')}/shared` : item?.url;
  const name = hasMultiple ? 'Multiple Items' : item?.name;
  const title = hasMultiple ? t`${ids.length} items selected` : item?.name;
  const destination = 'myfiles';

  const bulkDownload = useCallback(() => {
    Modal.show(
      <FilesModalBulkDownload
        ids={ids}
        primaryColor={options.primaryColor}
        dispatch={dispatch}
      />
    , modalFilesBulkDownload);
  }, [ids]);
 
  const filedrop = useCallback(() => {
    Modal.show(
      <FilesModalFileDrop
        id={ids[0]}
        name={item.name}
        isFileDrop={item.filedrop}
        primaryColor={options.primaryColor}
        isMinimal={options.isMinimal}
        dispatch={dispatch}
      />
    , modalFilesFileDrop);
  }, [ids]);

  const icon = useCallback((viewbox: string, width: number, height: number, icon: string) =>
    <Svg viewBox={viewbox} width={width} height={height}>
      <SvgPath d={icon} fillColor={Colors.neutral.mid}/>
    </Svg>
  , []);

  const save = useCallback(effects.pick(dispatch, {action: 'save', destination, ids}), [ids, dispatch]);
  const copy = useCallback(effects.pick(dispatch, {action: 'copy', destination, ids}), [ids, dispatch]);
  const move = useCallback(effects.pick(dispatch, {action: 'move', destination, ids}), [ids, dispatch]);
  const restore = useCallback(effects.pick(dispatch, {action: 'restore', destination, ids}), [ids, dispatch]);
  const copyLink = useCallback(effects.copyLink2(dispatch, ids, item?.url, ''), [ids, item]);
  const download = useCallback(effects.downloadFile(item?.url, true), [item]);
  const viewImage = useCallback(effects.downloadFile(item?.url), [item]);
  const [showShare] = useModalShare(dispatch, ids, name, url);
  const [show1TDL] = useModalShare(dispatch, ids, name, url, true);
  const [showTrash] = useModalTrash(ids, dispatch);
  const [showPurge] = useModalPurge(ids, dispatch);
  const [showPrivate] = useModalPrivate(ids, dispatch);
  const [showRename] = useModalRename(ids[0], item?.name, dispatch);
  const [showDescribe] = useModalDescribe(ids[0], item?.name, item?.description, isFolder, dispatch);
  const [showPasswordProtect] = useModalPasswordProtect(ids[0], item?.name, isFolder, dispatch);
  const [showUpsellOneTimeLink] = useModalUpsellFeature('pro', 'one_time_link');
  const [showUpsellFileDrop] = useModalUpsellFeature('pro', 'filedrop');
  const [showUpsellPassword] = useModalUpsellFeature('pro', 'password');
  
  const [show, close] = useMenu([
    {
      command: 'header',
      text: title,
    },
    hasShare && {
      command: 'share',
      text: t`Share`,
      icon: icon('0 0 20 24', 18, 18, Vectors.iconShare),
    },
    hasOneTimeLink && {
      command: 'one-time-link',
      text: t`1-Time Link`,
      icon: icon('0 0 24 24', 20, 20, Vectors.iconClock),
    },
    hasCopyLink && {
      command: 'copy-link',
      text: t`Copy Link`,
      icon: icon('0 0 20 10', 18, 18, Vectors.iconPublic),
    },
    hasView && {
      command: 'view',
      text: t`View Image`,
      icon: icon('0 0 16 20', 18, 18, Vectors.iconImage),
    },
    hasDownload && {
      command: 'download',
      text: t`Download`,
      icon: icon('0 0 16 20', 18, 18, Vectors.iconDownload),
    },
    hasDownloadBulk && {
      command: 'bulk-download',
      text: t`Bulk Download`,
      icon: icon('0 0 16 20', 18, 18, Vectors.iconDownload),
    },
    hasSaveToMyFiles && {
      command: 'save',
      text: t`Save to My Files...`,
      icon: icon('0 0 60 48', 18, 18, Vectors.iconSave),
    },
    isInAccount && {
      command: '',
      text: '-',
    },
    hasMoveTo && {
      command: 'move',
      text: t`Move to...`,
      icon: icon('0 0 24 24', 18, 18, Vectors.iconMove),
    },
    hasCopyTo && {
      command: 'copy',
      text: t`Copy to...`,
      icon: icon('0 0 24 24', 18, 18, Vectors.iconCopy),
    },
    hasRename && {
      command: 'rename',
      text: t`Rename`,
      icon: icon('0 0 24 20', 18, 18, Vectors.iconRename),
    },
    hasDescription && {
      command: 'describe',
      text: t`Description`,
      icon: icon('0 0 24 10', 18, 18, Vectors.iconDescription),
    },
    hasPasswordProtect && {
      command: 'password',
      text: t`Password Protect`,
      icon: icon('0 0 24 24', 18, 18, Vectors.iconPassword),
    },
    hasFileDropConfigure && {
      command: 'filedrop',
      text: t`Configure FileDrop`,
      icon: icon('0 0 24 16', 16, 18, Vectors.iconFiledrop),
    },
    isInAccount && {
      command: '',
      text: '-',
    },
    hasMoveToTrash && {
      command: 'trash',
      text: t`Move to Trash`,
      icon: icon('0 0 16 22', 16, 18, Vectors.iconTrash),
    },
    hasRestore && {
      command: 'restore',
      text: t`Restore to folder`,
      icon: icon('0 0 24 24', 20, 20, Vectors.iconRestoreTrash),
    },
    hasPurge && {
      command: 'purge',
      text: t`Delete Permanently`,
      icon: icon('0 0 24 24', 20, 20, Vectors.iconEmptyTrash),
    },
  ], (command) => {
    close();
    switch (command) {
      case 'share':
        return options.hasPrivate
          ? showPrivate()
          : showShare();
      case 'one-time-link':
        return options.isPremium
          ? show1TDL()
          : showUpsellOneTimeLink();
      case 'copy-link':
        return copyLink({});
      case 'view':
        return viewImage({});
      case 'download':
        return download({});
      case 'bulk-download':
        return bulkDownload();
      case 'filedrop':
        return options.isPremium
          ? filedrop()
          : showUpsellFileDrop();
      case 'save':
        return save({});
      case 'move':
        return move();
      case 'copy':
        return copy();
      case 'rename':
        return showRename();
      case 'describe':
        return showDescribe();
      case 'password':
        return options.isPremium
          ? showPasswordProtect()
          : showUpsellPassword();
      case 'trash':
        return showTrash();
      case 'restore':
        return restore();
      case 'purge':
        return showPurge();
    }
  }, anchor, anchorUpdate, ['context', 'bottom', 'top', 'right'], true, false, hasMultiple);

  return [show, close];
}

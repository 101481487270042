import React, {useCallback} from 'react';
import {Modal} from 'react-ult';
import {Types} from 'react-ult-ext-webview';
import {WebFrame} from 'controls/WebFrame';
import {getHost, isIOS} from 'features/platform';

const _modalId = 'upsell-feature';

type Plans = 'pro' | 'biz';
type Features = 'filedrop' | 'password' | 'bulkdownload' | 'webupload' | 'one_time_link';

export function useModalUpsellFeature(plan: Plans, feature: Features) {
  const close = useCallback(() => Modal.dismiss(_modalId), []);
  const message = useCallback((e: Types.WebViewMessageEvent) => {
    if (e && e.data === 'mf-close-dialogs') {
      close();
    }
  }, []);

  const show = useCallback(() => {
    if (isIOS()) return;
    const path = `${getHost()}/widgets/premium_upsell.php`;
    const query = `plan=${plan}&event=${feature}`;
    Modal.show(
      <WebFrame url={`${path}?${query}`} close={close} onMessage={message}/>
    , _modalId);
  }, [plan, feature]);

  return [show, close];
}

export function pickFiles(): Promise<FileList> {
  return new Promise(resolve => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = () => resolve(input.files);
    input.click();
  });
}

export function pickMedia(_type?: 'photo' | 'video') {
  return [];
}

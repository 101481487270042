import {Types} from 'react-ult';
import {default as FontRefs} from 'features/fonts';

export enum Breakpoints {
  menu = 900,
};

export enum FontSizes {
  size11 = 11,
  size12 = 12,
  size14 = 14,
  size16 = 16,
  size20 = 20,
  size32 = 32,
  menuItem = 16,
}

export const Colors = {
  primary: {
    brand: '#0070F0',
    dark: '#0e2866',
    medium: '#0045a6',
    light: '#e0eeff',
  },
  secondary: {
    green: '#66cc33',
    tan: '#feebc4',
    tanner: '#e2c18e',
    yellow: '#ffbf07',
    orange: '#ff8637',
    redOrange: '#ef5939',
    brightRed: '#e23b2a',
  },
  neutral: {
    black: '#000000',
    darker: '#1e232f',
    dark: '#282f3d',
    mid: '#888d9c',
    light: '#b2b7c4',
    lighter: '#e8e9ec',
    lightest: '#f4f4f5',
    whitish: '#fafafa',
    white: '#ffffff',
    transparent: 'rgba(0, 0, 0, 0)',
  },
  files: {
    text: '#000000',
    code: '#000000',
    document: '#0070F0',
    image: '#66cc33',
    pdf: '#e23b2a',
    video: '#9900ff',
    audio: '#9900ff',
    spreadsheet: '#00a651',
    archive: '#ffbf07',
    presentation: '#ef5939',
  },
};

export class Fonts {
  static monospace: Types.FontInfo = {
    fontFamily: FontRefs.monospace,
  };

  static displayLight: Types.FontInfo = {
    fontFamily: FontRefs.displayLight,
    fontWeight: '300',
  };

  static displayRegular: Types.FontInfo = {
    fontFamily: FontRefs.displayRegular,
    fontWeight: '400',
  };

  static displaySemibold: Types.FontInfo = {
    fontFamily: FontRefs.displaySemibold,
    fontWeight: '600',
  };

  static displayBold: Types.FontInfo = {
    fontFamily: FontRefs.displayBold,
    fontWeight: '700',
  };
}

import React from 'react';
import {useSelector} from 'react-redux';
import {Styles, View, Text} from 'react-ult';
import {Colors} from 'features/themes';
import {selectors} from 'store/app';

export interface Props {
  isOffset: boolean;
  isOffsetMore: boolean;
}

export function AppAlerts(props: Props) {
  const alerts = useSelector(selectors.getAlerts);
  const viewport = useSelector(selectors.getViewport);
  const classes = {
    root: [
      styles.root,
      (props.isOffset || props.isOffsetMore) && styles.offset,
      (props.isOffset && props.isOffsetMore) && styles.offsetMore,
      viewport.width >= 600 ? styles.desktop : styles.mobile,
    ],
  };

  return alerts && alerts.length > 0 ? (
    <View style={classes.root}>
      {alerts.map((e, i) => {
        return (
          <View key={i} style={[
            styles.alert,
            e.type === 'fail' && styles.fail,
          ]}>
            <Text style={styles.text}>
              {e.text}
            </Text>
          </View>
        )
      })}
    </View>
  ) : null;
}

export const styles = {
  root: Styles.createViewStyle({
    right: 16,
    bottom: 16,
    borderRadius: 3,
    position: 'absolute',
  }),
  offset: Styles.createViewStyle({
    bottom: 72,
  }),
  offsetMore: Styles.createViewStyle({
    bottom: 144,
  }),
  mobile: Styles.createViewStyle({
    left: 16,
  }),
  fail: Styles.createViewStyle({
    backgroundColor: Colors.secondary.redOrange,
  }),
  desktop: Styles.createViewStyle({
    width: 350,
  }),
  text: Styles.createTextStyle({
    fontSize: 14,
    color: Colors.neutral.white,
  }),
  alert: Styles.createViewStyle({
    marginTop: 8,
    borderRadius: 3,
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: Colors.primary.brand,
  }),
};

import {t} from '@lingui/macro';
import {Dispatch} from 'redux';
import {Types, Modal, Popup} from 'react-ult';
import {
  alert,
  load,
  loadSearch,
  syncFolder,
  download,
  preview,
  copyLink,
  move,
  archive,
  getShareLink,
  isFolderkey,
  getKeysFromUrl,
  appendFolderPathToFiles,
} from 'features/mediafire';
import {getHost, isWeb} from 'features/platform';
import {SCOPE} from 'globals/config';
import {state} from 'store';
import * as MF from 'globals/types';

// GENERAL

export function layout(dispatch: Dispatch) {
  return () => {
    dispatch(state.files.actions.layout());
  }
}

export function sort(dispatch: Dispatch, id: string) {
  return (sort: MF.FilesStore['sort'], filter: MF.FilesStore['filter']) => {
    dispatch(state.files.actions.sort(sort));
    syncFolder(dispatch, id, -1, -1, -1, sort, filter);
  }
}

export function filter(dispatch: Dispatch, id: string) {
  return (sort: MF.FilesStore['sort'], filter: MF.FilesStore['filter']) => {
    dispatch(state.files.actions.filter(filter));
    syncFolder(dispatch, id, -1, -1, -1, sort, filter);
  }
}

export function search(dispatch: Dispatch, id: string | null, term?: string) {
  return () => {
    const query = term && typeof term === 'string' && term.length >= 3 && term;
    query && loadSearch(dispatch, id, query);
    dispatch(state.files.actions.search({id, query}));
  }
}

export function copyLink2(dispatch: Dispatch, ids: string[], url: string, modalId?: string) {
  return (e: any) => {
    e.stopPropagation && e.stopPropagation();
    const shareUrl = ids.length === 1
      ? url
      : `${getHost(true)}/folder${SCOPE}${ids.join(',')}/shared`;
    alert(dispatch, t`Copied share link to clipboard`, 'info');
    copyLink(shareUrl);
    modalId && Popup.dismiss(modalId);
  }
}

export function downloadFile(url: string, forceDownload?: true) {
  return (e: any) => {
    e.stopPropagation && e.stopPropagation();
    download(url, false, forceDownload);
  }
}

export function previewFile(dispatch: Dispatch, id: string, name: string, url: string) {
  return async (e: any) => {
    e.stopPropagation && e.stopPropagation();
    if (isWeb()) {
      download(url);
    } else {
      dispatch(state.files.actions.preview({id, name}));
      await preview(id, name, dispatch, state.files.actions);
      dispatch(state.files.actions.previewClose());
    }
  }
}

export function navigate(dispatch: Dispatch, id?: string) {
  return (e: any) => {
    e && e.stopPropagation && e.stopPropagation();
    dispatch(state.files.actions.navigate({id}));
  }
}

export function browse(dispatch: Dispatch, id?: string, sort?: MF.FilesSort, filter?: MF.FilesFilter) {
  return (e?: any) => {
    e && e.stopPropagation && e.stopPropagation();
    dispatch(state.files.actions.browse({id}));
    id && load(dispatch, id, false, sort, filter);
  }
}

export function pick(dispatch: Dispatch, context: MF.FilesPick) {
  return (e?: any) => {
    e && e.stopPropagation && e.stopPropagation();
    dispatch(state.files.actions.pick(context));
    Popup.dismissAll();
  }
}

export function pickClear(dispatch: Dispatch) {
  dispatch(state.files.actions.pick(null));
}

// LISTS

export function listPick(pick: MF.FilesPick, dispatch: Dispatch, sort?: MF.FilesSort, filter?: MF.FilesFilter) {
  return (e: any) => {
    e.key && load(dispatch, e.key, false, sort, filter);
    if (pick) {
      dispatch(state.files.actions.pick({
        ids: pick.ids,
        action: pick.action,
        destination: e.key,
      }));
    } else {
      dispatch(state.files.actions.pick(null));
    }
  }
}

export function listOpen(dispatch: Dispatch) {
  return (e: any) => {
    if (e.type === 'folder') {
      dispatch(state.files.actions.navigate({id: e && e.key}));
    } else { 
      download(e.url);
    }
  }
}

export function listBrowse(dispatch: Dispatch, sort?: MF.FilesSort, filter?: MF.FilesFilter) {
  return (e: any) => {
    dispatch(state.files.actions.navigate({id: e.key}));
    e.key && load(dispatch, e.key, false, sort, filter);
  }
}

export function listFocus(dispatch: Dispatch) {
  return (e: any) => {
    dispatch(state.files.actions.focus({id: e && e.key}));
  }
}

export function listSelect(dispatch: Dispatch, ranged?: boolean, view?: MF.FilesView) {
  return (e: any) => {
    dispatch(state.files.actions.select({ranged, view, ids: [e && e.key]}));
  }
}

// SELECTING

export function select(dispatch: Dispatch, id?: string) {
  return (e?: any) => {
    e && e.stopPropagation && e.stopPropagation();
    const ids = [id || e && e['id']].filter(Boolean);
    dispatch(state.files.actions.select({ids}));
  }
}

export function selectMulti(dispatch: Dispatch, ids: string[]) {
  return () => {
    dispatch(state.files.actions.select({ids}));
  }
}

export function selectClear(dispatch: Dispatch, id: string | null) {
  return (e: any) => {
    dispatch(state.files.actions.select({ids: id ? [id] : []}));
    e.stopPropagation && e.stopPropagation();
  }
}

// DRAGGING

export function dragDrop(dispatch: Dispatch, setDrop: Function | null, id: string, name: string, parentKey?: string) {
  return (e?: Types.DragEvent) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    setDrop && setDrop(0);
    // Dnd uploading
    const files = e?.dataTransfer?.files;
    if (files && files.length > 0) {
      const items = e?.dataTransfer?.items;
      appendFolderPathToFiles(items, files, (content: File[]) => {
        dispatch(state.files.actions.upload({id, files: content}));
      });
    // Dnd moving
    } else {
      if (isFolderkey(id)) {
        const text = e.dataTransfer.getData('text');
        if (!text) return;
        const ids = getKeysFromUrl(text);
        if (!ids.includes(id) && (!parentKey || parentKey !== id)) {
          move(dispatch, ids, id, name);
        }
      }
    }
  }
}

export function dragDropTrash(dispatch: Dispatch) {
  return async (e?: Types.DragEvent) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    const text = e.dataTransfer.getData('text');
    if (!text) return;
    const ids = getKeysFromUrl(text);
    if (ids.length === 0) return;
    const response = await archive(dispatch, ids);
    if (response[0] || response[1]) {
      alert(dispatch, `Successfully moved items to trash`, 'info');
    } else {
      const message = response && response['error']
        ? response['message']
        : 'Failed to move items to trash';
      alert(dispatch, message, 'fail');
    }
  }
}

export function dragStart(dispatch: Dispatch, ids: string[], type: string, name: string) {
  return (e?: Types.DragEvent) => {
    dispatch(state.files.actions.drag({ids}));
    if (e) {
      const base = getHost();
      const path = ids.length === 1
        ? getShareLink(ids[0], type, name)
        : `${base}${SCOPE}folder/${ids.join(',')}`;
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text', path);
      e.dataTransfer.setData('text/uri-list', path);
      try {
        e.dataTransfer.setDragImage(new Image(), 0, 0);
      } catch(e) {}
    }
  }
}

export function dragEnd(dispatch: Dispatch) {
  return () => {
    dispatch(state.files.actions.drag({ids: null}));
  }
}

export function dragEnter(setDrop: Function, state: number) {
  return (e?: Types.DragEvent) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    setDrop(state + 1);
  }
}

export function dragLeave(setDrop: Function, state: number) {
  return (e?: Types.DragEvent) => {
    e && e.stopPropagation();
    setDrop(state - 1);
  }
}

export function dragOver() {
  return (e?: Types.DragEvent) => {
    e && e.preventDefault();
    e && e.stopPropagation();
  }
}

// UPLOADING

export function uploadStart(dispatch: Dispatch, id: string) {
  return () => {
    Modal.dismissAll();
    dispatch(state.files.actions.upload({id}));
  }
}

export function uploadEnd(dispatch: Dispatch) {
  return () => {
    dispatch(state.files.actions.upload({id: null}));
  }
}

export function uploadMinimize(dispatch: Dispatch, minimized: boolean) {
  return () => {
    dispatch(state.files.actions.uploadMinimize({minimized}));
  }
}

export function downloadMinimized(dispatch: Dispatch, minimized: boolean) {
  return () => {
    dispatch(state.files.actions.downloadMinimize({minimized}));
  }
}

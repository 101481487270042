import React, {useState, useCallback, useMemo} from 'react';
import isEqual from 'react-fast-compare';
import {Styles, View} from 'react-ult';
import {Dispatch} from '@reduxjs/toolkit';
import {useHotkeys} from 'hooks/useHotkeys';
import {
  VirtualListView,
  VirtualListViewCellRenderDetails,
  VirtualListViewItemInfo,
} from 'react-ult-ext-virtuallistview';
import {
  FilesListItem,
  FilesListItemProps,
  FilesListItemLarge,
  FilesListItemSmall,
  FilesListItemTiny,
} from 'view/FilesListItem';
import {Light, Colors, Breakpoints} from 'features/themes';
import {isTouch} from 'features/platform';
import * as effects from 'store/files/effects';
import * as MF from 'globals/types';

export interface FilesListProps {
  dispatch: Dispatch;
  id: string;
  name: string;
  sort: MF.FilesStore['sort'];
  filter: MF.FilesStore['filter'];
  items: {[id: string]: MF.FilesItem};
  view: {files: string[], folders: string[]};
  viewport: {width: number, height: number};
  dragging: string[];
  selection: string[];
  isPremium: boolean;
  isSearch: boolean,
  focusId?: string;
  primaryColor?: string;
  maxheight?: number;
  maxwidth?: number;
  hasPrivateSelection?: boolean;
  hasFolderTree?: boolean;
  uploader?: boolean;
  foreign?: boolean;
  picker?: boolean;
  tree?: boolean;
  onPick?: any;
}

export const FilesList = React.memo(function FilesList(props: FilesListProps) {
  const dispatch = props.dispatch;
  const {id, name, sort, filter, viewport, view, items, selection, picker, uploader, dragging, focusId, tree, primaryColor, isPremium} = props;
  const [stateDrop, setDrop] = useState(0);
  const hasTouch = isTouch();
  const isOwned = !props.foreign;
  const isTrash = props.id === 'trash';
  const isSearch = props.id === 'search';
  const isLargeRow = hasTouch || !isOwned;
  const isMinimalRow = viewport.width < 1024 || hasTouch;
  const isUploadFull = viewport.width >= 600;
  const hasGutter = (!isMinimalRow && !tree && !picker) || uploader;
  const hasFocus = !picker && !tree && !uploader;
  const heightHeader = isOwned ? isLargeRow ? 142 : 120 : 160;
  const maxHeight = props.maxheight || viewport.height - heightHeader;
  const maxWidth = props.maxwidth
    ? Math.min(viewport.width, props.maxwidth)
    : viewport.width - (viewport.width > Breakpoints.menu ? props.hasFolderTree ? 420 : 220 : 0);

  const {isRangeSelect} = useHotkeys(id, view, selection, focusId, props.isSearch, dispatch);

  const drop = useCallback(effects.dragDrop(dispatch, setDrop, id, name), [setDrop, id, name, items[id]?.parentKey]);
  const dragEnter = useCallback(effects.dragEnter(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragLeave = useCallback(effects.dragLeave(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragOver = useCallback(effects.dragOver(), []);

  const open = useCallback(effects.listOpen(dispatch), []);
  const focus = useCallback(effects.listFocus(dispatch), []);
  const select = useCallback(effects.listSelect(dispatch, isRangeSelect, view), [isRangeSelect, view]);
  const browse = useCallback(effects.listBrowse(dispatch, sort, filter), [sort, filter]);

  const render = useCallback((e: VirtualListViewCellRenderDetails<FilesListItemProps>) =>
    <FilesListItem {...e.item}/>, []);

  const prepareRows = (list: string[]) => {
    return list.map((key) => {
      const item = {
        isPremium,
        id: key && items[key] ? items[key].id : null,
        url: key && items[key] ? items[key].url : '',
        name: key && items[key] ? items[key].name : '',
        description: key && items[key] ? items[key].description : '',
        hash: key && items[key] ? items[key].hash : '',
        type: key && items[key] ? items[key].type : '',
        flag: key && items[key] ? items[key].flag : 0,
        size: key && items[key] ? items[key].size : 0,
        time: key && items[key] && (items[key].deleted || items[key].created),
        shared: key && items[key] && items[key].shared,
        parentKey: isSearch ? key && items[key] && items[key].parentKey : '',
        parentName: isSearch ? key && items[key] && items[key].parentName : '',
        files: key && items[key] ? items[key].files : 0,
        folders: key && items[key] ? items[key].folders : 0,
        downloads: key && items[key] ? items[key].downloads : 0,
        password: key && items[key] ? items[key].password : false,
        private: key && items[key] ? items[key].private : false,
        filedrop: key && items[key] ? items[key].filedrop : false,
        uploadStatus: key && items[key] ? items[key].uploadStatus : '',
        uploadHashed: key && items[key] ? items[key].uploadHashed : 0,
        uploadProgress: key && items[key] ? items[key].uploadProgress : 0,
      };
      return {
        // Virtual list item props
        key: item.id,
        isNavigable: true,
        height: tree
          ? FilesListItemTiny
          : isLargeRow
            ? FilesListItemLarge
            : FilesListItemSmall,
        // File list item props
        info: item,
        opts: {
          dispatch,
          dragging,
          selection,
          focusId,
          sort,
          filter,
          primaryColor,
          isTrash,
          isTree: tree,
          isPicker: picker,
          isMinimal: isMinimalRow,
          isUploader: uploader,
          isUploadFull: isUploadFull,
          hasPrivateSelection: props.hasPrivateSelection,
        },
      }
    });
  };

  const styleHeight = Styles.createScrollViewStyle({maxHeight, maxWidth, overflow: 'visible'}, false);

  const itemList = useMemo(() =>
    view ? prepareRows([
      ...Array.from(new Set(view.folders)),
      ...Array.from(new Set(!picker && !tree ? view.files : [])),
    ]) : []
  , [view, items, selection, dragging, focusId]);

  // Reset scroll position when changing folders
  const mount = useCallback((e?: VirtualListView<VirtualListViewItemInfo>) => {
    e && e.scrollToTop(false, 0);
  }, [id]);

  return view ? (
    <View
      style={[
        styles.root,
        hasGutter && styles.gutter,
        tree && styles.tree,
      ]}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={drop}>
      <VirtualListView
        skipRenderIfItemUnchanged
        animateChanges
        ref={mount}
        style={styleHeight}
        itemList={itemList}
        renderItem={render}
        scrollsToTop={true}
        padding={hasGutter ? 14 : 0}
        keyboardFocusScrollOffset={(maxHeight / 2) - 41}
        scrollIndicatorInsets={{top: 20, left: 0, right: 0, bottom: 0}}
        onItemFocused={!isRangeSelect && hasFocus ? focus : undefined}
        onItemSelected={tree
          ? browse
          : picker || uploader
            ? props.onPick
            : hasTouch
              ? selection.length === 0
                ? open
                : undefined
              : select
        }
      />
    </View>
  ) : null;
}, (prev, next) => isEqual(prev, next));

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    backgroundColor: Light.filesBackground,
  }),
  tree: Styles.createViewStyle({
    backgroundColor: Light.filesTreeBackground,
  }),
  gutter: Styles.createViewStyle({
    marginLeft: 14,
  }),
  hoverText: Styles.createTextStyle({
    color: Colors.neutral.white,
  }),
};

import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {Styles, View, Text, Button} from 'react-ult';
import {SafeAreaView} from 'controls/SafeArea';
import {cancelDownload} from 'features/filesystem';
import {Vectors, Colors} from 'features/themes';
import {isWeb} from 'features/platform';
import {selectors} from 'store/files';
import {state} from 'store';

export function FilesPreviewer() {
  const preview = useSelector(selectors.getPreview);
  const progress = useSelector(selectors.getPreviewProgress);
  const classes = {
    barProgress: [
      styles.barProgress,
      Styles.createViewStyle({width: isNaN(progress) ? 0 : progress}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'width 0.5s ease'}, false),
    ],
  };

  const dispatch = useDispatch();
  const cancel = useCallback(() => {
    dispatch(state.files.actions.previewClose());
    try {
      cancelDownload(preview.jobId);
    } catch (e) {}
  }, [preview.jobId]);

  return (
    <View disableTouchOpacityAnimation style={styles.root}>
      <SafeAreaView style={{flex: 1, backgroundColor: Colors.neutral.darker}}>
        <View style={styles.header}>
          <Text style={styles.title}>
            Loading Preview
          </Text>
          <Button style={styles.close} onPress={cancel} title="Cancel preview">
            <Svg viewBox="0 0 20 20" width={24} height={24}>
              <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.white}/>
            </Svg>
          </Button>
        </View>
        <View style={styles.wrapper}>
          <View style={styles.content}>
            <Text style={styles.message}>
              {preview.name}
            </Text>
            <View style={styles.progress}>
              <View style={styles.bar}>
                <View style={classes.barProgress}/>
              </View>
            </View>
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    position: 'absolute',
    flexDirection: 'row',
    backgroundColor: Colors.neutral.white,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  header: Styles.createViewStyle({
    flex: 0,
    height: 65,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  }),
  title: Styles.createTextStyle({
    marginLeft: 26,
    color: Colors.neutral.white,
    fontSize: 18,
    flex: 1,
  }),
  close: Styles.createButtonStyle({
    width: 65,
    height: 65,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  }),
  wrapper: Styles.createViewStyle({
    flex: 1,
    justifyContent: 'center',
  }),
  content: Styles.createViewStyle({
    flex: 0,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  }),
  message: Styles.createTextStyle({
    color: Colors.neutral.white,
    marginBottom: 14,
    textAlign: 'center',
  }),
  progress: Styles.createViewStyle({
    flex: 0,
    width: 100,
  }),
  bar: Styles.createViewStyle({
    position: 'relative',
    flex: 0,
    height: 6,
    borderRadius: 3,
    backgroundColor: Colors.neutral.mid,
  }),
  barProgress: Styles.createViewStyle({
    position: 'absolute',
    flex: 0,
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: Colors.primary.brand,
  }),
};

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function random(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function delay(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function bytesize(bytes: number, decimals: number = 2) {
  if (bytes === 0) return '0 B';
  const scale = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const dm = decimals <= 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(scale));
  if (!sizes[i]) return '0 B';
  return (bytes / Math.pow(scale, i)).toFixed(dm) + ' ' + sizes[i];
}

export function ekeyToPercentile(ekey: string, digits = 2, offset = 0) {
  const value = ekey.substr(0 - (digits + Math.abs(offset)), digits);
  return 100 * parseInt(value, 36) / Math.pow(36, value.length);
}

export function getContrast(hexcolor: string) {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0,2), 16);
  const g = parseInt(hexcolor.substr(2,2), 16);
  const b = parseInt(hexcolor.substr(4,2), 16);
  const y = ((r*299)+(g*587)+(b*114))/1000;
  return (y >= 128) ? 'black' : 'white';
}

export function luminance(hexcolor: string, luminosity: number) {
  hexcolor = String(hexcolor).replace(/[^0-9a-f]/gi, '');
  if (hexcolor.length < 6) {
    hexcolor = hexcolor[0]
      + hexcolor[0]
      + hexcolor[1]
      + hexcolor[1]
      + hexcolor[2]
      + hexcolor[2];
  }
  luminosity = luminosity || 0;
  let rgb = '#', c: any, i: number;
  for (i = 0; i < 3; i++) {
    c = parseInt(hexcolor.substr(i*2,2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * luminosity)), 255)).toString(16);
    rgb += ('00'+c).substr(c.length);
  }
  return rgb;
}

export function getBit(value: number, bit: number) {
  return (value >> bit) % 2;
}

export function testBit(value: number, bit: number) {
  return (value >> bit) % 2 !== 0;
}

export function toArray(list: FileList | File[]): File[] {
  return Array.prototype.slice.call(list || [], 0);
}

export function uriToFile(uri: string, name: string): Promise<File> {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => res(new File(xhr.response, name));
    xhr.onerror = (e) => rej(e);
    xhr.open('GET', uri);
    xhr.send();
  });
}

export function shuffle(array: unknown[]) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function throttle(
  func: Function,
  wait: number,
  opts?: {leading: boolean, trailing: boolean},
) {
  const options = opts || {leading: false, trailing: false};
  let context: any, args: any, result: any;
  let timeout: any = null;
  let previous = 0;

  var later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };

  return function(this: any) {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

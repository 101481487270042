import React, {ReactNode} from 'react';
import {ComponentBase} from 'resub';
import {Styles, View, Text, Button, Types, CommonProps} from 'react-ult';
import {Light, Fonts, FontSizes} from 'features/themes';
import {Keys} from 'globals/keys';

/*
  # TODO
  - fix arrow keys focusing dividers (tab works)
  - fix focus trap (focusing underneath menu)
*/

export interface MenuItem {
  text: string;
  command: string;
  icon?: ReactNode;
  disabled?: boolean;
}

export interface MenuProps extends CommonProps {
  menuItems: MenuItem[];
  menuButtonStyles?: Types.StyleRuleSet<Types.ButtonStyle>;
  menuTextStyles?: Types.StyleRuleSet<Types.TextStyle>;
  focusFirst?: boolean;
  multiMode?: boolean;
  onSelectItem: (command: string) => void;
}

interface MenuState {
  hoverCommand?: string;
  focusedIndex: number;
}

export class Menu extends ComponentBase<MenuProps, MenuState> {
  private _mountedRefsMap: {[key: string]: any} = {};

  protected _buildState(_props: MenuProps, initialBuild: boolean): Partial<MenuState> {
    if (initialBuild) {
      return {
        hoverCommand: undefined,
        focusedIndex: -1,
      };
    }
    return {};
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.focusFirst) {
      this.focusFirt();
    }
  }

  componentDidUpdate(prevProps: MenuProps, prevState: MenuState, prevContext: any) {
    super.componentDidUpdate(prevProps, prevState, prevContext);
    if (this.state.focusedIndex !== prevState.focusedIndex) {
      this.focusItem(this.state.focusedIndex);
    }
  }

  focusFirt() {
    this.focusItem(0);
  }

  focusLast() {
    this.focusItem(this.props.menuItems.length - 1);
  }

  focusItem(index = 0) {
    if (index < 0 || index > this.props.menuItems.length - 1)
      return;
    if (this.state.focusedIndex === index) {
      const ref = this._mountedRefsMap[index];
      if (ref) {
        ref.requestFocus();
      }
    } else {
      this.setState({focusedIndex: index});
    }
  }

  render() {
    const menuItems = this.props.menuItems.map((item, index) => {
      if (index === 0 && item.command === 'header') {
        return (
          <View key={'header'} style={[
            _styles.header,
            this.props.multiMode && _styles.headerMulti
          ]}>
            <Text style={[
              _styles.headerText,
              this.props.multiMode && _styles.headerTextMulti
            ]}>
              {item.text}
            </Text>
          </View>
        );
      }

      if (item.text === '-') {
        return (
          <View key={`divider-${index}`} style={_styles.divider}/>
        );
      }

      const buttonStyles = [_styles.menuItemContainer];
      if (this.props.menuButtonStyles) {
        buttonStyles.push(this.props.menuButtonStyles);
      }

      const textStyles = [_styles.menuItemText];
      if (this.props.menuTextStyles) {
        textStyles.push(this.props.menuTextStyles);
      }

      if (item.command === this.state.hoverCommand && !item.disabled) {
        buttonStyles.push(_styles.menuItemHover);
      }

      const accessibilityLabel = item.text;
      if (item.disabled) {
        textStyles.push(_styles.disabledText);
      }

      const iconStyles = [_styles.menuItemIcon];
      return (
        <Button
          ref={(e: any) => this._mountedRefsMap[index] = e}
          key={item.command}
          style={buttonStyles}
          onPress={e => this._onClickItem(e, item)}
          onHoverStart={() => this._onMouseEnter(item)}
          onHoverEnd={() => this._onMouseLeave(item)}
          disabled={item.disabled}
          tabIndex={index + 1}
          accessibilityTraits={Types.AccessibilityTrait.MenuItem}
          accessibilityLabel={accessibilityLabel}>
          {item.icon &&
            <View style={iconStyles}>
              {item.icon}
            </View>
          }
          <Text style={textStyles}>
            {item.text}
          </Text>
        </Button>
      );
    });

    return (
      <View
        restrictFocusWithin
        disableTouchOpacityAnimation
        accessibilityTraits={Types.AccessibilityTrait.Menu}
        onKeyPress={this._onKeyPress}
        tabIndex={-1}
        style={[
          _styles.menuContainer,
          this.props.multiMode && _styles.menuMulti,
        ]}>
        {menuItems}
      </View>
    );
  }

  private _onKeyPress = (event: Types.KeyboardEvent) => {
    let currentFocusedIndex = this.state.focusedIndex;
    if (event.keyCode === Keys.UpArrow || event.keyCode === Keys.LeftArrow)
      currentFocusedIndex = currentFocusedIndex - 1;
    if (event.keyCode === Keys.DownArrow || event.keyCode === Keys.RightArrow)
      currentFocusedIndex = currentFocusedIndex + 1;
    if (currentFocusedIndex < 0 || currentFocusedIndex >= this.props.menuItems.length)
      return;
    this.setState({focusedIndex: currentFocusedIndex});
  };

  private _onClickItem(e: Types.SyntheticEvent, item: MenuItem) {
    e.stopPropagation();
    if (!item.disabled && this.props.onSelectItem) {
      this.props.onSelectItem(item.command);
    }
  }

  private _onMouseEnter(item: MenuItem) {
    if (!item.disabled && item.command !== this.state.hoverCommand) {
      this.setState({hoverCommand: item.command});
    }
  }

  private _onMouseLeave(item: MenuItem) {
    if (!item.disabled && item.command === this.state.hoverCommand) {
      this.setState({hoverCommand: undefined});
    }
  }
}

const _styles = {
  menuContainer: Styles.createViewStyle({
    width: 180,
    paddingBottom: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Light.menuBorder,
    backgroundColor: Light.menuBackground,
  }),
  menuMulti: Styles.createViewStyle({
    borderColor: Light.menuHeaderMultiBorder,
  }),
  menuItemContainer: Styles.createButtonStyle({
    minHeight: 36,
    paddingVertical: 4,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
  menuItemHover: Styles.createButtonStyle({
    backgroundColor: Light.menuItemHover,
  }),
  menuItemText: Styles.createTextStyle({
    flex: 1,
    font: Fonts.displayRegular,
    fontSize: FontSizes.size12,
    color: Light.menuText,
  }),
  menuItemIcon: Styles.createViewStyle({
    flex: 0,
    width: 20,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  disabledText: Styles.createTextStyle({
    color: Light.menuTextDisabled,
  }),
  divider: Styles.createViewStyle({
    height: 1,
    marginVertical: 4,
    backgroundColor: Light.menuBorder,
  }),
  header: Styles.createViewStyle({
    height: 28,
    borderBottomWidth: 1,
    borderColor: Light.menuHeaderBorder,
    backgroundColor: Light.menuHeaderBackground,
    marginBottom: 4,
  }),
  headerText: Styles.createTextStyle({
    color: Light.menuHeaderText,
    textAlign: 'center',
    fontSize: 11,
    lineHeight: 28,
    paddingHorizontal: 16,
  }),
  headerMulti: Styles.createViewStyle({
    borderColor: Light.menuHeaderMultiBorder,
    backgroundColor: Light.menuHeaderMultiBackground,
  }),
  headerTextMulti: Styles.createTextStyle({
    color: Light.menuHeaderMultiText,
  }),
};

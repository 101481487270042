import {t} from '@lingui/macro';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Fragment, Styles, View, Button, Text, ScrollView} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useModalShare} from 'hooks/useModalShare';
import {FilesPathItem} from 'view/FilesPathItem';
import {Light, Vectors, Colors} from 'features/themes';
import {isTouch, isNative} from 'features/platform';
import {selectors} from 'store/files';

import * as filesEffects from 'store/files/effects';

export interface Props {
  id: string;
  searchQuery?: string;
  isMinimal?: boolean;
}

export function FilesPath(props: Props) {
  const folder = useSelector(selectors.getItem(props.id));
  const hasTouch = isTouch();
  const isTrash = props.id === 'trash';
  const isRecent = props.id === 'recent';
  const isFollowing = props.id === 'following';
  const isSearch = !!props.searchQuery;
  const isSpecial = isTrash || isRecent || isFollowing || isSearch;
  const iconBox = isFollowing ? '0 0 24 24' : '0 0 20 20';
  const iconSize = hasTouch ? 18 : 16;
  const iconRoot = Vectors.iconHome;
  const hasDepth = folder && folder.hierarchy && folder.hierarchy.length;
  const root = isTrash
    ? {id: 'trash', name: t`Trash`}
    : isFollowing
      ? {id: 'following', name: t`Following files lets you get updates and collaborate with friends.`}
      : isRecent
        ? {id: 'recent', name: t`Recent Files`}
        : {id: 'myfiles', name: t`My Files`};
  const classes = {
    outer: [
      styles.outer,
      hasTouch && styles.outerLarge,
    ],
    root: [
      hasTouch ? styles.rootLarge : styles.root,
      !isNative() && (hasTouch
        ? Styles.createScrollViewStyle({['display' as any]: 'flex'}, false)
        : Styles.createScrollViewStyle({['overflowX' as any]: 'hidden'}, false)),
    ],
    paths: [
      styles.paths,
      hasTouch && styles.pathsLarge,
    ],
    text: [
      styles.myfiles,
      hasTouch && styles.myfilesLarge,
      isSpecial && styles.specialText,
    ],
    iconHome: [
      styles.iconHome,
      hasDepth && styles.iconHomeDepth,
    ],
    buttonHome: [
      styles.buttonHome,
      hasTouch && styles.buttonHomeTouch,
    ],
    buttonShare: [
      styles.buttonShare,
      hasTouch && styles.buttonShareTouch,
    ],
  };

  const url = folder ? folder.url : undefined;
  const name = folder ? folder.name : undefined;

  const dispatch = useDispatch();
  const goHome = useCallback(filesEffects.navigate(dispatch, root.id), [root.id]);
  const [showShare] = useModalShare(dispatch, [props.id], name, url);

  return (
    <View style={classes.outer}>
      {isSearch &&
        <Text style={classes.text}>
          {t`Showing results for “${props.searchQuery}”`}
        </Text>
      }
      {isRecent &&
        <Text style={classes.text}>
          {t`Recent files`}
        </Text>
      }
      {isFollowing &&
        <Text style={classes.text}>
          {t`Shared with me`}
        </Text>
      }
      {isTrash &&
        <Text style={classes.text}>
          {t`Trash folder`}
        </Text>
      }
      <ScrollView horizontal style={classes.root}>
        {!isSearch && !isSpecial &&
          <Button
            style={classes.buttonHome}
            onPress={goHome}
            title={t`Go to ${root.name}`}>
            <Svg style={classes.iconHome} viewBox={iconBox} width={iconSize} height={iconSize}>
              <SvgPath d={iconRoot} fillColor={Colors.neutral.mid} fillOpacity={0.5}/>
            </Svg>
            {!hasDepth &&
              <Text style={classes.text}>
                {root.name}
              </Text>
            }
          </Button>
        }
        {!isSearch && folder && folder.hierarchy &&
          <View style={classes.paths}>
            {[[root.id, root.name], ...folder.hierarchy].map((item, i, paths) => {
              const first = i === 0;
              const last = i === paths.length - 1;
              return (
                <Fragment key={i}>
                  {!first &&
                    <FilesPathItem active={last} id={item[0]} name={item[1]}/>
                  }
                  {!last &&
                    <Svg style={styles.divider} viewBox="0 0 12 18" width={8} height={12}>
                      <SvgPath d={Vectors.iconRight} fillColor={Colors.neutral.mid} fillOpacity={0.5}/>
                    </Svg>
                  }
                  {last && !first &&
                    <Button
                      title={t`Share this folder`}
                      style={classes.buttonShare}
                      onPress={showShare}>
                      <Text style={styles.textShare}>
                        {t`Share this folder`}
                      </Text>
                    </Button>
                  }
                </Fragment>
              )
            })}
          </View>
        }
      </ScrollView>
    </View>
  );
}

export const styles = {
  outer: Styles.createViewStyle({
    flexDirection: 'row',
    flex: 0,
    height: 42,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Light.breadcrumbsBorder,
    backgroundColor: Light.breadcrumbsBackground,
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
  }),
  outerLarge: Styles.createViewStyle({
    height: 54,
  }),
  root: Styles.createScrollViewStyle({
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',
    height: 42,
  }),
  rootLarge: Styles.createScrollViewStyle({
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',
    height: 54,
  }),
  myfilesLarge: Styles.createTextStyle({
    fontSize: 14,
  }),
  paths: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row',
    alignItems: 'center',
    height: 42,
  }),
  pathsLarge: Styles.createViewStyle({
    height: 54,
    marginBottom: 0,
  }), 
  myfiles: Styles.createTextStyle({
    fontSize: 12,
    color: Light.breadcrumbsText,
    paddingHorizontal: 8,
  }),
  specialText: Styles.createTextStyle({
    marginLeft: 12,
  }),
  divider: Styles.createViewStyle({
    marginTop: 4,
  }),
  iconHome: Styles.createViewStyle({
    marginLeft: 12,
  }),
  iconHomeDepth: Styles.createViewStyle({
    marginRight: 12,
  }),
  buttonHome: Styles.createButtonStyle({
    height: 42,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  }),
  buttonHomeTouch: Styles.createButtonStyle({
    height: 54,
  }),
  textShare: Styles.createTextStyle({
    color: Colors.neutral.mid,
    fontSize: 11,
  }),
  buttonShare: Styles.createButtonStyle({
    paddingHorizontal: 12,
    paddingVertical: 4,
    marginRight: 14,
    marginBottom: 2,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Light.breadcrumbsShareBorder,
    backgroundColor: Light.breadcrumbsShareBackground,
    shadowOffset: {width: 0, height: 1},
    shadowColor: 'rgba(0,0,0,0.2)',
  }),
  buttonShareTouch: Styles.createButtonStyle({
    paddingVertical: 6,
  }),
};

import {t} from '@lingui/macro';
import React, {useRef, useState, useEffect} from 'react';
import isEqual from 'react-fast-compare';
import {Styles, View, GestureView, Text, Button} from 'react-ult';
import {VirtualListViewItemInfo} from 'react-ult-ext-virtuallistview';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useFileItem, FileItem, FileListOptions} from 'hooks/useFileItem';
import {useMenuFiles} from 'hooks/useMenuFiles';
import {FilesItemIcon} from 'view/FilesThumbnail';
import {Light, Vectors, Colors} from 'features/themes';
import {isWeb, isTouch, isNative} from 'features/platform';
import {getTimeSince} from 'features/mediafire';

export const FilesListItemTiny = 26;
export const FilesListItemSmall = 42;
export const FilesListItemLarge = 54;

export interface FilesListItemProps extends VirtualListViewItemInfo {
  info: FileItem,
  opts: FileListOptions,
}

export const FilesListItem = React.memo(function FilesListItem(props: FilesListItemProps) {
  const {isTree, isTrash, isPicker, isUploader, isUploadFull} = props.opts;
  const {id, url, name, type, parentKey, parentName, uploadStatus} = props.info;
  const item = useFileItem(props.info, props.opts);
  const anchor = useRef(null);
  const [showMenuFiles] = useMenuFiles(item.contextItems, item.contextItem, item.contextOptions, props.opts.dispatch, anchor.current);

  // Workaround to populate the anchor ref
  // otherwise context menu won't appear without selecting an item
  const [_refDefined, setRefDefined] = useState(false);
  useEffect(() => {
    setRefDefined(true);
  }, []);

  // General list item props
  const isRowLarge = props.height === FilesListItemLarge;
  const isRowTiny = props.height === FilesListItemTiny;

  // File list options
  const isNormal = !isUploader && !isPicker;
  const isNormalExceptTree = !isTree && isNormal;

  // File item info
  const isPremium = props.info.isPremium;
  const isPrivate = props.info.private;
  const isFileDrop = props.info.filedrop;
  const isProtected = props.info.password;
  const isEmpty = props.info.type === 'empty';

  // UI state
  const hasTouch = isTouch();
  const hasNative = isNative();
  const hasHover = !isUploader && !hasTouch;
  const hasDragDrop = isNormal && item.isOwned;
  const colorIcon = item.colorSecondary;
  const classes = {
    root: [
      isRowTiny ? styles.rootTiny : isRowLarge ? styles.rootLarge : styles.root,
      item.isSelected ? styles.selected : !isTree ? styles.normal : undefined,
      item.isFocused && (item.isSelected ? styles.focusedAndSelected : styles.focused),
      item.isDropping && styles.dropping,
      item.stateHover && (item.isSelected ? styles.hoveringSelected : isTree ? styles.hoveringTree : styles.hovering),
      item.isDragging && (isTree ? styles.draggingTree : styles.dragging),
    ],
    inner: isTree
      ? styles.innerTree
      : styles.inner,
    thumbnail: !isUploader
      ? isTree
        ? styles.thumbnailTree
        : styles.thumbnail
      : styles.thumbnailUpload,
    content: [
      styles.content,
      props.opts.isMinimal && styles.contentMinimal,
    ],
    overview: [
      styles.overview,
      item.isSelected && styles.selectedText,
    ],
    titleText: [
      styles.titleText,
      item.stateTitle && item.styleColorPrimary,
    ],
    timestamp: [
      styles.timestamp,
      isRowLarge && styles.largeText,
      item.isSelected && styles.selectedText,
    ],
    download: [
      styles.download,
      isRowLarge && styles.largeText,
      item.isSelected && styles.selectedText,
    ],
    uploadStatus: [
      styles.uploadStatus,
      isUploadFull && styles.uploadStatusFull,
      !isUploadFull && item.hasUploadError && styles.uploadStatusError,
    ],
    menu: [
      styles.menu,
      isRowLarge && styles.menuLarge,
    ], 
    selectIcon: [
      styles.selectIcon,
      isRowLarge && styles.selectIconLarge,
    ],
    anchor: [
      styles.anchor,
      Styles.createViewStyle({left: item.stateMenuPosX, top: item.stateMenuPosY}, false),
    ],
    bar: [
      styles.bar,
      item.hasUploadError && styles.barError,
    ],
    hashed: [
      styles.hashed,
      Styles.createViewStyle({width: isNaN(item.percentHashed) ? 0 : item.percentHashed}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'background-color 0.2s, width 0.5s ease'}, false),
    ],
    uploaded: [
      styles.uploaded,
      id && id.length === 9 && styles.downloaded,
      Styles.createViewStyle({width: isNaN(item.percentUploaded) ? 0 : item.percentUploaded}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'background-color 0.2s, width 0.5s ease'}, false),
    ],
  };

  const tapOuter = hasTouch && !isPicker ? item.hasSelection ? item.onSelect : item.open : undefined;
  const tapInner = hasTouch && !isTree && !isUploader && !isPicker && !item.hasSelection ? item.onSelect : undefined;

  return (
    <View
      style={classes.root}
      onDrop={hasDragDrop && item.isFolder ? item.onDrop : undefined}
      onDragOver={hasDragDrop && item.isFolder ? item.onDragOver : undefined}
      onDragEnter={hasDragDrop && item.isFolder ? item.onDragEnter : undefined}
      onDragLeave={hasDragDrop && item.isFolder ? item.onDragLeave : undefined}
      onDragStart={hasDragDrop ? item.onDragStart : undefined}
      onDragEnd={hasDragDrop ? item.onDragEnd : undefined}
      onMouseEnter={hasHover ? item.onMouseEnter : undefined}
      onMouseLeave={hasHover ? item.onMouseLeave : undefined}>
      {!isEmpty &&
        <GestureView
          style={classes.inner}
          onContextMenu={isNormal ? item.showMenuFiles : undefined}
          onDoubleClick={isNormal ? item.open : undefined}
          onTap={tapOuter}>
          <View ref={item.anchor} style={classes.anchor}/>
          {hasNative &&
            <GestureView style={classes.thumbnail} onTap={tapInner}>
              <React.Fragment>
                {item.isSelected &&
                  <Svg style={classes.selectIcon} viewBox={'0 0 20 20'} width={30} height={30}>
                    <SvgPath d={Vectors.iconCheck} fillColor={Colors.neutral.black} fillOpacity={0.5}/>
                  </Svg>
                }
                {!item.isSelected &&
                  <FilesItemIcon
                    id={id}
                    name={name}
                    type={type}
                    src={item.textPhotoURI}
                    large={isRowLarge}
                    picker={isPicker}
                    tree={isTree}
                    expandable={item.isExpandable}
                    primaryColor={item.colorPrimary}
                  />
                }
              </React.Fragment>
            </GestureView>
          }
          {!hasNative &&
            <View style={classes.thumbnail} onPress={tapInner}>
              <React.Fragment>
                {item.isSelected &&
                  <Svg style={classes.selectIcon} viewBox={'0 0 20 20'} width={30} height={30}>
                    <SvgPath d={Vectors.iconCheck} fillColor={Colors.neutral.black} fillOpacity={0.5}/>
                  </Svg>
                }
                {!item.isSelected &&
                  <FilesItemIcon
                    id={id}
                    name={name}
                    type={type}
                    src={item.textPhotoURI}
                    large={isRowLarge}
                    picker={isPicker}
                    tree={isTree}
                    expandable={item.isExpandable}
                    primaryColor={item.colorPrimary}
                  />
                }
              </React.Fragment>
            </View>
          }
          <View style={classes.content}>
            {isTree || isPicker || isUploader || hasTouch || isTrash
              ? <Text
                  style={isTree ? styles.titleTree : styles.titleSimple}
                  numberOfLines={1}
                  ellipsizeMode={'middle'}>
                  {name}
                </Text>
              : <Button
                  style={styles.title}
                  onPress={!hasTouch ? item.open : undefined}
                  onHoverStart={!hasTouch ? item.onHoverStart : undefined}
                  onHoverEnd={!hasTouch ? item.onHoverEnd : undefined}
                  title={item.textOpen}>
                  <Text
                    style={classes.titleText}
                    numberOfLines={1}
                    ellipsizeMode="middle">
                    {name}
                  </Text>
                </Button>
            }
            {!isTree && !isPicker && !item.isShared &&
              <Text style={classes.overview}>
                {isTrash && props.opts.isMinimal
                  ? t`Deleted ${getTimeSince(item.textTimestamp)} ago`
                  : item.textDetails}
              </Text>
            }
          </View>
          <View style={styles.details}>
            {isNormalExceptTree && item.isFlagged &&
              <Button style={styles.button} onPress={item.showFlags} title={item.textFlagged}>
                <Svg viewBox={'0 0 20 20'} width={14} height={14}>
                  <SvgPath
                    d={Vectors.iconWarning}
                    fillColor={Colors.secondary.redOrange}
                  />
                </Svg>
              </Button>
            }
            {isNormalExceptTree && item.isOwned && isFileDrop && !isTrash &&
              <Button
                title={t`This folder is a FileDrop`}
                style={styles.button}
                onPress={isPremium ? item.showFileDrop : item.showUpsellFileDrop}>
                <Svg viewBox={'0 0 24 14'} width={14} height={14}>
                  <SvgPath
                    d={Vectors.iconFiledrop}
                    fillColor={colorIcon}
                  />
                </Svg>
              </Button>
            }
            {isNormalExceptTree && item.isOwned && isProtected && !isTrash &&
              <Button
                style={styles.button}
                onPress={isPremium ? item.showPassword : item.showUpsellPassword}
                title={t`This item is password protected`}>
                <Svg viewBox={'0 0 20 24'} width={16} height={16}>
                  <SvgPath d={Vectors.iconPassword} fillColor={colorIcon}/>
                </Svg>
              </Button>
            }
            {isNormalExceptTree && !isPrivate && !isTrash &&
              <Button style={styles.button} onPress={item.copyLink} title={t`Copy share link`}>
                <Svg viewBox={'0 0 20 8'} width={14} height={12}>
                  <SvgPath d={Vectors.iconPublic} fillColor={colorIcon}/>
                </Svg>
              </Button>
            }
            {isWeb() && parentKey && isNormalExceptTree && !isTrash &&
              <Button style={styles.buttonSearch} onPress={item.onBack} title={t`Go to parent`}>
                <Svg viewBox={'0 0 24 18'} width={18} height={18}>
                  <SvgPath
                    d={Vectors.iconFolder}
                    fillColor={colorIcon}
                  />
                </Svg>
                {false && !props.opts.isMinimal &&
                  <Text style={classes.download} numberOfLines={1} ellipsizeMode="middle">
                    {parentName}
                  </Text>
                }
              </Button>
            }
            {isNormalExceptTree && !props.opts.isMinimal &&
              <Text style={classes.timestamp}>
                {!isTrash ? item.textTimestamp : t`Deleted ${getTimeSince(item.textTimestamp)} ago`}
              </Text>
            }
            {isNormalExceptTree && !item.isOwned && !isTrash && false &&
              <Button style={styles.button} onPress={item.onDownload} title={t`Download file`}>
                <Svg viewBox={'0 0 20 20'} width={18} height={18}>
                  <SvgPath d={Vectors.iconDownload} fillColor={colorIcon}/>
                </Svg>
                {!props.opts.isMinimal && <Text style={classes.download}>DOWNLOAD</Text>}
              </Button>
            }
            {isUploader && false &&
              <Button
                style={styles.uploadCancel}
                title={t`Cancel file upload`}>
                <Svg viewBox="0 0 14 14" width={12} height={12}>
                  <SvgPath d={Vectors.iconClose} fillColor={colorIcon}/>
                </Svg>
              </Button>
            }
            {isUploader && !!url && !item.hasUploadError && 
              <View style={styles.uploadActions}>
                <Button
                  style={styles.uploadAction}
                  onPress={item.showShare}
                  onHoverStart={undefined}
                  onHoverEnd={undefined}
                  title={t`Share file`}>
                  <Text style={styles.uploadActionText}>
                    {t`Share`}
                  </Text>
                </Button>
                <Button
                  style={styles.uploadAction}
                  onPress={item.copyLink}
                  onHoverStart={undefined}
                  onHoverEnd={undefined}
                  title={t`Copy link`}>
                  <Text style={styles.uploadActionText}>
                    {t`Copy Link`}
                  </Text>
                </Button>
              </View>
            }
            {isUploader && uploadStatus &&
              <View title={uploadStatus}>
                <Text style={classes.uploadStatus} numberOfLines={1} ellipsizeMode="tail">
                  {uploadStatus}
                </Text>
              </View>
            }
            {isUploader && isUploadFull &&
              <View style={classes.bar}>
                {!item.hasUploadError &&
                  <React.Fragment>
                    <View style={classes.hashed} ariaValueNow={item.percentHashed}></View>
                    <View style={classes.uploaded} ariaValueNow={item.percentUploaded}></View>
                  </React.Fragment>
                }
              </View>
            }
            {isNormalExceptTree &&
              <Button
                ref={anchor}
                style={classes.menu}
                onPress={showMenuFiles}
                title={t`Open context menu`}>
                <Svg viewBox={'0 0 6 16'} width={6} height={12}>
                  <SvgPath
                    d={Vectors.iconMore}
                    fillColor={colorIcon}
                  />
                </Svg>
              </Button>
            }
            {item.isExpandable &&
              <Button
                style={styles.expand}
                onPress={item.onBrowse}
                title={t`Browse ${name}`}>
                <Svg viewBox="0 0 24 24" width={24} height={24}>
                  <SvgPath
                    d={Vectors.iconTreeExpand}
                    fillColor={Colors.neutral.mid}
                    fillOpacity={item.isExpandable ? 0.7 : 0}
                  />
                </Svg>
              </Button>
            }
          </View>
        </GestureView>
      }
    </View>
  );
}, (prev, next) => isEqual(prev, next));

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    height: FilesListItemSmall,
    flexDirection: 'column',
  }),
  rootTiny: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'column',
    height: FilesListItemTiny,
  }),
  rootLarge: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    height: FilesListItemLarge,
  }),
  normal: Styles.createViewStyle({
    backgroundColor: Light.listItemNormalBackground,
    borderColor: Light.listItemNormalBorder,
    borderBottomWidth: 1,
  }),
  children: Styles.createViewStyle({
    flexDirection: 'row',
    marginLeft: 10,
    flex: 1,
  }),
  selectedText: Styles.createTextStyle({
    color: Light.listItemSelectText,
  }),
  selectedHoverText: Styles.createTextStyle({
    color: Colors.neutral.dark,
  }),
  selected: Styles.createViewStyle({
    backgroundColor: Light.listItemSelectBackground,
    borderColor: Light.listItemSelectBorder,
    borderBottomWidth: 1,
  }),
  dragging: Styles.createViewStyle({
    opacity: 0.5,
    borderColor: Light.listItemNormalBorder,
    backgroundColor: Light.listItemDraggingBackground,
  }),
  draggingTree: Styles.createViewStyle({
    opacity: 0.5,
  }),
  dropping: Styles.createViewStyle({
    opacity: 1,
    backgroundColor: Light.listItemDropBackground,
  }),
  hovering: Styles.createViewStyle({
    backgroundColor: Light.listItemHoverBackground,
  }),
  hoveringTree: Styles.createViewStyle({
    backgroundColor: Light.listItemHoverTreeBackground,
  }),
  hoveringSelected: Styles.createViewStyle({
    backgroundColor: Light.listItemHoverSelectedBackground,
  }),
  focused: Styles.createViewStyle({
    backgroundColor: Light.listItemFocusBackground,
  }),
  focusedAndSelected: Styles.createViewStyle({
    backgroundColor: Light.listItemFocusSelectedBackground,
  }),
  largeText: Styles.createTextStyle({
    lineHeight: FilesListItemLarge,
  }),
  inner: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
  }),
  innerTree: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
  }),
  thumbnail: Styles.createViewStyle({
    flexDirection: 'row',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
  thumbnailTree: Styles.createViewStyle({
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 6,
  }),
  thumbnailUpload: Styles.createViewStyle({
    flexDirection: 'row',
    justifyContent: 'center',
  }),
  selectIcon: Styles.createViewStyle({
    marginHorizontal: 13,
    marginVertical: 6,
  }),
  selectIconLarge: Styles.createViewStyle({
    marginVertical: 12,
  }),
  content: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  contentMinimal: Styles.createViewStyle({
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  }),
  details: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  title: Styles.createButtonStyle({
    marginRight: 8,
    flex: 0,
  }),
  titleSimple: Styles.createTextStyle({
    flexShrink: 1,
    marginRight: 8,
    lineHeight: 16,
    fontSize: 12,
    color: Light.listItemTitle,
  }),
  titleTree: Styles.createTextStyle({
    flexShrink: 1,
    fontSize: 12,
    lineHeight: FilesListItemTiny - 1,
    color: Light.filesTreeText,
  }),
  titleText: Styles.createTextStyle({
    fontSize: 12,
    color: Light.listItemTitle,
    flexShrink: 1,
  }),
  overview: Styles.createTextStyle({
    fontSize: 11,
    marginRight: 8,
    color: Colors.neutral.light,
  }),
  timestamp: Styles.createTextStyle({
    flex: 1,
    fontSize: 11,
    color: Colors.neutral.mid,
    textAlign: 'center',
  }),
  download: Styles.createTextStyle({
    flex: 1,
    fontSize: 11,
    marginLeft: 8,
    color: Colors.neutral.light,
  }),
  button: Styles.createButtonStyle({
    width: 36,
    height: 42,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  buttonSearch: Styles.createButtonStyle({
    height: 42,
    paddingHorizontal: 8,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  menu: Styles.createButtonStyle({
    width: 42,
    height: 42,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  menuLarge: Styles.createButtonStyle({
    marginRight: 6,
  }),
  expand: Styles.createButtonStyle({
    margin: 0,
    paddingVertical: 0,
    paddingHorizontal: 6,
  }),
  visible: Styles.createViewStyle({
    opacity: 1,
  }),
  check: Styles.createViewStyle({
    position: 'absolute',
    left: 8,
    top: 8,
  }),
  anchor: Styles.createViewStyle({
    top: 0,
    left: 0,
    width: 1,
    height: 1,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0)',
  }),
  // Uploading & Downloading
  uploadCancel: Styles.createButtonStyle({
    width: 48,
    height: 46,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  uploadActions: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
  }),
  uploadAction: Styles.createButtonStyle({
    height: 42,
    paddingHorizontal: 6,
  }),
  uploadActionText: Styles.createTextStyle({
    fontSize: 12,
    marginTop: 3,
    color: Colors.primary.brand,
  }),
  bar: Styles.createViewStyle({
    flex: 0,
    height: 4,
    width: 100,
    marginLeft: 14,
    marginRight: 6,
    borderRadius: 3,
    backgroundColor: Colors.neutral.lighter,
  }),
  barError: Styles.createViewStyle({
    backgroundColor: Colors.secondary.redOrange,
  }),
  hashed: Styles.createViewStyle({
    flex: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: Colors.neutral.light,
  }),
  uploaded: Styles.createViewStyle({
    flex: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: Colors.primary.brand,
  }),
  downloaded: Styles.createViewStyle({
    backgroundColor: '#33CC66',
  }),
  uploadStatus: Styles.createTextStyle({
    color: Light.listItemTitle,
    fontSize: 12,
    marginLeft: 12,
    width: 70,
    textAlign: 'right',
  }),
  uploadStatusFull: Styles.createTextStyle({
    width: 120,
  }),
  uploadStatusError: Styles.createTextStyle({
    color: Colors.secondary.redOrange,
  }),
};

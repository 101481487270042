import React, {ReactNode} from 'react';
import {Types, Styles, View, Text, Component, Stateless} from 'react-ult';
import {Light, Fonts, FontSizes} from 'features/themes';
import HoverButton from 'controls/HoverButton';

export interface IconButtonProps {
  text?: string;
  mode?: 'primary' | 'cancel' | 'destructive';
  icon?: ReactNode;
  title?: string;
  disabled?: boolean;
  onPress?: (e: Types.SyntheticEvent) => void;
  iconStyle?: Types.StyleRuleSetRecursive<Types.ViewStyle>;
  textStyle?: Types.StyleRuleSetRecursive<Types.TextStyle>;
  textHoverStyle?: Types.StyleRuleSetRecursive<Types.TextStyle>;
  buttonStyle?: Types.StyleRuleSetRecursive<Types.ViewStyle>;
  buttonHoverStyle?: Types.StyleRuleSetRecursive<Types.ViewStyle>;
}

export class IconButton extends Component<IconButtonProps, Stateless> {
  render(): JSX.Element | null {
    return (
      <HoverButton
        title={this.props.title}
        disabled={this.props.disabled}
        onPress={this.props.onPress}
        onRenderChild={this._onRenderButton}
      />
    );
  }

  private _onRenderButton = (isHovering: boolean) => {
    const buttonStyles = [_styles.button, this.props.buttonStyle];
    const iconStyles = [_styles.icon, this.props.iconStyle];
    const textStyles = [_styles.text, this.props.textStyle];

    switch (this.props.mode) {
      case 'primary':
        textStyles.push(_styles.textPrimary);
        buttonStyles.push(_styles.buttonPrimary);
        if (isHovering)
          buttonStyles.push(_styles.buttonPrimaryHover);
        break;
      case 'cancel':
        textStyles.push(_styles.textCancel);
        buttonStyles.push(_styles.buttonCancel);
        if (isHovering)
          buttonStyles.push(_styles.buttonCancelHover);
        break;
      case 'destructive':
        textStyles.push(_styles.textDestructive);
        buttonStyles.push(_styles.buttonDestructive);
        if (isHovering)
          buttonStyles.push(_styles.buttonDestructiveHover);
        break;
    }

    if (isHovering) {
      buttonStyles.push(this.props.buttonHoverStyle);
      textStyles.push(this.props.textHoverStyle);
    }

    return (
      <View style={buttonStyles}>
        {this.props.icon &&
          <View style={iconStyles}>
            {this.props.icon}
          </View>
        }
        {this.props.icon && this.props.text &&
          <View style={_styles.spacer}/>
        }
        {this.props.text &&
          <Text style={textStyles}>
            {this.props.text}
          </Text>
        }
      </View>
    );
  };
}

const _styles = {
  icon: Styles.createViewStyle({
    flex: 0,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  spacer: Styles.createViewStyle({
    width: 10,
  }),
  button: Styles.createViewStyle({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    minWidth: 90,
    borderWidth: 2,
    borderRadius: 3,
    paddingHorizontal: 16,
    borderColor: 'transparent',
  }),
  buttonPrimary: Styles.createButtonStyle({
    borderColor: Light.buttonPrimaryBorder,
    backgroundColor: Light.buttonPrimaryBackground,
  }),
  buttonPrimaryHover: Styles.createButtonStyle({
    backgroundColor: Light.buttonPrimaryBackgroundHover,
  }),
  buttonCancel: Styles.createButtonStyle({
    borderColor: Light.buttonCancelBorder,
    backgroundColor: Light.buttonCancelBackground,
  }),
  buttonCancelHover: Styles.createButtonStyle({
    backgroundColor: Light.buttonCancelBackgroundHover,
  }),
  buttonDestructive: Styles.createButtonStyle({
    borderColor: Light.buttonDestructiveBorder,
    backgroundColor: Light.buttonDestructiveBackground,
  }),
  buttonDestructiveHover: Styles.createButtonStyle({
    backgroundColor: Light.buttonDestructiveBackgroundHover,
  }),
  text: Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size11,
  }),
  textPrimary: Styles.createTextStyle({
    color: Light.textButtonPrimary,
  }),
  textCancel: Styles.createTextStyle({
    color: Light.textButtonCancel,
  }),
  textDestructive: Styles.createTextStyle({
    color: Light.textButtonDestructive,
  }),
};
